import { STORAGE } from './constants'
import { getStorage } from './store'

export { STORAGE } from './constants'
export { isStorageAvailable, getStorage } from './store'

export const LocalStorage = getStorage(STORAGE.Local)
export const SessionStorage = getStorage(STORAGE.Session)
export const CookieStorage = getStorage(STORAGE.Cookie)
export const MemoryStorage = getStorage(STORAGE.Memory)
