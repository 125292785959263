const CSS_PREFIX = '--etvas'

const read = (key, defaultValue = '') => {
  const cssVal = document.documentElement.style.getPropertyValue(
    `${CSS_PREFIX}-${key}`
  )
  return cssVal === undefined ? defaultValue : cssVal
}

const write = (key, value) => {
  const _root = document.documentElement
  _root.style.setProperty(`${CSS_PREFIX}-${key}`, value)
}

export const cssStyleVar = {
  read,
  write
}
