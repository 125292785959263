import { useContext, useEffect } from 'react'

import { AuthContext } from 'app/services/AuthProvider'

import { i18nService } from '@shared/i18n'

export const useSyncUserLocale = () => {
  const { currentUser } = useContext(AuthContext)

  useEffect(() => {
    if (!currentUser) {
      return
    }
    if (i18nService.getSessionLanguage() === currentUser.locale) {
      return
    }
    i18nService.setSessionLanguage(currentUser.locale)
  }, [currentUser])
}
