import gql from 'graphql-tag'

export const PURCHASE_PRODUCT = gql`
  mutation ($input: IDInput!) {
    purchaseProduct(input: $input) {
      id
      status
    }
  }
`

export const ADD_PAYMENT_METHOD = gql`
  mutation attachPaymentMethod($input: AttachPaymentInput!) {
    attachPaymentMethod(input: $input) {
      paymentMethod {
        ... on PaymentMethodCard {
          id
          type
          brand
          lastFour
          expirationMonth
          expirationYear
        }
        ... on PaymentMethodSepa {
          id
          type
          lastFour
          countryCode
          bankCode
          branchCode
        }
      }
      setupIntent {
        id
        status
        clientSecret
      }
    }
  }
`

export const UPDATE_BILLING_INFO = gql`
  mutation updateBillingInfo($input: BillingInfoInput!) {
    updateBillingInfo(input: $input) {
      _id
      name
      vat
      address {
        line1
        line2
        city
        postalCode
        countryCode
      }
    }
  }
`

export const ATTACH_PAYMENT_COUPON = gql`
  mutation attachPaymentCoupon($input: IDInput!) {
    attachPaymentCoupon(input: $input) {
      id
      name
      amountOff
      percentOff
      validUntil
    }
  }
`

export const REMOVE_PAYMENT_COUPON = gql`
  mutation removePaymentCoupon {
    removePaymentCoupon {
      success
    }
  }
`
