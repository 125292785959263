import { ApolloProvider } from '@apollo/client'
import appTheme from '@assets/theme'
import isPropValid from '@emotion/is-prop-valid'
import AuthProvider, { AuthContext } from '@services/AuthProvider'
import OrganizationProvider from '@services/OrganizationProvider'
import StripeProvider from '@services/StripeProvider'
import { apolloClient } from '@services/graphql/clients'
import { BrowserRouter } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'

import { BrandingProvider, GlobalStyle, ThemeProvider } from '@etvas/etvaskit'

import { I18nProvider } from '@etvas/i18n'

import { i18nService } from '@shared/i18n'

import Router from './navigation/Router'
import FeatureGatesProvider from './services/FeatureGatesProvider'

const Root = () => (
  <BrowserRouter>
    <BrandingProvider>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ApolloProvider client={apolloClient}>
          <OrganizationProvider>
            <AuthProvider>
              <I18nProvider authContext={AuthContext} i18nService={i18nService}>
                <FeatureGatesProvider>
                  <ThemeProvider theme={appTheme}>
                    <GlobalStyle />
                    <StripeProvider>
                      <Router />
                    </StripeProvider>
                  </ThemeProvider>
                </FeatureGatesProvider>
              </I18nProvider>
            </AuthProvider>
          </OrganizationProvider>
        </ApolloProvider>
      </StyleSheetManager>
    </BrandingProvider>
  </BrowserRouter>
)

export default Root

function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    return isPropValid(propName)
  }

  return true
}
