import { useQuery } from '@apollo/client'

import { GET_ARTICLE_DETAILS } from './graphql/queries'

export const useBlogArticle = id => {
  const { data, loading: isLoadingArticle } = useQuery(GET_ARTICLE_DETAILS, {
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    variables: {
      input: {
        id
      }
    }
  })

  return { article: data?.getBlogItem ?? {}, isLoadingArticle }
}
