import { useCallback, useContext, useMemo, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Flex, Icon, Typography } from '@etvas/etvaskit'

import { withLanguageSwitcher, withLanguages } from '@etvas/i18n'

import { storage } from '@shared/funcs'

const Option = ({ language, onClick }) => {
  const handleClick = useCallback(
    () => onClick && onClick(language),
    [onClick, language]
  )

  const flag = useMemo(
    () => `${process.env.REACT_APP_PUBLIC_S3_URL}/flags/${language.id}.svg`,
    [language]
  )

  return (
    <Flex
      p={2}
      onClick={handleClick}
      alignItems='center'
      justifyContent='flex-start'
    >
      <StyledFlagImg src={flag} alt={language.id} />
      <Typography variant='labelSmall'>{language.id.toUpperCase()}</Typography>
    </Flex>
  )
}

Option.propTypes = {
  language: PropTypes.object,
  onClick: PropTypes.func
}

const LanguageDropdown = ({ value, languages, onChange, ...props }) => {
  const {
    organization: { languages: availableLanguages }
  } = useContext(OrganizationContext)

  const [open, setOpen] = useState(false)
  const handleToggle = useCallback(() => setOpen(!open), [open, setOpen])
  const handleChange = useCallback(
    language => {
      storage.didChangeLanguage = true
      onChange(language.id)
      setOpen(false)
    },
    [setOpen, onChange]
  )
  const currentLang = useMemo(
    () => languages.find(({ id }) => id === value),
    [languages, value]
  )

  const isLanguageAvailable = ({ id }) => availableLanguages.includes(id)
  const _languages = languages.filter(isLanguageAvailable)
  const isSingleLanguageOptions = _languages.length < 2

  return (
    <Box
      height='45px'
      width={isSingleLanguageOptions ? '80px' : '109px'}
      position='relative'
      {...props}
    >
      <Container>
        <Flex
          onClick={handleToggle}
          width='100%'
          alignItems='center'
          justifyContent='space-between'
        >
          <Option language={currentLang} />
          {!isSingleLanguageOptions && (
            <Box mr={4}>
              <Icon color='black' name={open ? 'menuUp' : 'menuDown'} />
            </Box>
          )}
        </Flex>

        {open && (
          <Flex flexDirection='column'>
            {_languages
              .filter(({ id }) => id !== value)
              .map(lang => (
                <Option key={lang.id} language={lang} onClick={handleChange} />
              ))}
          </Flex>
        )}
      </Container>
    </Box>
  )
}

LanguageDropdown.propTypes = {
  value: PropTypes.string,
  languages: PropTypes.array,
  onChange: PropTypes.func
}

const Container = styled.div(
  css({
    width: '100%',
    background: 'white',
    position: 'absolute',
    left: 0,
    top: 0,
    boxShadow: '0px 2px 4px rgba(19, 51, 77, 0.15)',
    borderRadius: '8px'
  })
)

const StyledFlagImg = styled.img(
  css({
    width: 24,
    height: 24,
    marginRight: 3
  })
)

export const LanguageSwitcher = withLanguageSwitcher(
  withLanguages(LanguageDropdown)
)
