import PropTypes from 'prop-types'

import { PurchasedProducts } from '../../myProducts/components'
import { usePurchasedProducts } from '../../myProducts/services/usePurchasedProducts'
import { usePostHeight } from '../hooks/usePostHeight'

const EmbeddedMyProducts = ({ name }) => {
  usePostHeight(name)

  const { isLoadingProducts, products } = usePurchasedProducts()

  return (
    <PurchasedProducts
      isFetching={isLoadingProducts}
      products={products}
      noProducts='link'
      showSeeMore='link'
      showAction='embed'
    />
  )
}

EmbeddedMyProducts.propTypes = {
  name: PropTypes.string
}

export default EmbeddedMyProducts
