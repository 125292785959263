const factory = () => {
  const { protocol, host, hostname, search } = window.location
  const full = `${protocol}//${host}`
  const segments = hostname.split('.')
  const domain = (() =>
    segments.slice(Math.max(segments.length - 2, 0)).join('.'))()
  const [subdomain] = segments

  const params = new URLSearchParams(search)
  const query = {}
  for (const [_key, _value] of params.entries()) {
    query[_key] = _value
  }

  const slug =
    subdomain === 'localhost' || !isNaN(subdomain)
      ? process.env.REACT_APP_LOCAL_SLUG
      : subdomain

  return {
    full,
    host: window.location.host,
    hostname: window.location.hostname,
    protocol: window.location.protocol,
    pathname: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash,
    domain,
    segments,
    subdomain,
    query,
    slug,
    isLocal:
      // localhost
      subdomain === 'localhost' ||
      // IPV6 localhost
      subdomain === '[::1]' ||
      // We consider IP addresses also local
      // (the portal will never be accessed by IP address)
      segments.every(segment => !isNaN(segment))
  }
}

export default factory()

export const getCurrentLocation = factory
