import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '@services/AuthProvider'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import PropTypes from 'prop-types'

const StripeProvider = ({ children }) => {
  const { authenticated } = useContext(AuthContext)
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    if (authenticated) {
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
    }
  }, [authenticated])

  return <Elements stripe={stripePromise}>{children}</Elements>
}

StripeProvider.propTypes = {
  children: PropTypes.node
}

export default StripeProvider
