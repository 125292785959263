import PropTypes from 'prop-types'

import { Box, Button, Flex, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

const PaymentMethodEmpty = ({ handleClick, ...props }) => (
  <Box {...props}>
    <Typography
      mx='auto'
      maxWidth='340px'
      my={{ _: '30px', md: '66px' }}
      className='empty-payment-method'
      textAlign='center'
      variant='titleSmall'
    >
      <T label='text.noPaymentMethod' />
    </Typography>
    <Flex justifyContent='flex-end'>
      <Button
        variant='link'
        width={{ _: '100%', md: 'auto' }}
        onClick={handleClick}
        type='button'
        mb={4}
      >
        <T label='label.add' />
      </Button>
    </Flex>
  </Box>
)

PaymentMethodEmpty.propTypes = {
  handleClick: PropTypes.func
}

export default PaymentMethodEmpty
