import { useContext } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'

import { SessionStorage } from '@shared/store'

const PREFIX = 'etv_pi_'

export const useSessionStorage = () => {
  const { organizationId } = useContext(OrganizationContext)
  const productImpressionsKey = `${PREFIX}${organizationId}`

  const getPI = () => {
    const raw = SessionStorage.getItem(productImpressionsKey)
    return raw ? JSON.parse(raw) : []
  }

  const savePI = ids => {
    SessionStorage.setItem(
      productImpressionsKey,
      JSON.stringify([...getPI(), ...ids])
    )
  }

  const clearPI = () => {
    SessionStorage.removeItem(productImpressionsKey)
  }

  return { getPI, savePI, clearPI }
}
