import { useContext, useMemo } from 'react'

import PropTypes from 'prop-types'

import { Button, Flex, Form, Grid } from '@etvas/etvaskit'

import { I18nContext, T } from '@etvas/i18n'

import { currentCountryCode } from '@shared/constants'
import { useBillingInfo } from '@shared/services/useBillingInfo'

import FormFragment from './FormFragment'
import Skel from './Skel'
import { areValuesChanged, b2i, handleValidation, i2b } from './utils'

const BillingInfoCard = ({ showVat, ...props }) => {
  const { translate } = useContext(I18nContext)

  const {
    billingInfo,
    requestUpdateBillingInfo,
    isLoadingBillingInfo,
    isUpdatingBillingInfo
  } = useBillingInfo()

  const initialValues = useMemo(
    () =>
      b2i(billingInfo, {
        defaultCountryCode: currentCountryCode?.toUpperCase()
      }),
    [billingInfo]
  )

  const _handleSubmit = async (values, { setFieldError }) => {
    const payload = i2b(values)
    const response = await requestUpdateBillingInfo(payload)

    if (!response.success && response.error?.includes('vat')) {
      setFieldError('vat', translate('error.invalidVAT'))
    }
  }

  if (isLoadingBillingInfo) {
    return <Skel />
  }

  return (
    <Form
      initialValues={initialValues}
      validate={values => handleValidation(values, translate)}
      onSubmit={_handleSubmit}
      enableReinitialize
      {...props}
    >
      {({ values, initialValues, errors }) => (
        <Grid vspace='0' cols={2}>
          <FormFragment
            translate={translate}
            errors={errors}
            showVat={showVat}
          />
          <Grid.Item span={2}>
            <Flex justifyContent='flex-end' py={4}>
              <Button
                variant='link'
                width={{ _: '100%', md: 'auto' }}
                ml={1}
                type='submit'
                disabled={
                  !areValuesChanged(initialValues, values) ||
                  isUpdatingBillingInfo
                }
              >
                <T label='label.saveChanges' />
              </Button>
            </Flex>
          </Grid.Item>
        </Grid>
      )}
    </Form>
  )
}

BillingInfoCard.propTypes = {
  showVat: PropTypes.bool
}

BillingInfoCard.defaultProps = {
  showVat: false
}

export default BillingInfoCard
