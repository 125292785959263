import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Typography } from '@etvas/etvaskit'

import { NavBack } from '@shared/components'

import { Skeleton } from './Skeleton'
import { useBlogArticle } from './services/useBlogArticle'

const BlogArticleDetails = () => {
  const { articleId } = useParams()
  const { article, isLoadingArticle } = useBlogArticle(articleId)

  return (
    <Box mt={[4, 0]}>
      {isLoadingArticle ? (
        <Skeleton />
      ) : (
        <>
          <NavBack>{article?.title ?? '...'}</NavBack>
          <Box mt={10}>
            <Image src={article?.featuredImageURL ?? 'about:blank'} />
          </Box>
          <Typography
            mt={10}
            variant='textLarge'
            dangerouslySetInnerHTML={{
              __html: article?.content?.html ?? '...'
            }}
          />
        </>
      )}
    </Box>
  )
}

const Image = styled.img({
  maxWidth: '100%'
})

export default BlogArticleDetails
