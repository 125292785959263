import { useContext, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import PropTypes from 'prop-types'

import { Button, Typography } from '@etvas/etvaskit'

import { userApi } from '@shared/funcs'
import { T } from '@shared/i18n'

import { ChangeForgottenPasswordForm } from './ChangeForgottenPasswordForm'

export const RecoverPassword = ({ userData, handleAuthStateChange }) => {
  const { organizationId } = useContext(OrganizationContext)
  const [didSuccessfullyReset, setDidSuccessfullyReset] = useState()

  const goToLogin = () => {
    handleAuthStateChange('login')
  }

  const changeForgottenPassword = async (
    { email, verificationCode, newPassword },
    actions
  ) => {
    const { setStatus, setSubmitting } = actions
    try {
      setStatus()
      await userApi.forgotPasswordSubmit(email, verificationCode, newPassword, {
        bpId: organizationId
      })
      setDidSuccessfullyReset(true)
    } catch (err) {
      setStatus(<T label='text.errorChangingPassword' />)
      console.error(
        '* Unexpected error caught while changing forgotten password',
        err
      )
    } finally {
      setSubmitting(false)
    }
  }

  if (didSuccessfullyReset) {
    return (
      <>
        <Typography variant='base14Light' textAlign='center' my={10}>
          <T label='text.passwordChanged' />
        </Typography>
        <Button variant='primary' mx='auto' onClick={goToLogin}>
          <T label='label.login' />
        </Button>
      </>
    )
  }

  return (
    <ChangeForgottenPasswordForm
      email={userData?.email}
      changeForgottenPassword={changeForgottenPassword}
    />
  )
}

RecoverPassword.propTypes = {
  userData: PropTypes.shape({
    email: PropTypes.string
  }),
  handleAuthStateChange: PropTypes.func
}
