import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Button, Flex, Typography } from '@etvas/etvaskit'

import { CardItem } from '@shared/components'
import { T } from '@shared/i18n'

const PaymentMethods = ({ cards, requestAddCard, hasBorder, buttonLabel }) => {
  const initialValues = {
    code: '',
    month: '',
    year: '',
    cvv: ''
  }

  const _handleValidation = ({ code, month, year, cvv }) => {
    const errors = {}
    if (!code || code.length !== 16) {
      errors.code = 'invalid'
    }
    const mon = parseInt(month)
    if (isNaN(mon) || mon < 1 || mon > 12) {
      errors.month = 'invalid'
    }
    const yr = parseInt(year)
    if (isNaN(yr) || yr < 20 || yr > 99) {
      errors.year = 'invalid'
    }
    const cv = parseInt(cvv)
    if (isNaN(cv) || !cvv || cvv.length !== 3) {
      errors.cvv = 'invalid'
    }
    return errors
  }

  const _handleAddCard = async (card, { setSubmitting, resetForm }) => {
    await requestAddCard(card)
    resetForm()
    setSubmitting(false)
  }

  return (
    <T>
      {({ translate }) => (
        <>
          {cards.length > 0 ? (
            <Flex flexDirection='column' mb={4}>
              {cards.map(card => (
                <CardItem
                  mb={2}
                  key={card.id}
                  type={card.type}
                  last4={card.last4}
                />
              ))}
            </Flex>
          ) : null}
          <StripeRenderMock hasBorder={hasBorder}>
            <Typography as='h4'>Visa</Typography>
            <Formik
              initialValues={initialValues || {}}
              validate={_handleValidation}
              onSubmit={_handleAddCard}
            >
              {({ errors }) => (
                <Form noValidate>
                  <CardField>
                    <Field
                      type='text'
                      name='code'
                      id='code'
                      maxLength='16'
                      placeholder={translate('label.cardNumber')}
                      className={errors.code ? 'is-invalid' : ''}
                    />
                  </CardField>
                  <Flex>
                    <CardField>
                      <Field
                        type='text'
                        name='month'
                        id='month'
                        maxLength='2'
                        placeholder='MM'
                        className={errors.month ? 'is-invalid' : ''}
                      />
                    </CardField>
                    <CardField>
                      <Field
                        type='text'
                        name='year'
                        id='year'
                        maxLength='2'
                        placeholder='YY'
                        className={errors.year ? 'is-invalid' : ''}
                      />
                    </CardField>
                    <CardField>
                      <Field
                        type='text'
                        name='cvv'
                        id='cvv'
                        maxLength='3'
                        placeholder='CVV'
                        className={errors.cvv ? 'is-invalid' : ''}
                      />
                    </CardField>
                  </Flex>
                  <Flex mt={4} justifyContent='flex-end'>
                    <StyledButton color='accent' type='submit'>
                      {translate(buttonLabel)}
                    </StyledButton>
                  </Flex>
                </Form>
              )}
            </Formik>
          </StripeRenderMock>
        </>
      )}
    </T>
  )
}

const CardField = styled(Flex)`
  margin-bottom: 0.5rem;
  input {
    font-family: Courier, monospace;
    font-size: 0.75rem;
    color: black;
    width: 100%;
    outline: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #999;
    padding: 0.5rem 0;
    &.is-invalid {
      border-bottom: 1px solid red;
      &::placeholder {
        color: red;
      }
    }
  }
`

const StripeRenderMock = styled(Box)`
  border-radius: 1rem;
  ${props =>
    props.hasBorder
      ? 'border: 1px solid #ccc; padding: 2rem;'
      : 'padding: 1rem;'};
  h4 {
    font-size: 16px;
    color: #666;
    font-weight: normal;
    margin: 0 0 1rem 0;
    padding: 0;
    text-transform: uppercase;
  }
`

const StyledButton = styled(Button)`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  display: inline-block;
  min-height: auto;
  height: auto;
  font-size: 0.75rem;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
`

PaymentMethods.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      last4: PropTypes.string
    })
  ),
  requestAddCard: PropTypes.func,
  hasBorder: PropTypes.bool,
  buttonLabel: PropTypes.string
}

PaymentMethods.defaultProps = {
  hasBorder: true,
  buttonLabel: 'label.saveToAccount'
}
export default PaymentMethods
