import { useContext } from 'react'

import css from '@styled-system/css'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Icon, Typography } from '@etvas/etvaskit'

import { BrowserStackContext } from 'app/services/BrowserStackProvider'

export const NavBack = ({ children, to, ...props }) => {
  const history = useHistory()
  const stack = useContext(BrowserStackContext)
  const canGoBack = stack?.length >= 2

  const handleClick = () => {
    if (to) {
      return history.push(to)
    }
    return history.replace(stack[stack.length - 2].pathname)
  }

  return (
    <Box mb={2} {...props}>
      <StyledLink>
        {(canGoBack || to) && (
          <Box mr={2}>
            <Icon
              name='arrowLeft'
              color='inputIcon'
              size='24px'
              onClick={handleClick}
            />
          </Box>
        )}

        <Typography as='span' variant='labelLarge' color='inputActive'>
          {children}
        </Typography>
      </StyledLink>
    </Box>
  )
}

const StyledLink = styled.a(
  css({
    color: 'inherit',
    textDecoration: 'inherit',
    font: 'inherit',
    textTransform: 'inherit',
    display: 'inline-flex',
    alignItems: 'flex-start',
    svg: {
      marginTop: 1,
      cursor: 'pointer'
    }
  })
)

NavBack.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
}
