import { useCallback, useEffect, useState } from 'react'

import { Hub } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

import { userApi } from '@shared/funcs'
import { useRedirectToStoredPath } from '@shared/hooks'

export const useCognitoAuth = () => {
  const history = useHistory()
  const redirectToStoredPath = useRedirectToStoredPath()

  const [{ cognitoUser, isFetchingCognitoUser }, storeAuthData] = useState({
    cognitoUser: null,
    isFetchingCognitoUser: true
  })

  const fetchUser = useCallback(async () => {
    try {
      const currUser = await userApi.getCurrentUser()

      if (currUser) {
        storeAuthData({
          cognitoUser: {
            ...currUser
          },
          isFetchingCognitoUser: false
        })
      }
    } catch (err) {
      storeAuthData({ cognitoUser: null, isFetchingCognitoUser: false })
      console.warn(`* Error while fetching user.`, err)
    }
  }, [])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  useEffect(() => {
    Hub.listen('auth', checkAuthedUser, 'authHandler')
    return () => Hub.remove('auth', checkAuthedUser)

    async function checkAuthedUser(event) {
      const { event: eventName } = event.payload

      await fetchUser()
      if (eventName === 'signIn') {
        redirectToStoredPath()
      } else if (eventName === 'signOut') {
        history.replace('/auth')
      }
    }
  }, [fetchUser, history, redirectToStoredPath])

  return { cognitoUser, isFetchingCognitoUser }
}
