import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTracking } from '@services/hooks/useTracking'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Modal,
  Touchable,
  Typography
} from '@etvas/etvaskit'

import { Markdown, MediaSlider, ProductCard, Section } from '@shared/components'
import { slugify } from '@shared/funcs'
import { T } from '@shared/i18n'
import { productShape } from '@shared/models'

import { ProductInfoSkeleton } from './ProductDetailsSkeleton'

const MEDIA_RATIO = 16 / 9

const ProductInfo = ({
  isFetching,
  product,
  showAction,
  hideRating,
  initPurchase
}) => {
  const [slide, setSlide] = useState(null)
  const [isTracked, setTracked] = useState(false)
  const { track } = useTracking()

  useEffect(() => {
    if (!isFetching && product?.id && !isTracked) {
      setTracked(true)
      track({
        name: 'product.view',
        value: product.id,
        context: { productId: product.id }
      })
    }
  }, [isFetching, product, track, isTracked])

  const closeImage = useCallback(() => setSlide(null), [setSlide])
  const handleSlideClick = useCallback(idx => setSlide(idx), [setSlide])
  const media = useMemo(() => product?.mediaURLs, [product])

  const termsUrl = useMemo(
    () =>
      product
        ? `/products/${slugify(product.name)}/${
            product.id
          }/terms-and-conditions`
        : '',
    [product]
  )

  if (isFetching) {
    return <ProductInfoSkeleton />
  }

  if (slide !== null) {
    return (
      <Modal backDrop='white' onEscape={closeImage}>
        <Modal.Content width={['100vw', '90vw', '80vw']}>
          <ButtonContainer justifyContent='flex-end'>
            <Touchable onClick={closeImage}>
              <Icon name='circleX' />
            </Touchable>
          </ButtonContainer>
          <Flex flexDirection='column' width='100%' justifyContent='center'>
            <MediaSlider
              isSingle
              media={media}
              initialIndex={slide}
              ratio={MEDIA_RATIO}
            />
          </Flex>
        </Modal.Content>
      </Modal>
    )
  }

  return (
    <>
      <Box>
        <ProductCard
          variant='hero'
          product={product}
          withTrialInfo
          showSeeMore={false}
          showAction={showAction}
          hideRating={!!hideRating}
          height={['35rem', '27rem', '27rem', '23rem']}
          initPurchase={initPurchase}
        />
      </Box>

      {media?.length ? (
        <Box mt={12}>
          <MediaSlider
            media={media}
            ratio={MEDIA_RATIO}
            onSelect={handleSlideClick}
          />
        </Box>
      ) : undefined}

      <Box mt={10}>
        <Section title={<T label='title.description' />}>
          <Card>
            <Flex p={4} flexDirection='column' justifyContent='space-between'>
              <Box flexGrow='1'>
                <Markdown variant='textLarge' md={product.description} />
              </Box>
              <Flex mt={4} justifyContent='flex-start'>
                {product.infoURL && (
                  <Flex alignItems='center' mr={8}>
                    <Icon name='web' size='12px' color='textLabelDefault' />
                    <Button
                      variant='link'
                      as='a'
                      href={product.infoURL}
                      target='_blank'
                      rel='noopener noreferrer'
                      ml={2}
                    >
                      <Typography
                        variant='labelButton'
                        color='textLabelDefault'
                      >
                        <T label='label.website' />
                      </Typography>
                    </Button>
                  </Flex>
                )}
                <Flex alignItems='center'>
                  <Icon name='handshake' size='12px' color='textLabelDefault' />
                  <Button
                    variant='link'
                    as='a'
                    href={termsUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    ml={2}
                  >
                    <Typography variant='labelButton' color='textLabelDefault'>
                      <T label='label.TCPrivacyPolicy' />
                    </Typography>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Card>
        </Section>
      </Box>
    </>
  )
}

const ButtonContainer = styled(Flex)`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
`

ProductInfo.propTypes = {
  isFetching: PropTypes.bool,
  product: productShape,
  showAction: PropTypes.oneOf([false, true, 'embed']),
  hideRating: PropTypes.bool,
  initPurchase: PropTypes.bool
}

ProductInfo.defaultProps = {
  showAction: true,
  hideRating: false
}

export default ProductInfo
