export const STORAGE = {
  Local: 'local',
  Session: 'session',
  Cookie: 'cookie',
  Memory: 'memory'
}

export const KEYS = {
  [STORAGE.Local]: '__l_',
  [STORAGE.Session]: '__s_',
  [STORAGE.Cookie]: '__c_',
  [STORAGE.Memory]: '__m_'
}

export const keyed = key =>
  Object.keys(KEYS).find(name => key.startsWith(KEYS[name]))

export const FALLBACKS = {
  [STORAGE.Local]: [STORAGE.Local, STORAGE.Cookie, STORAGE.Memory],
  [STORAGE.Session]: [STORAGE.Session, STORAGE.Cookie, STORAGE.Memory],
  [STORAGE.Cookie]: [STORAGE.Cookie, STORAGE.Memory],
  [STORAGE.Memory]: [STORAGE.Memory]
}
