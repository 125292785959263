import { Box, Flex, Typography } from '@etvas/etvaskit'

export const SidebarFooter = props => (
  <Box height={48} opacity='0.3' {...props}>
    <Flex px={4} alignItems='center' height='100%'>
      <Typography variant='base12Light' mr={1} color='baseWhite'>
        delivered by
      </Typography>
      <img src='/etvas-trademark.png' alt='Etvas trademark' />
    </Flex>
  </Box>
)
