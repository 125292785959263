import PropTypes from 'prop-types'

import { BlockSkeleton, Card } from '@etvas/etvaskit'

import { ModalDialog } from '@shared/components'

import Skel from './Skel'

const ModalSkel = ({ product, children, ...props }) => (
  <ModalDialog tinted {...props}>
    <Skeleton>{children}</Skeleton>
  </ModalDialog>
)

export const Skeleton = ({ noShadow, width }) => (
  <Card width={width} p={6} boxShadow={noShadow ? 'none' : ''}>
    <BlockSkeleton height='2rem' my={4} />
    <Skel mt={7} />
  </Card>
)

ModalSkel.propTypes = {
  children: PropTypes.node,
  product: PropTypes.shape({})
}

Skeleton.propTypes = {
  noShadow: PropTypes.bool,
  width: PropTypes.string
}

Skeleton.defaultProps = {
  noShadow: false,
  width: '100%'
}

export default ModalSkel
