import { useQuery } from '@apollo/client'

import { GET_LIST_PURCHASED_PRODUCTS } from './graphql/queries'

export const usePurchasedProducts = () => {
  const { data, loading: isLoadingPurchasedProducts } = useQuery(
    GET_LIST_PURCHASED_PRODUCTS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      partialRefetch: true
    }
  )

  return {
    isLoadingPurchasedProducts,
    purchasedProducts: data?.listPurchasedProducts ?? []
  }
}
