import { datadogRum } from '@datadog/browser-rum'

const setUser = details => datadogRum.setUser(details)
const removeUser = () => datadogRum.removeUser()

const rumSession = {
  setUser,
  removeUser
}

export default rumSession
