import { createRoot } from 'react-dom/client'

import {
  OFFER_QUERY_PARAM_NAME,
  cleanupCookies,
  currentLocation,
  getStripedURL,
  persistExternalId,
  persistOfferId,
  redirectIfNeeded
} from '@shared/funcs'
import { loadI18n } from '@shared/i18n'

import Root from './app/Root'
import './datadog'
import * as serviceWorker from './serviceWorker'
import './shared/polyfills'
import {
  hydrateAuthStorage,
  hydrateFromCacheIfExists
} from './shared/services/sso/hydrate'
import { getAuthStorage, unpack } from './shared/services/sso/utils'

redirectIfNeeded('www', true)

const _boot = async () => {
  await loadI18n()

  const storage = getAuthStorage()
  if (storage) {
    const token = unpack(currentLocation.query)

    persistOfferId(currentLocation)
    persistExternalId(currentLocation)

    try {
      window.history.replaceState(
        '',
        '',
        getStripedURL(currentLocation, ['_sso', '_ssc', OFFER_QUERY_PARAM_NAME])
      )
    } catch (err) {
      console.warn('* Error caught while trying to remove SSO info', err)
    }

    if (token) {
      if (!hydrateFromCacheIfExists(token, storage)) {
        await hydrateAuthStorage(token, storage)
      }
    }
  }
  cleanupCookies()

  const root = createRoot(document.getElementById('root'))
  root.render(<Root />)
}

_boot()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
