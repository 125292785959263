import { errorTypes } from '../constants'

const { INVALID, NOT_FOUND, UNKNOWN } = errorTypes

export const extractErrorsFromException = ({
  graphQLErrors,
  networkError,
  message
}) => {
  if (graphQLErrors?.length) {
    const [{ errorType, errorInfo }] = graphQLErrors

    if (errorType === 'ValidationException') {
      return {
        error: INVALID,
        hasErrors: true,
        fieldErrors: mapServerErrorsToHumanReadableMessages(errorInfo),
        errorMessage: 'Please check your input and try again.'
      }
    }
    if (errorType === 'ReadException') {
      return {
        error: NOT_FOUND,
        hasErrors: true,
        errorMessage:
          'The desired resource could not be found. Please refresh and try again.'
      }
    }
  }

  console.warn(
    `* [Unexpected exception]: ${message}`,
    graphQLErrors,
    networkError
  )

  return {
    error: UNKNOWN,
    hasErrors: true,
    errorMessage: 'An error occured while processing your request.'
  }
}

const messagesMap = {
  alphanum: 'Only letters and numbers allowed',
  creditCard: 'Invalid credit card number',
  domain: 'Invalid domain',
  email: 'Invalid email address',
  invalid: 'Invalid field',
  ip: 'Invalid IP address',
  lowercase: 'Only lowercase characters allowed',
  taken: 'Already exists',
  used: 'Already used',
  trim: 'No spaces at beginning or end allowed',
  required: 'Required',
  uppercase: 'Only CAPITAL LETTERS allowed',
  url: 'Invalid URL'
}

const mapServerErrorsToHumanReadableMessages = serverErrors =>
  Object.keys(serverErrors).reduce((errorMessages, fieldName) => {
    const failedRule = serverErrors[fieldName] || 'invalid'
    return {
      ...errorMessages,
      failedRule,
      [fieldName]: messagesMap[failedRule]
    }
  }, {})
