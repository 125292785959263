import styled from 'styled-components'

import { Box, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

export const SuspendedLabel = props => (
  <StyledWrapper {...props}>
    <Typography>
      <T label='label.suspended' />
    </Typography>
  </StyledWrapper>
)

const StyledWrapper = styled(Box)`
  display: inline-flex;
  align-items: center;
  margin-top: 0.3em;
  text-transform: capitalize;
  &:before {
    content: ' ';
    display: block;
    width: 0.75em;
    height: 0.75em;
    background-color: red;
    border-radius: 999em;
    margin-right: 0.375em;
  }
`
