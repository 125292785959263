import PropTypes from 'prop-types'

import { servicePartnerShape } from './servicePartner'

export const productShape = PropTypes.shape({
  id: PropTypes.string,
  rating: PropTypes.number,
  description: PropTypes.string,
  detailedDescription: PropTypes.string,
  name: PropTypes.string,
  imageURL: PropTypes.string,
  price: PropTypes.number,
  status: PropTypes.string,
  subscriptionMonths: PropTypes.number,
  trialMonths: PropTypes.number,
  purchaseId: PropTypes.string,
  purchaseStatus: PropTypes.string,
  subscriptionType: PropTypes.string,
  servicePartner: servicePartnerShape
})
