import { KEYS, STORAGE, keyed } from './constants'

let _memoryStorage = {}

export const isMemoryStorageAvailable = () => true

export const createMemoryStorage = mimic => {
  const mk = !mimic || mimic === STORAGE.Memory ? '' : KEYS[mimic]

  return {
    __type: STORAGE.Memory,
    getItem: key => _memoryStorage[`${mk}${key}`],
    setItem: (key, value) => {
      if (value === null || value === undefined) {
        delete _memoryStorage[`${mk}${key}`]
        return
      }
      _memoryStorage[`${mk}${key}`] = value
    },
    removeItem: key => delete _memoryStorage[`${mk}${key}`],
    hasItem: key => {
      const value = _memoryStorage[`${mk}${key}`]
      return value !== undefined && value !== null
    },
    clear: () => {
      Object.keys(_memoryStorage)
        .filter(key => (mk ? key.startsWith(mk) : !keyed(key)))
        .forEach(key => {
          delete _memoryStorage[key]
        })
    },
    _reset: () => (_memoryStorage = {}) // This should be used only in testing
  }
}
