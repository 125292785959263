import { BlockSkeleton, Grid } from '@etvas/etvaskit'

const Skel = props => (
  <Grid cols={2} {...props}>
    <Grid.Item span={2}>
      <BlockSkeleton height='3rem' mt={2} />
    </Grid.Item>
    <Grid.Item>
      <BlockSkeleton height='3rem' />
    </Grid.Item>
    <Grid.Item>
      <BlockSkeleton height='3rem' />
    </Grid.Item>
    <Grid.Item span={2}>
      <BlockSkeleton height='3rem' />
    </Grid.Item>
    <Grid.Item span={2}>
      <BlockSkeleton height='3rem' />
    </Grid.Item>
    <Grid.Item span={2}>
      <BlockSkeleton height='3rem' />
    </Grid.Item>
    <Grid.Item span={2}>
      <BlockSkeleton height='1.5rem' />
    </Grid.Item>
  </Grid>
)

export default Skel
