import { createContext, useEffect, useRef } from 'react'

import { useAuthUser } from '@services/hooks'
import PropTypes from 'prop-types'

import { rumSession } from '@shared/funcs'

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const didSetRumInfo = useRef(false)
  const {
    authenticated,
    currentUser,
    isLoadingUserProfile,
    cognitoUser,
    isFetchingCognitoUser,
    ...consentDetails
  } = useAuthUser()

  const profileAuth = currentUser && Object.keys(currentUser).length > 0

  useEffect(() => {
    if (!process.env.REACT_APP_DATADOG_RUM_TAG) {
      return
    }

    if (currentUser && !didSetRumInfo.current) {
      rumSession.setUser({
        id: currentUser.id,
        email: currentUser.email
      })
      didSetRumInfo.current = true
      return
    }

    if (!currentUser) {
      didSetRumInfo.current = false
    }
  }, [currentUser])

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        currentUser,
        isLoadingUserProfile: isLoadingUserProfile,
        cognitoUser,
        isFetchingCognitoUser,
        profileAuth,
        ...consentDetails
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node
}

export default AuthProvider
