import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import { GET_PURCHASE_LATEST_INVOICE } from './graphql/queries'

export const usePollingInvoice = purchaseId => {
  const [pollInvoice, setPollInvoice] = useState(null)

  const { data: invoiceDetailsResponse, stopPolling } = useQuery(
    GET_PURCHASE_LATEST_INVOICE,
    {
      variables: {
        input: { id: purchaseId }
      },
      fetchPolicy: 'no-cache',
      pollInterval: 500
    }
  )

  useEffect(() => {
    if (invoiceDetailsResponse?.getPurchaseLatestInvoice) {
      stopPolling()
      setPollInvoice(invoiceDetailsResponse.getPurchaseLatestInvoice)
    }
  }, [invoiceDetailsResponse, stopPolling])

  return { pollInvoice }
}
