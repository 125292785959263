import { breakpoints } from '@assets/styles/_utils'
import PropTypes from 'prop-types'

import { Grid } from '@etvas/etvaskit'

import { useMatchWidth } from '@shared/hooks'

import BlogArticle from './BlogArticle'

const BlogSection = ({ articles, isFetching }) => {
  const shouldNotWrap = useMatchWidth(breakpoints.lg)

  if (isFetching || !articles.length) return null

  return (
    <Grid cols={shouldNotWrap ? 3 : 2} hspace='3.3rem' vspace='1.7rem'>
      {articles.map(article => (
        <Grid.Item key={article.id}>
          <BlogArticle
            articleId={article.id}
            name={article.title}
            description={article.excerpt}
            imageURL={article.featuredImageURL}
          />
        </Grid.Item>
      ))}
    </Grid>
  )
}

BlogSection.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      featuredImageURL: PropTypes.string,
      title: PropTypes.string,
      excerpt: PropTypes.string
    })
  ),
  isFetching: PropTypes.bool
}

export default BlogSection
