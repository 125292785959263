import { BlockSkeleton, Button, Flex, Grid } from '@etvas/etvaskit'

const PaymentMethodSkeleton = props => (
  <Grid cols={2} {...props}>
    <Grid.Item span={2}>
      <BlockSkeleton height='3rem' />
    </Grid.Item>
    <Grid.Item>
      <BlockSkeleton height='3rem' />
    </Grid.Item>
    <Grid.Item>
      <BlockSkeleton height='3rem' />
    </Grid.Item>
    <Grid.Item span={2}>
      <Flex justifyContent='flex-end'>
        <Button
          variant='primary'
          width={{ _: '100%', md: 'auto' }}
          ml={1}
          disabled
          type='button'
        >
          ...
        </Button>
      </Flex>
    </Grid.Item>
  </Grid>
)

export default PaymentMethodSkeleton
