import { useLazyQuery } from '@apollo/client'
import { GET_EMAIL_AVAILABILITY } from '@services/graphql/queries'

import { extractErrorsFromException } from '@shared/funcs'

export const useCheckEmail = input => {
  const [_checkEmail, { loading, data, error }] = useLazyQuery(
    GET_EMAIL_AVAILABILITY,
    {
      variables: { input },
      fetchPolicy: 'no-cache',
      context: { unauthenticated: true }
    }
  )

  const checkEmail = async email => {
    try {
      const response = await _checkEmail({ variables: { input: email } })
      return response
    } catch (err) {
      console.error(' * Error while checking email availability', err)
      return err
    }
  }

  const isInvalidEmail = payload => {
    if (!payload?.graphQLErrors) {
      return false
    }
    const response = extractErrorsFromException(payload)
    return (
      response?.hasErrors &&
      response?.fieldErrors?.failedRule === 'invalid' &&
      response?.fieldErrors?.email === 'Invalid field'
    )
  }

  return {
    checkEmail,
    isEmailAvailable: data?.isEmailAvailable,
    loading,
    isInvalidEmail,
    error: error && isInvalidEmail(error) ? null : error
  }
}
