import PropTypes from 'prop-types'

import { BlockSkeleton, Box, Card, Flex, TextSkeleton } from '@etvas/etvaskit'

const ProductOverviewSkeleton = ({
  mobileFlexDirection,
  hideImage,
  desktopFlexDirection
}) => (
  <Card>
    <Flex
      flexDirection={[
        mobileFlexDirection,
        mobileFlexDirection,
        desktopFlexDirection
      ]}
      justifyContent='space-between'
      maxWidth='1200px'
      margin='0 auto'
    >
      <Flex
        p={[6, 6, 6, 6, 0]}
        flexDirection='column'
        justifyContent='space-between'
        my={8}
        width='100%'
      >
        <Box my={6} width='100%'>
          <TextSkeleton lines={3} />
          <TextSkeleton lines={4} mt={6} />
          <TextSkeleton lines={1} width='50%' mt={6} />
        </Box>
        <BlockSkeleton width='176px' height='56px' />
      </Flex>
      {!hideImage && (
        <Flex
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          mt={[6, 6, 0, 0]}
        >
          <Box width='400px' height='350px'>
            <BlockSkeleton width='100%' height='100%' />
          </Box>
        </Flex>
      )}
    </Flex>
  </Card>
)

ProductOverviewSkeleton.propTypes = {
  mobileFlexDirection: PropTypes.string,
  hideImage: PropTypes.string,
  desktopFlexDirection: PropTypes.string
}

export default ProductOverviewSkeleton
