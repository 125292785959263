import { useCallback, useContext, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import { useHistory, useLocation } from 'react-router-dom'

import { AuthFlow, AuthTemplate } from '@shared/components'
import AuthenticationTemplate from '@shared/components/AuthenticationTemplate'
import { T } from '@shared/i18n'

import { ImprintSupport } from './components/ImprintSupport'

const Auth = () => {
  const { organization } = useContext(OrganizationContext)

  return (
    <AuthTemplate brandMedia={organization?.brandMedia}>
      <AuthForm />
    </AuthTemplate>
  )
}

const authLabels = {
  checkEmail: 'title.logInOrCreate',
  login: 'title.logIn',
  register: 'title.createAnAccount',
  recoverPassword: 'title.recoverPassword'
}

const AuthForm = () => {
  const history = useHistory()
  const location = useLocation()
  const [currentAuthTitle, setCurrentAuthTitle] = useState('checkEmail')
  const handleStateChange = useCallback(
    newAuthState => {
      setCurrentAuthTitle(newAuthState)
      const searchParams = new URLSearchParams()
      if (newAuthState === 'register') {
        searchParams.append('isRegistering', 'true')
        history.replace({
          ...location,
          search: searchParams.toString()
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <AuthenticationTemplate title={<T label={authLabels[currentAuthTitle]} />}>
      <AuthFlow onAuthStateChange={handleStateChange} />
      <ImprintSupport mt={6} />
    </AuthenticationTemplate>
  )
}

export default Auth
