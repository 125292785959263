let _storage = {}

const getItem = key => _storage[key]
const setItem = (key, value) => {
  _storage[key] = value
  return value
}
const removeItem = key => delete _storage[key]
const clear = () => {
  _storage = {}
  return _storage
}
const _hydrate = seed => (_storage = { ...seed })

export const memoryStorage = {
  getItem,
  setItem,
  removeItem,
  clear,
  sync: async () => null,
  syncPromise: null,
  _hydrate,
  _all: () => ({ ..._storage })
}
