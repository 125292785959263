import gql from 'graphql-tag'

import { BLOG_ITEM_WITHOUT_CONTENT_FRAGMENT } from '@shared/services/graphql/fragments'

export const GET_BLOG_ITEMS = gql`
  query listBlogItems {
    listBlogItems {
      ...blogItemWithoutContent
    }
  }
  ${BLOG_ITEM_WITHOUT_CONTENT_FRAGMENT}
`
