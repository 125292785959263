import { useLazyQuery, useMutation } from '@apollo/client'
import { apolloClient } from '@services/graphql/clients'

import { extractErrorsFromException } from '@shared/funcs'

import {
  ATTACH_PAYMENT_COUPON,
  REMOVE_PAYMENT_COUPON
} from './graphql/mutations'
import {
  GET_CAN_ATTACH_PAYMENT_COUPON,
  GET_PAYMENT_COUPON
} from './graphql/queries'

const couponCode = 'FREUNDSCHAFTSRABATT'
const discountValue = 25

export const usePaymentCoupon = () => {
  const [
    getCanAttachPaymentCoupon,
    { data, loading: isLoadingCouponAttachable }
  ] = useLazyQuery(GET_CAN_ATTACH_PAYMENT_COUPON, {
    variables: { input: { id: couponCode } },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    partialRefetch: true
  })

  const [getPaymentCoupon, { data: paymentCoupon, loading: isCouponFetching }] =
    useLazyQuery(GET_PAYMENT_COUPON, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      partialRefetch: true
    })

  const [attachPaymentCoupon, { loading: isCouponAttaching }] = useMutation(
    ATTACH_PAYMENT_COUPON,
    {
      refetchQueries: [
        {
          query: GET_CAN_ATTACH_PAYMENT_COUPON,
          variables: { input: { id: couponCode } }
        }
      ]
    }
  )

  const [removePaymentCoupon, { loading: isCouponRemoving }] = useMutation(
    REMOVE_PAYMENT_COUPON,
    {
      refetchQueries: [
        {
          query: GET_CAN_ATTACH_PAYMENT_COUPON,
          variables: { input: { id: couponCode } }
        }
      ]
    }
  )
  const requestAttachPaymentCoupon = async id => {
    try {
      const { data } = await attachPaymentCoupon({
        variables: { input: { id } }
      })
      const coupon = data?.attachPaymentCoupon
      if (coupon) {
        _updateCachedCoupon(coupon)
      }
      return { success: !!coupon, coupon }
    } catch (err) {
      console.error('* ERROR attaching coupon', err)
      return {
        error: extractErrorsFromException(err),
        success: false
      }
    }
  }

  const requestDetachPaymentCoupon = async () => {
    try {
      const { success } = await removePaymentCoupon()
      _updateCachedCoupon(null)
      return { success: !!success }
    } catch (err) {
      console.error('* ERROR detaching coupon', err)
      return { success: false }
    }
  }

  const _updateCachedCoupon = coupon => {
    const getPaymentCoupon = coupon
      ? { ...coupon, __typename: 'PaymentCoupon' }
      : null
    apolloClient.writeQuery({
      query: GET_PAYMENT_COUPON,
      data: { getPaymentCoupon }
    })
  }

  return {
    getCanAttachPaymentCoupon,
    getPaymentCoupon,
    requestAttachPaymentCoupon,
    requestDetachPaymentCoupon,
    isLoadingCouponAttachable,
    canAttachPaymentCoupon: data?.canAttachPaymentCoupon?.success,
    paymentCoupon: paymentCoupon?.getPaymentCoupon,
    isPaymentCouponFetching: isCouponFetching,
    isPaymentCouponUpdating: isCouponAttaching || isCouponRemoving,
    couponCode,
    discountValue
  }
}
