import { useContext, useEffect, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'

import { I18nContext } from '@etvas/i18n'

export const useCarouselSlides = () => {
  const { organization, isRequestingOrganization } =
    useContext(OrganizationContext)
  const [carouselSlides, setCarouselSlides] = useState([])
  const { language } = useContext(I18nContext)

  useEffect(() => {
    if (!organization?.onboardingAssets?.[language]) {
      setCarouselSlides([])
      return
    }
    const slides = organization.onboardingAssets[language].map(
      (slide, idx) => ({
        id: `slide-${idx}`,
        position: idx,
        ...slide
      })
    )
    setCarouselSlides(slides)
  }, [language, organization])

  return { carouselSlides, isLoadingCarousel: isRequestingOrganization }
}
