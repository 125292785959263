import { useCallback, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Typography } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { MediaSlider } from '@shared/components'
import { isImage } from '@shared/funcs'

const MEDIA_RATIO = 16 / 9

const ProductGallery = ({ product, ...props }) => {
  const [slide, setSlide] = useState(null)
  const handleSlideClick = useCallback(idx => setSlide(idx), [setSlide])

  const slideImages = useMemo(
    () => product?.mediaURLs?.filter(file => isImage(file)),
    [product]
  )

  return (
    <Box maxWidth='1200px' margin='32px auto' {...props}>
      <Typography variant='labelButton' color='outline' mb={1}>
        <T label='text.productGallery' />
      </Typography>

      {slideImages && slideImages.length ? (
        <MediaSlider
          media={slideImages}
          ratio={MEDIA_RATIO}
          initialIndex={slide}
          onSelect={handleSlideClick}
        />
      ) : undefined}
    </Box>
  )
}
ProductGallery.propTypes = {
  product: PropTypes.object
}

export default ProductGallery
