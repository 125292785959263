import { useQuery } from '@apollo/client'

import { GET_PRODUCT_APP_INFO } from './graphql/queries'

export const useProductAppInfo = id => {
  const { data, loading } = useQuery(GET_PRODUCT_APP_INFO, {
    fetchPolicy: 'no-cache',
    partialRefetch: true,
    variables: {
      input: {
        id
      }
    }
  })

  return { product: data?.getProductDetails, isLoadingProductAppInfo: loading }
}
