import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { Box, Button, Card, Flex, Icon, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

const BlogArticle = ({ articleId, name, description }) => {
  const history = useHistory()

  return (
    <Box height='19rem'>
      <Card height='100%'>
        <Flex
          justifyContent='space-between'
          flexDirection='column'
          height='100%'
          p={6}
        >
          <Box>
            <Quote open />
            <Box flexGrow='1' my={4}>
              <Typography variant='labelLarge' truncate={2}>
                {name}
              </Typography>
              <Typography truncate={4} variant='textSmall' mt={2}>
                {description}
              </Typography>
            </Box>
          </Box>
          <Flex justifyContent='space-between' alignItems='center'>
            <Button
              variant='link'
              icon='bookOpenOutline'
              onClick={() => history.push(`/blog/${articleId}`)}
            >
              <T label='label.readMore' />
            </Button>
            <Quote />
          </Flex>
        </Flex>
      </Card>
    </Box>
  )
}

BlogArticle.propTypes = {
  articleId: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string
}

const Quote = ({ open }) => (
  <Flex justifyContent={open ? 'flex-start' : 'flex-end'}>
    <Box bg='brandLightest' height={40}>
      <Icon
        name={open ? 'formatQuoteOpen' : 'formatQuoteClose'}
        color='brand'
        size='40px'
      />
    </Box>
  </Flex>
)

Quote.propTypes = {
  open: PropTypes.bool
}

export default BlogArticle
