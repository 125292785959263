import { useContext, useEffect, useRef, useState } from 'react'

import { etvasTheme, useResizeObserver } from '@etvas/etvaskit'

import { isElementInViewport, isEmbedded } from '@shared/funcs'

import { ViewContext } from '../EmbeddedRouter'

export const usePostHeight = name => {
  const { currentView, setCurrentView } = useContext(ViewContext) ?? {}
  const iframeName = name || currentView

  const ref = useRef(name ? document.body : null)
  const [modal, setModal] = useState(null)
  const target = modal || ref

  useEffect(() => {
    if (name && isEmbedded()) {
      setCurrentView(name)
    }
  }, [setCurrentView, name])

  useEffect(() => {
    if (!isEmbedded()) {
      return
    }

    const modalContent = document.getElementById('etvas-modal-content')
    if (modalContent && !isElementInViewport(modalContent)) {
      setModal(modalContent)
    }
  }, [])

  useResizeObserver({
    ref: target,
    onResize({ height }) {
      const verticalSpacing = 2 * etvasTheme.space[8]
      window.top.postMessage(
        {
          channel: 'etvas-channel',
          action: 'etvas-resize',
          payload: {
            name: iframeName,
            height: height + (modal ? verticalSpacing : 0)
          }
        },
        '*'
      )
    }
  })
}
