import { KEYS, STORAGE, keyed } from './constants'

export const _isAvailable = type => {
  let storage
  try {
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (err) {
    return (
      err instanceof DOMException &&
      (err.code === 22 || // every browser except Firefox
        err.code === 1014 || // Firefox
        err.name === 'QuotaExceededError' || // test name if code not present
        err.name === 'NS_ERROR_DOM_QUOTA_REACHED') && // Firefox
      // acknowledge QuotaExceededError if there is something already stored
      storage &&
      storage.length !== 0
    )
  }
}

export const isLocalStorageAvailable = () => _isAvailable('localStorage')

export const createLocalStorage = mimic => {
  const mk = !mimic || mimic === STORAGE.Local ? '' : KEYS[mimic]
  return {
    __type: STORAGE.Local,
    __mimic: mimic || Storage.Local,
    getItem: key => window.localStorage.getItem(`${mk}${key}`),
    setItem: (key, value) => window.localStorage.setItem(`${mk}${key}`, value),
    removeItem: key => window.localStorage.removeItem(`${mk}${key}`),
    hasItem: key => {
      const value = window.localStorage.getItem(`${mk}${key}`)
      return value !== undefined && value !== null
    },
    clear: () => {
      Object.keys(window.localStorage)
        .filter(key => (mk ? key.startsWith(mk) : !keyed(key)))
        .forEach(key => window.localStorage.removeItem(key))
    }
  }
}
