import { CookieStorage } from '@aws-amplify/auth'
import { awsConfig } from '@config/aws.config'

import { memoryStorage } from './memoryStorage'

const factory = () => {
  if (!awsConfig.cookieStorage) {
    return memoryStorage
  }
  const storage = new CookieStorage(awsConfig.cookieStorage)

  return {
    getItem: storage.getItem,
    setItem: storage.setItem,
    removeItem: storage.removeItem,
    clear: storage.clear,
    sync: async () => null,
    syncPromise: null,
    _hydrate: seed =>
      Object.keys(seed).forEach(name => {
        storage.setItem(name, seed[name])
      })
  }
}

export const cookieStorage = factory()
