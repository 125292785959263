import { useContext, useEffect, useMemo } from 'react'

import { AuthContext } from '@services/AuthProvider'
import PropTypes from 'prop-types'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { NotFound } from '@shared/components'
import { isEmbedded } from '@shared/funcs'

const ProtectedRoute = ({
  component: Component,
  path,
  exact,
  computedMatch,
  location,
  ...componentProps
}) => {
  const history = useHistory()
  const { authenticated, hasConsent } = useContext(AuthContext)
  const { pathname, search } = location

  const isFromRegister = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.has('isRegistering') && !!params.get('isRegistering')
    // We can receive the isRegistering param only once
    // and we don't want to update this value if search will change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldDisplayConsent = () =>
    isFromRegister || isEmbedded() ? false : !hasConsent

  useEffect(() => {
    if (!authenticated) {
      return
    }

    const params = new URLSearchParams(search)
    const cleanup = ['state', 'isRegistering']
    let needsReplace = false
    cleanup.forEach(name => {
      if (params.has(name)) {
        params.delete(name)
        needsReplace = true
      }
    })
    if (needsReplace) {
      history.replace({ search: params.toString() })
    }
  }, [history, search, authenticated])

  if (!authenticated) {
    if (isEmbedded()) {
      return <NotFound />
    }

    return (
      <Redirect
        from={pathname}
        to={{
          pathname: '/auth',
          state: { authRedirectLocation: location }
        }}
      />
    )
  }

  if (shouldDisplayConsent()) {
    return (
      <Redirect
        to={{ pathname: '/consent', state: { authRedirectLocation: location } }}
      />
    )
  }

  return (
    <Route
      path={path}
      exact={exact}
      computedMatch={computedMatch}
      location={location}
    >
      {Component ? <Component {...componentProps} /> : null}
    </Route>
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  exact: PropTypes.bool,
  computedMatch: PropTypes.object,
  location: PropTypes.object
}

export default ProtectedRoute
