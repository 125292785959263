import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { getHostname } from '@shared/funcs'

if (process.env.REACT_APP_DATADOG_TAG) {
  // for more info @see https://docs.datadoghq.com/logs/log_collection/javascript/?tab=npm
  datadogLogs.init({
    clientToken: 'pub013f43e1c31b843d90dc5d6be95e7feb',
    site: 'datadoghq.eu', // Default: datadoghq.com | The Datadog Site of your organization. datadoghq.com for Datadog US site, datadoghq.eu for Datadog EU site.
    service: 'customer-portal', // The service name for this application.
    env: process.env.REACT_APP_NODE_ENV, // The application’s environment e.g. prod, pre-prod, staging.
    version: process.env.REACT_APP_DATADOG_TAG, // The application’s version e.g. 1.2.3, 6c44da20.2020.02.13_12:59:59.
    forwardErrorsToLogs: true, // Set to false to stop forwarding console.error logs, uncaught exceptions and network errors to Datadog.
    sampleRate: parseInt(process.env.REACT_APP_DATADOG_SAMPLE_RATE), // Percentage of sessions to track. Only tracked sessions send logs. 100 for all, 0 for none of them.
    trackSessionAcrossSubdomains: false, // Set to true to preserve session across subdomains of the same site. If you use both Logs and RUM SDKs, this config must match.
    useSecureSessionCookie:
      process.env.REACT_APP_DATADOG_SECURE_COOKIE === 'true', // Default: false | Set to true to use a secure session cookie. This will prevent session tracking on insecure (non-HTTPS) connections. If you use both Logs and RUM SDKs, this config must match.
    useCrossSiteSessionCookie: false, // Set to true to use a secure cross-site session cookie. This will allow the Logs SDK to run when the site is loaded from another one (for example, in an iframe). Implies useSecureSessionCookie. If you use both Logs and RUM SDKs, this config must match.
    beforeSend: log => {
      const isNetworkError = log =>
        (log.error &&
          log.error.origin === 'network' &&
          log.error.stack === 'AbortError: The user aborted a request.') ||
        log.message === 'Script error.'

      const isStatsigError = log => {
        const toIgnoreStatsigURL = [
          'https://api.statsig.com/v1/initialize',
          'https://api.statsig.com/v1/rgstr',
          'https://featuregates.org/v1/initialize',
          'https://events.statsigapi.net/v1/rgstr'
        ]
        return (
          log.error &&
          log.error.origin === 'network' &&
          toIgnoreStatsigURL.some(url => log.http.url.includes(url))
        )
      }

      const isStripeValidationError = log => {
        const invalidPaymentCodes = [
          'invalid_iban',
          'incomplete_iban',
          'invalid_number',
          'incomplete_number'
        ]
        return (
          log.error &&
          log.error.origin === 'console' &&
          ((log.message.includes('validation_error') &&
            invalidPaymentCodes.some(code => log.message.includes(code))) ||
            log.message.includes('Error while adding payment method'))
        )
      }

      if (isNetworkError(log) || isStatsigError(log)) {
        return false
      }

      if (isStripeValidationError(log)) {
        log.status = 'warn'
      }
    }
  })
}

if (process.env.REACT_APP_DATADOG_RUM_TAG) {
  datadogRum.init({
    applicationId: 'd51616c6-fb7a-4c24-9666-134d552bbae0',
    clientToken: 'pub863c0bed21ee00bbb5d99c072219831b',
    site: 'datadoghq.eu',
    service: 'customer-portal',
    version: process.env.REACT_APP_DATADOG_RUM_TAG,
    sampleRate: parseInt(process.env.REACT_APP_DATADOG_SAMPLE_RATE),
    trackInteractions: true,
    allowedTracingOrigins: [
      `https://${getHostname(process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT)}`
    ]
  })
  datadogRum.startSessionReplayRecording()
}
