import { useMutation } from '@apollo/client'

import { GRANT_EXTERNAL_ACCESS_TOKEN } from '../graphql/mutations'

export const useGrantToken = () => {
  const [requestMutation, { loading, data }] = useMutation(
    GRANT_EXTERNAL_ACCESS_TOKEN,
    {}
  )

  const requestGrantToken = async id => {
    if (!id) {
      throw new Error('No purchase id')
    }
    await requestMutation({ variables: { input: { id } } })
  }

  return {
    requestGrantToken,
    token: data?.grantAccessToPurchase?.jwt ?? null,
    isRequestingGrantToken: loading
  }
}
