import gql from 'graphql-tag'

import { ProfileFragment } from './fragments'

export const UPDATE_PROFILE_DATA = gql`
  mutation ($input: ProfileInput!) {
    updateMyProfile(input: $input) {
      ...ProfileData
    }
  }
  ${ProfileFragment}
`

export const CANCEL_PURCHASE = gql`
  mutation ($input: IDInput!) {
    cancelPurchase(input: $input) {
      id
      cancelsAt
    }
  }
`

export const DELETE_MY_ACCOUNT = gql`
  mutation {
    deleteMyAccount {
      success
    }
  }
`
