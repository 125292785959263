import { useCallback, useState } from 'react'

import { useQuery } from '@apollo/client'
import { useGetPortalConsent } from '@services/hooks'

import { isEmbedded, isLandingPage, storage, userApi } from '@shared/funcs'
import { getLanguage, setLanguage } from '@shared/i18n'

import { GET_USER_PROFILE } from '../../graphql/queries'
import { useCognitoAuth } from './useCognitoAuth'

const updateLang = userProfileLang => {
  if (isLandingPage() || isEmbedded()) {
    return
  }

  const currLang = getLanguage()
  if (storage.didChangeLanguage) {
    setLanguage(currLang)
  } else {
    setLanguage(userProfileLang ?? currLang)
  }
}

export const useAuthUser = () => {
  const { cognitoUser, isFetchingCognitoUser } = useCognitoAuth()
  const [isLoadingLanguage, setLoadingLanguage] = useState(true)

  const loadUserLanguage = useCallback(
    ({ getMyProfile }) => {
      updateLang(getMyProfile?.locale)
      setLoadingLanguage(false)
    },
    [setLoadingLanguage]
  )

  const { data, loading, error } = useQuery(GET_USER_PROFILE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !cognitoUser,
    onCompleted: loadUserLanguage
  })
  const consentDetails = useGetPortalConsent({
    skip: isEmbedded() || !cognitoUser
  })

  if (error) {
    console.error(`ERROR caught while getting customer profile data`, error)
    userApi.signOut()
  }

  return {
    authenticated: !!cognitoUser,
    currentUser:
      data?.getMyProfile && cognitoUser
        ? { ...cognitoUser, ...data.getMyProfile }
        : null,
    isLoadingUserProfile: loading,
    cognitoUser,
    isFetchingCognitoUser:
      isFetchingCognitoUser || (!!cognitoUser && isLoadingLanguage),
    ...consentDetails
  }
}
