import PropTypes from 'prop-types'

import { ImprintCard } from '@shared/components'

import { usePostHeight } from '../hooks/usePostHeight'

const EmbeddedImprint = ({ name, ...props }) => {
  usePostHeight(name)
  return <ImprintCard {...props} />
}

EmbeddedImprint.propTypes = {
  name: PropTypes.string
}

export default EmbeddedImprint
