import { FALLBACKS, STORAGE } from './constants'
import { createCookieStorage, isCookieStorageAvailable } from './cookie'
import { createLocalStorage, isLocalStorageAvailable } from './local'
import { createMemoryStorage, isMemoryStorageAvailable } from './memory'
import { createSessionStorage, isSessionStorageAvailable } from './session'

let _cached = {}

export const storageFactory = (availabilityMap, factoryMap) => {
  const available = type => {
    if (availabilityMap[type] === undefined) {
      throw new Error(`Cannot find storage ${type}`)
    }
    return availabilityMap[type]()
  }

  const factory = type => {
    if (!FALLBACKS[type]) {
      throw new Error(`Cannot find storage ${type}`)
    }
    if (type === STORAGE.Memory) {
      return factoryMap[type](type)
    }

    for (let i = 0; i < FALLBACKS[type].length; i++) {
      const _fallback = FALLBACKS[type][i]
      const cachedKey = `${type}.${_fallback}`
      if (available(_fallback)) {
        if (!factoryMap[_fallback]) {
          throw new Error(`Unknown type ${_fallback} for factory`)
        }
        if (!_cached[cachedKey]) {
          _cached[cachedKey] = factoryMap[_fallback](type)
        }
        return _cached[cachedKey]
      }
    }
    return null
  }

  return { available, factory }
}

export const availabilityMap = {
  [STORAGE.Local]: isLocalStorageAvailable,
  [STORAGE.Session]: isSessionStorageAvailable,
  [STORAGE.Cookie]: isCookieStorageAvailable,
  [STORAGE.Memory]: isMemoryStorageAvailable
}

export const factoryMap = {
  [STORAGE.Local]: createLocalStorage,
  [STORAGE.Session]: createSessionStorage,
  [STORAGE.Cookie]: createCookieStorage,
  [STORAGE.Memory]: createMemoryStorage
}

const { available, factory } = storageFactory(availabilityMap, factoryMap)

export const isStorageAvailable = available
export const getStorage = factory

export const clearFactoryStorageCache = () => (_cached = {})
