import { useCallback } from 'react'

import PropTypes from 'prop-types'

import { Box, Flex, Link, Typography, styled } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { PAYMENT_METHODS } from '@shared/constants'
import { useMatchWidth } from '@shared/hooks/useMatchWidth'

import Bullets from './Bullets'
import CardElementWrapper from './CardElementWrapper'
import PaymentMethodsDropdown from './PaymentMethodsDropdown'

const PurchasePaymentMethodView = ({
  paymentMethod,
  tinted,
  onChange,
  isBusy,
  ...props
}) => {
  const minWidth370 = useMatchWidth('370px')
  const minWidth420 = useMatchWidth('420px')

  const getNumberOfBulletGroups = useCallback(() => {
    if (minWidth420) {
      return 3
    }
    if (minWidth370) {
      return 2
    }
    return 1
  }, [minWidth370, minWidth420])

  const numberOfBulletGroups = getNumberOfBulletGroups()

  return (
    <Box {...props}>
      <PaymentMethodsDropdown
        mb={2}
        disabled={true}
        value={paymentMethod.type}
      />

      <Box>
        <Typography variant='inputLabel' width='fit-content'>
          <T
            label={
              paymentMethod.type === PAYMENT_METHODS.card
                ? 'label.paymentMethod'
                : 'label.iban'
            }
          />
        </Typography>
        <CardElementWrapper mt={1} tinted={tinted}>
          <Flex justifyContent='space-between' alignItems='center'>
            {paymentMethod.type === PAYMENT_METHODS.card && (
              <>
                <Typography
                  variant='base14Bold'
                  letterSpacing={1}
                  color={Bullets.defaultColor}>
                  <BulletGroups numberOfGroups={numberOfBulletGroups} />
                  {paymentMethod.lastFour}
                </Typography>
                <Typography
                  variant='base14Bold'
                  letterSpacing={1}
                  color={
                    Bullets.defaultColor
                  }>{`${paymentMethod.expirationMonth}/${
                  paymentMethod.expirationYear - 2000
                }`}</Typography>
              </>
            )}

            {paymentMethod.type === PAYMENT_METHODS.sepa && (
              <Typography
                variant='base14Bold'
                color={Bullets.defaultColor}
                letterSpacing={1}>
                <BulletGroups
                  numberOfGroups={
                    numberOfBulletGroups < 3
                      ? numberOfBulletGroups + 1
                      : numberOfBulletGroups
                  }
                />
                {paymentMethod.lastFour}
              </Typography>
            )}

            <Typography variant='labelButton'>
              <StyledLink
                onClick={onChange}
                busy={isBusy}>
                <T label='label.change' />
              </StyledLink>
            </Typography>
          </Flex>
        </CardElementWrapper>
      </Box>
    </Box>
  )
}
const StyledLink = styled(Link)`
  cursor: pointer;
`

PurchasePaymentMethodView.propTypes = {
  tinted: PropTypes.bool,
  paymentMethod: PropTypes.shape({
    type: PropTypes.string,
    lastFour: PropTypes.string,
    expirationMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    expirationYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  onChange: PropTypes.func,
  isBusy: PropTypes.bool
}


const BulletGroups = ({ numberOfGroups }) => (
  <>
    {Array.from({ length: numberOfGroups }, (_, i) => (
      <Bullets key={`blt-group-${i}`} />
    ))}
  </>
)
BulletGroups.propTypes = {
  numberOfGroups: PropTypes.number
}

export default PurchasePaymentMethodView
