import { Fragment } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import { Typography } from '@etvas/etvaskit'

import { productPurchase } from '@shared/constants'
import { formatPrice } from '@shared/funcs'
import { T } from '@shared/i18n'
import { productShape } from '@shared/models'

import { RecurringSubscriptionPeriod } from './RecurringSubscriptionPeriod'

const {
  isOneTimeSubscription,
  isRecurringSubscription,
  isLifetimeSubscription,
  isProductCanceled
} = productPurchase

const getEndDate = date => moment(date).format('ll')

const subscriptionInterval = product => {
  const validUntil = getEndDate(product?.validUntil)
  if (product?.isDemo) {
    return <T label='label.demo' />
  }
  if (isRecurringSubscription(product)) {
    return (
      <RecurringSubscriptionPeriod
        subscriptionMonths={product.subscriptionMonths}
      />
    )
  }

  if (isLifetimeSubscription(product)) {
    return <T label='text.lifetime' />
  }

  return <T label='label.expiresOn' args={[validUntil]} />
}

export const ProductPurchaseDetails = ({ product, separator }) => {
  const hasActiveTrial = !!product.trialEndsAt

  const trialEndsDate = getEndDate(product?.trialEndsAt)
  const validUntilDate = getEndDate(product?.validUntil)
  const cancelsAtDate = getEndDate(product?.cancelsAt)

  const isCanceledProduct = isProductCanceled(product)
  const isOneTimePurchase = isOneTimeSubscription(product)
  const isRecurringPurchase = isRecurringSubscription(product)
  const isDemoPurchase = product?.isDemo

  const priceText = formatPrice(product.price, {
    currency: product.currency,
    zero: <T label='label.free' />
  })
  const isReactivation = productPurchase.isProductSuspended(product)

  const price = (
    <DetailsText>
      {hasActiveTrial ? <T label='label.freeTrial' /> : priceText}
    </DetailsText>
  )

  const canceledStatus = isCanceledProduct && (
    <DetailsText color='statusError'>
      <T label='label.canceled' />
    </DetailsText>
  )

  const suspendedStatus = (
    <DetailsText color='statusError'>
      <T label='label.suspended' />
    </DetailsText>
  )

  const subscriptionInfo = !hasActiveTrial && !isCanceledProduct && (
    <DetailsText>{subscriptionInterval(product)}</DetailsText>
  )

  const getActionDate = () => {
    if (isDemoPurchase) {
      const validUntil = getEndDate(product?.validUntil)
      return (
        <DetailsText>
          <T label='label.expiresOn' args={[validUntil]} />
        </DetailsText>
      )
    }
    if (isCanceledProduct) {
      const usableUntilDate = hasActiveTrial ? trialEndsDate : cancelsAtDate
      return (
        <DetailsText>
          <T label='label.usableUntil' args={[usableUntilDate]} />
        </DetailsText>
      )
    }

    if (hasActiveTrial) {
      return (
        <DetailsText>
          {priceText}{' '}
          <RecurringSubscriptionPeriod
            subscriptionMonths={product.subscriptionMonths}
          />{' '}
          <T
            label={`label.${isOneTimePurchase ? 'onceOn' : 'startingWith'}`}
            args={[trialEndsDate]}
          />
        </DetailsText>
      )
    }

    if (isRecurringPurchase) {
      const hasOldValidUntil = moment(product?.validUntil).isBefore()
      const dateColor = hasOldValidUntil ? 'error' : 'primary'
      return (
        <DetailsText>
          <T label='label.renewsOn' />{' '}
          <DetailsText color={dateColor}>{validUntilDate}</DetailsText>
        </DetailsText>
      )
    }
  }
  const actionDate = getActionDate()

  const details = isReactivation
    ? [price, suspendedStatus]
    : [price, canceledStatus, subscriptionInfo, actionDate]
  const detailsToBeShown = details.filter(detail => !!detail)
  return (
    <>
      {detailsToBeShown.map((detail, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={idx}>
          {detail}
          {detailsToBeShown.length - 1 !== idx && separator}
        </Fragment>
      ))}
    </>
  )
}

ProductPurchaseDetails.propTypes = {
  product: productShape,
  separator: PropTypes.string
}

ProductPurchaseDetails.defaultProps = {
  separator: ' | '
}

const DetailsText = ({ children, ...props }) => (
  <Typography variant='labelSmallBold' display='inline' {...props}>
    {children}
  </Typography>
)

DetailsText.propTypes = {
  children: PropTypes.any
}
