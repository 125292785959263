import PropTypes from 'prop-types'

import { Box } from '@etvas/etvaskit'

import { CouponBanner } from '../../discover/components'

const Discount = ({ informal, ...props }) => (
  <Box {...props}>
    <CouponBanner informal={informal} />
  </Box>
)

Discount.propTypes = {
  discount: PropTypes.object,
  informal: PropTypes.bool
}

Discount.defaultProps = {
  informal: false
}

export default Discount
