import PropTypes from 'prop-types'

import { ActivityIndicator, Flex, Space, Typography } from '@etvas/etvaskit'

const LoadingSpinner = ({ title, primaryColor, textVariant, ...props }) => (
  <Flex
    alignItems='center'
    flexDirection='column'
    justifyContent='center'
    width='100%'
    height='100%'
    {...props}
  >
    <ActivityIndicator
      colors={{ background: 'white', primary: primaryColor }}
      size='64px'
    />
    <Space mt={4}>
      <Typography variant={textVariant}>{title || ''}...</Typography>
    </Space>
  </Flex>
)

LoadingSpinner.propTypes = {
  title: PropTypes.node,
  primaryColor: PropTypes.string,
  textVariant: PropTypes.string
}

LoadingSpinner.defaultProps = {
  primaryColor: 'accent'
}

export default LoadingSpinner
