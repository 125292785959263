const statuses = {
  Purchasing: 'Purchasing',
  Purchased: 'Purchased',
  Failed: 'Failed',
  Suspended: 'Suspended',
  Resuming: 'Resuming',
  Expired: 'Expired',
  Canceled: 'Canceled'
}

const canBeReactivated = status =>
  status === statuses.Suspended || status === statuses.Resuming

const purchaseStatus = {
  ...statuses,
  canBeReactivated
}

export default purchaseStatus
