// No need to import en, it's bundled with moment
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/ro'

import de from './dictionaries/de'
import en from './dictionaries/en'
import fr from './dictionaries/fr'
import ro from './dictionaries/ro'

const languages = [
  {
    id: 'en',
    name: 'English',
    englishName: 'English',
    dictionary: en
  },
  {
    id: 'de',
    name: 'Deutsch',
    englishName: 'German',
    dictionary: de
  },
  {
    id: 'ro',
    name: 'Română',
    englishName: 'Romanian',
    dictionary: ro
  },
  {
    id: 'fr',
    name: 'French',
    englishName: 'French',
    dictionary: fr
  }
]

export default languages
