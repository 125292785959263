import gql from 'graphql-tag'

import {
  AVAILABLE_PRODUCT_FRAGMENT,
  BLOG_ITEM_WITHOUT_CONTENT_FRAGMENT,
  PRODUCT_PURCHASE_STATUS_FRAGMENT
} from '@shared/services/graphql/fragments'

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetails($input: IDInput!) {
    getProductDetails(input: $input) {
      ...productPurchaseStatus
      ...availableProduct
    }
  }
  ${PRODUCT_PURCHASE_STATUS_FRAGMENT}
  ${AVAILABLE_PRODUCT_FRAGMENT}
`

export const GET_BLOG_ITEMS = gql`
  query listBlogItems {
    listBlogItems {
      ...blogItemWithoutContent
    }
  }
  ${BLOG_ITEM_WITHOUT_CONTENT_FRAGMENT}
`
