import { useContext } from 'react'

import { AuthContext } from '@services/AuthProvider'
import { OrganizationContext } from '@services/OrganizationProvider'

import { Button, Flex, Image, Link, Typography } from '@etvas/etvaskit'

import { AuthenticationTemplate } from '@shared/components'
import { userApi } from '@shared/funcs'
import { useRedirectToStoredPath } from '@shared/hooks'
import { T } from '@shared/i18n'

const supportEmail = 'support@etvas.com'

const RegisterConsent = () => {
  const {
    currentUser,
    collectConsent,
    isCollectingConsent,
    errorCollectingConsent
  } = useContext(AuthContext)
  const redirectToStoredPath = useRedirectToStoredPath()
  const { organization } = useContext(OrganizationContext)
  const { firstName, lastName, email } = currentUser ?? {}
  const fullName = `${firstName} ${lastName}`

  const handleContinue = async () => {
    const { success } = await collectConsent()
    if (success) {
      redirectToStoredPath()
    }
  }

  const handleChangeAccount = () => userApi.signOut()

  return (
    <AuthenticationTemplate title={<T label='label.register' />}>
      <Flex flexDirection='column' alignItems='center'>
        <Typography
          variant='base14Light'
          color='baseBlack'
          display='inline'
          textAlign='center'
          mb={5}
        >
          <Typography variant='base14Light'>
            <T label='text.consentRegister1' />
          </Typography>

          <Typography variant='base14Light' color='brand' display='inline'>
            {organization?.name}{' '}
          </Typography>
          <T label='text.consentRegister2' />
        </Typography>

        <Flex mb={3} alignItems='center'>
          <Image
            alt='account placeholder'
            src='/account-placeholder.png'
            height='32px'
            mr={2}
          />
          <Typography variant='base14Light' color='baseGrayDark'>
            {fullName}
          </Typography>
        </Flex>

        <Typography variant='base14Light' color='baseBlack' mb={7}>
          {email}
        </Typography>

        {errorCollectingConsent && (
          <Typography
            variant='base14Bold'
            color='statusError'
            textAlign='center'
            mb={3}
          >
            <T label='error.unknown' />
          </Typography>
        )}

        <Button
          variant='primary'
          mb={6}
          onClick={handleContinue}
          loading={isCollectingConsent}
          disabled={isCollectingConsent}
        >
          <T label='label.continue' />
        </Button>

        <Button variant='link' mb={6} onClick={handleChangeAccount}>
          <T label='label.changeAccount' />
        </Button>

        <Typography
          variant='base12Light'
          color='baseBlack'
          mb={7}
          textAlign='center'
        >
          <T label='text.anyQuestions' />

          <Link target='_blank' href={`mailto:${supportEmail}`}>
            <Typography variant='base12Light' color='outline'>
              {supportEmail}
            </Typography>
          </Link>
        </Typography>
      </Flex>
    </AuthenticationTemplate>
  )
}

export default RegisterConsent
