import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { CardSkeleton, Flex, TextSkeleton } from '@etvas/etvaskit'

import { ProductCard } from '@shared/components'

import { useEmbeddedData } from '../hooks/useEmbeddedData'
import { usePostHeight } from '../hooks/usePostHeight'

const ProductCardSkeleton = () => (
  <CardSkeleton height='200px' width='100%' imageSize={0.4}>
    <Flex height='100%' flexDirection='column' justifyContent='space-between'>
      <TextSkeleton width='75%' />
      <TextSkeleton lines={2} />
      <TextSkeleton width='30%' lines={2} />
    </Flex>
  </CardSkeleton>
)

const defaultOptions = {
  boxSize: 'small',
  showSeeMore: 'link',
  showAction: false
}

const EmbeddedProductCard = ({ name, options }) => {
  const { productId } = useParams()
  const { product, loading } = useEmbeddedData(productId)
  usePostHeight(`${name}-${productId}`)

  if (loading) {
    return <ProductCardSkeleton />
  }

  const productCardOptions =
    options && Object.keys(options).length > 0
      ? { ...defaultOptions, ...options }
      : defaultOptions

  return <ProductCard product={product} {...productCardOptions} />
}

EmbeddedProductCard.propTypes = {
  name: PropTypes.string,
  options: PropTypes.shape({
    boxSize: PropTypes.oneOf(['small', 'large']),
    variant: PropTypes.oneOf(['normal', 'hero']),
    showSeeMore: PropTypes.oneOf([false, 'link', 'button']),
    seeMoreText: PropTypes.string,
    hideRating: PropTypes.bool
  })
}

EmbeddedProductCard.defaultProps = {
  options: {
    boxSize: 'small',
    variant: 'normal',
    showSeeMore: 'link',
    hideRating: false
  }
}

export default EmbeddedProductCard
