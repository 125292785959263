import gql from 'graphql-tag'

export const ProfileFragment = gql`
  fragment ProfileData on Profile {
    id
    firstName
    lastName
    locale
    phoneNumber
    email
  }
`

export const ConsentFragment = gql`
  fragment ConsentFragment on Consent {
    organizationId
  }
`
