import { useMemo } from 'react'

import PropTypes from 'prop-types'
import { StatsigProvider } from 'statsig-react'

import { userSessionTrack } from '@shared/funcs'

import { isInIframe } from './utils/isInIframe'

const FeatureGatesProvider = ({ children }) => {
  const user = useMemo(() => {
    let ses = userSessionTrack.getActive()
    if (!ses) {
      userSessionTrack.create()
      ses = userSessionTrack.getActive()
    }

    return {
      userID: ses.id
    }
  }, [])

  if (!process.env.REACT_APP_STAT_SIG_SDK_KEY || isInIframe()) {
    return children
  }

  return (
    <StatsigProvider
      sdkKey={process.env.REACT_APP_STAT_SIG_SDK_KEY}
      user={user}
      waitForInitialization={false}
    >
      {children}
    </StatsigProvider>
  )
}

FeatureGatesProvider.propTypes = {
  children: PropTypes.node
}

export default FeatureGatesProvider
