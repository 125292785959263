import { purchaseStatus } from '@shared/models'

export const trialUnits = {
  minutes: 'minutes',
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  years: 'years'
}

const subscriptionTypes = {
  oneTimeSubscriptionType: 'Once',
  recurringSubscriptionType: 'Recurring'
}

const isOneTimeSubscription = product =>
  product?.subscriptionType === subscriptionTypes.oneTimeSubscriptionType

const isRecurringSubscription = product =>
  product?.subscriptionType === subscriptionTypes.recurringSubscriptionType

const isLifetimeSubscription = product =>
  product?.subscriptionType === subscriptionTypes.oneTimeSubscriptionType &&
  product?.subscriptionMonths === 0

const isFreeProduct = product =>
  product?.subscriptionType === subscriptionTypes.oneTimeSubscriptionType &&
  product?.price === 0 &&
  product?.subscriptionMonths === 0

const isProductCanceled = product => product?.cancelsAt !== null

const isProductSuspended = product =>
  purchaseStatus.canBeReactivated(product?.purchaseStatus)

export const productPurchase = {
  ...subscriptionTypes,
  isOneTimeSubscription,
  isRecurringSubscription,
  isLifetimeSubscription,
  isFreeProduct,
  isProductCanceled,
  isProductSuspended
}
