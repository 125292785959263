import {
  BlockSkeleton,
  Box,
  CardSkeleton,
  Flex,
  TextSkeleton
} from '@etvas/etvaskit'

export const ProductInfoSkeleton = () => (
  <Flex width='100%' flexDirection='column'>
    <CardSkeleton height='400px' width='100%' imageSize={0.4}>
      <Flex height='100%' flexDirection='column' justifyContent='space-between'>
        <TextSkeleton width='75%' />
        <TextSkeleton lines={3} />
        <TextSkeleton width='30%' lines={2} />
      </Flex>
    </CardSkeleton>
    <Box mt='80px' px='40px'>
      <BlockSkeleton width='100%' height='300px' />
    </Box>
  </Flex>
)
