const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif']

const videoMimes = {
  flv: 'video/x-flv',
  mp4: 'video/mp4',
  m3u8: 'application/x-mpegURL',
  ts: 'video/MP2T',
  '3gp': 'video/3gpp',
  mov: 'video/quicktime',
  avi: 'video/x-msvideo',
  wmv: 'video/x-ms-wmv'
}

export const isImage = url => imageExtensions.some(ext => url.endsWith(ext))

export const getVideoMime = url => {
  const segments = url.toLowerCase().split('.')
  const ext = segments.pop()
  return videoMimes[ext] || `video/${ext}`
}

export const appendTime = url => `${url}#t=0.001`
