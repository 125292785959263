import { useMemo } from 'react'

import { BgTypography, Grid } from '@shared/components'
import { usePaginatedState } from '@shared/hooks/usePaginatedState'
import { T } from '@shared/i18n'

import { getPaymentHistoryGrid } from './grids/paymentHistory'
import { useInvoicesPagination } from './hooks/useInvoicesPagination'
import { usePaymentHistory } from './services'

const initialInvoicesNumber = 5
const PaymentHistory = () => {
  const grid = getPaymentHistoryGrid()
  const { state, dispatch } = usePaginatedState(initialInvoicesNumber)
  const paymentHistoryQuery = usePaymentHistory({
    count: initialInvoicesNumber
  })
  const paginationConfig = useInvoicesPagination({
    state,
    dispatch,
    listInvoicesQuery: paymentHistoryQuery
  })

  const { listInvoices, fetchMoreOrRefetch, isInitialLoading, error } =
    paymentHistoryQuery
  const invoices = useMemo(
    () => listInvoices?.slice(state.pivot - state.pageLimit, state.pivot),
    [listInvoices, state]
  )
  const isFetchingTransactions = fetchMoreOrRefetch || isInitialLoading

  if (error) {
    return (
      <BgTypography
        typographyContent={<T label='error.unknown' />}
        backgroundColor='baseWhite'
        typographyColor='statusError'
      />
    )
  }

  return (
    <Grid
      busy={isFetchingTransactions}
      items={invoices}
      name={grid.name}
      columns={grid.columns}
      emptyGridText={<T label='text.emptyGrid' />}
      paginationConfig={paginationConfig}
    />
  )
}

export default PaymentHistory
