import { createContext } from 'react'

import PropTypes from 'prop-types'

import { favicon } from '@shared/funcs'

import { useGetBranding } from './hooks/useGetBranding'

export const OrganizationContext = createContext()

function setOgTag(tag, value) {
  const el = Array.from(document.getElementsByTagName('meta')).find(
    el => el.getAttribute('property') === tag
  )

  if (el) {
    el.setAttribute('content', value)
  }
}

const OrganizationProvider = ({ children }) => {
  const {
    organization,
    loading: isRequestingOrganization,
    slug,
    error
  } = useGetBranding()

  if (isRequestingOrganization) {
    return null
  }

  if (organization?.favicon) {
    favicon.set(organization.favicon)
    setOgTag('og:image', organization.favicon)
  }

  if (organization?.name) {
    document.title = organization.name
    setOgTag('og:title', organization.name)
  }

  return (
    <OrganizationContext.Provider
      value={{
        isRequestingOrganization,
        organization,
        organizationId: organization?.id,
        slug,
        orgError: error
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}

OrganizationProvider.propTypes = {
  children: PropTypes.node
}

export default OrganizationProvider
