import { getCachedTokens, setCachedTokens } from './cache'
import { cookieStorage } from './cookieStorage'
import { exchangeToken } from './exchange'
import { memoryStorage } from './memoryStorage'
import { unpackJWT } from './unpack'

const WEB_CLIENT_ID = process.env.REACT_APP_WEB_CLIENT_ID

const _createCognitoData = ({ refreshToken, idToken, accessToken }) => {
  const { sub } = unpackJWT(idToken)

  const keyPrefix = `CognitoIdentityServiceProvider.${WEB_CLIENT_ID}`
  return {
    [`${keyPrefix}.${sub}.idToken`]: idToken,
    [`${keyPrefix}.${sub}.accessToken`]: accessToken,
    [`${keyPrefix}.${sub}.refreshToken`]: refreshToken,
    'amplify-signin-with-hostedUI': false,
    [`${keyPrefix}.LastAuthUser`]: sub
  }
}

const _getStorage = name => {
  switch (name) {
    case 'cookie':
      return cookieStorage
    default:
      return memoryStorage
  }
}

export const hydrateAuthStorage = async (sso, storage = 'memory') => {
  try {
    const cognitoTokens = await exchangeToken(sso)
    if (sso.type !== 'authCode') {
      setCachedTokens(sso.value, cognitoTokens)
    }
    const data = _createCognitoData(cognitoTokens)
    const _storage = _getStorage(storage)
    _storage._hydrate(data)
  } catch (err) {
    console.error('Could not inject SSO from ', sso, err)
  }
}

export const hydrateFromCacheIfExists = (sso, storage = 'memory') => {
  if (sso.type === 'authCode') {
    return false
  }
  try {
    const cached = getCachedTokens(sso.value)
    if (!cached?.idToken || !cached?.accessToken || !cached?.refreshToken) {
      return false
    }
    const data = _createCognitoData(cached)
    const _storage = _getStorage(storage)
    _storage._hydrate(data)
    return true
  } catch (err) {
    console.warn('* Error while hydrating from cache', err)
    return false
  }
}
