import { paginationActions } from '@shared/hooks/usePaginatedState'
import { T } from '@shared/i18n'

export const useInvoicesPagination = ({
  state,
  dispatch,
  listInvoicesQuery
}) => {
  const { pivot, pageLimit } = state
  const { fetchMore, loading, nextPageToken, refetch } = listInvoicesQuery
  const hasMore = !!nextPageToken
  const total = listInvoicesQuery?.listInvoices?.length

  const handlePrev = () => {
    if (pivot < 2 * pageLimit) {
      dispatch({ type: paginationActions.SET_PIVOT, payload: pageLimit })
      return
    }

    dispatch({ type: paginationActions.SET_PIVOT, payload: pivot - pageLimit })
  }

  const handleNext = () => {
    dispatch({ type: paginationActions.SET_PIVOT, payload: pivot + pageLimit })
    if (pivot + pageLimit <= total && total !== pivot) {
      return
    }

    const missingItems = (pivot + pageLimit) % total
    dispatch({ type: paginationActions.SET_PIVOT, payload: pivot + pageLimit })
    if (hasMore && (missingItems > 0 || total === pivot)) {
      fetchMore({
        variables: {
          input: {
            count: missingItems === 0 ? pageLimit : missingItems,
            nextPageToken
          }
        }
      }).catch(e => {
        console.error(' * Error while fetching more invoices', e)
      })
    }
  }

  const handleChangeCounter = async newCounter => {
    const newNumber = parseInt(newCounter)
    dispatch({
      type: paginationActions.SET_PIVOT,
      payload: newNumber
    })
    dispatch({ type: paginationActions.SET_PAGE_LIMIT, payload: newNumber })
    if (newNumber > total) {
      await refetch(newCounter)
    }
  }

  const paginationConfig = {
    loading,
    pageLimit,
    actions: {
      next: {
        handleNext,
        disabled: (!hasMore && total <= pivot) || loading
      },
      prev: {
        handlePrev,
        disabled: pivot === pageLimit || loading
      },
      changeCounter: handleChangeCounter
    },
    labels: {
      next: <T label='pagination.next' />,
      previous: <T label='pagination.previous' />,
      show: <T label='pagination.show' />,
      results: <T label='pagination.results' />
    },
    resultsPerPage: [5, 25, 50]
  }

  return paginationConfig
}
