import { createContext, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

export const BrowserStackContext = createContext()

const BrowserStackProvider = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const [backStack, setBackStack] = useState([location])

  useEffect(() => {
    history.listen((location, action) => {
      setBackStack(backStack => {
        switch (action) {
          case 'POP':
            return backStack.slice(0, backStack.length - 1)
          case 'PUSH':
            return [...backStack, location]
          case 'REPLACE':
            if (isGoingBack(backStack)) {
              return backStack.slice(0, backStack.length - 1)
            }
            return [...backStack.slice(0, backStack.length - 1), location]
          default:
            break
        }
      })

      function isGoingBack(backStack) {
        return (
          backStack.length >= 2 &&
          backStack[backStack.length - 2].pathname === location.pathname
        )
      }
    })
  }, [setBackStack, history])

  return (
    <BrowserStackContext.Provider value={backStack}>
      {children}
    </BrowserStackContext.Provider>
  )
}
BrowserStackProvider.propTypes = {
  children: PropTypes.node
}

export default BrowserStackProvider
