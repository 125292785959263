import { Button, Card, Flex, Modal, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

export const ExpiredSessionModal = props => {
  const refresh = () => window.location.reload()
  return (
    <Modal
      backDrop='whiteShadow'
      alignItems='center'
      justifyContent='center'
      {...props}
    >
      <Modal.Content maxWidth='400px'>
        <Card p={5}>
          <Flex flexDirection='column'>
            <Typography variant='titleLargest' textAlign='center' mb={6}>
              <T label='label.sessionExpired' />
            </Typography>
            <Typography variant='labelSmall' textAlign='center' mb={6}>
              <T label='text.sessionExpired' />
            </Typography>
            <Button onClick={refresh} variant='primary' mx='auto'>
              <T label='label.refresh' />
            </Button>
          </Flex>
        </Card>
      </Modal.Content>
    </Modal>
  )
}
