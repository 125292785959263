import { useContext } from 'react'

import { AuthContext } from '@services/AuthProvider'
import CouponProvider from '@services/CouponProvider'
import { OrganizationContext } from '@services/OrganizationProvider'
import { Route, Switch } from 'react-router-dom'

import { NotFound } from '@shared/components'
import { isEmbedded, isLandingPage } from '@shared/funcs'
import { useOnRouteChange, useSwitchToAvailableLanguage } from '@shared/hooks'

import {
  Auth as AuthScreen,
  DiscoverLanding,
  EmbeddedRouter,
  ProductLanding,
  RegisterUserConsent
} from '../screens'
import ProtectedApp from './ProtectedApp'
import {
  AuthRoute,
  CookieBanner,
  DemoModeNotification,
  ProtectedRoute
} from './components'
import TermsSwitch, { tosRoutes } from './tosRoutes'

const AppRouter = () => {
  useSwitchToAvailableLanguage()
  useOnRouteChange(() => {
    window.scrollTo(0, 0)
  })

  const { organization, orgError } = useContext(OrganizationContext)
  const { isFetchingCognitoUser, isLoadingConsents } = useContext(AuthContext)

  if (!organization || orgError) {
    return <NotFound />
  }

  if (isLandingPage()) {
    return (
      <CouponProvider noStart>
        <CookieBanner />
        <Route path='/l/:lang/:slug/:pid' component={ProductLanding} />
        <Route path='/l/:lang/discover' component={DiscoverLanding} />
        <Route path='/lp/:lang/:slug/:pid' component={ProductLanding} />
      </CouponProvider>
    )
  }

  if (isFetchingCognitoUser || isLoadingConsents) {
    return null
  }

  if (isEmbedded()) {
    return (
      <CouponProvider noStart>
        <Route path='/embed/:lang' component={EmbeddedRouter} />
      </CouponProvider>
    )
  }

  return (
    <>
      {organization?.isDemoAccount && <DemoModeNotification />}
      <Switch>
        <AuthRoute path='/auth' component={AuthScreen} exact />
        <Route path='/consent' component={RegisterUserConsent} exact />
        <Route
          path={tosRoutes.map(({ path }) => path)}
          component={TermsSwitch}
          exact
        />
        <ProtectedRoute component={ProtectedApp} />
      </Switch>
    </>
  )
}

export default AppRouter
