import { useCallback, useContext, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import css from '@styled-system/css'
import styled from 'styled-components'

import { Flex, Icon, Tooltip } from '@etvas/etvaskit'

import { FooterTooltip } from './Layout/Footer'

const Content = styled.div(({ bgImage }) =>
  css({
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'top center',
    height: '288px',
    width: '100%',
    position: 'relative'
  })
)

const IconWrapper = styled.div({
  cursor: 'pointer'
})

const StyledTooltip = styled(Tooltip)(({ visible }) =>
  css({
    display: ['none', null, visible ? 'flex' : 'none']
  })
)

export const Footer = props => {
  const { organization } = useContext(OrganizationContext)
  const [tooltip, setTooltip] = useState(false)

  const show = useCallback(() => setTooltip(true), [setTooltip])
  const hide = useCallback(() => setTooltip(false), [setTooltip])

  const hasFooterImage = Boolean(organization.footerImage)
  const footerImage = hasFooterImage ? organization.footerImage : '/footer.png'

  return (
    <Content bgImage={footerImage} {...props}>
      {!hasFooterImage && (
        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='flex-end'
          height='100%'
          pb='100px'
        >
          <StyledTooltip visible={tooltip} mb={2}>
            <FooterTooltip />
          </StyledTooltip>
          <IconWrapper
            onMouseOver={show}
            onMouseOut={hide}
            onBlur={hide}
            onFocus={show}
          >
            <Icon name='heart' color='backgroundInputGray' size='large' />
          </IconWrapper>
        </Flex>
      )}
    </Content>
  )
}
