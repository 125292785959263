import { useQuery } from '@apollo/client'

import { GET_PURCHASE_DETAILS } from './graphql/queries'

export const usePollingActivation = purchaseId => {
  const { data: response, stopPolling } = useQuery(GET_PURCHASE_DETAILS, {
    variables: { input: { id: purchaseId } },
    fetchPolicy: 'no-cache',
    pollInterval: 500
  })

  return { activation: response?.getPurchaseDetails, stopPolling }
}
