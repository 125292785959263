export const mergePaginatedItems = (existing, incoming, { readField }) => {
  const { items: existingItems } = existing ?? {}
  const { items: incomingItems, ...restIncoming } = incoming ?? {}

  const mergedItems = existingItems ? existingItems.slice(0) : []
  const existingIds = mergedItems.map(item => readField('id', item))
  const filterIncoming = incomingItems.filter(item => {
    const itemId = readField('id', item)
    if (existingIds.indexOf(itemId) === -1) {
      return true
    }

    return false
  })

  mergedItems.push(...filterIncoming)

  return {
    items: mergedItems,
    ...restIncoming
  }
}
