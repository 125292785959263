import { useContext, useEffect, useRef } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

import { Box, Button, Flex, Form, TextField, Typography } from '@etvas/etvaskit'

import {
  getExternalId,
  getSimplifiedCognitoUser,
  loginWithTrace,
  removeExternalId,
  storage,
  userApi
} from '@shared/funcs'
import { I18nContext, T } from '@shared/i18n'

export const ConfirmEmailCode = ({ userData, setUser, state, retryLogin }) => {
  const history = useHistory()
  const { search } = useLocation()
  const { organizationId } = useContext(OrganizationContext)

  const { translate } = useContext(I18nContext)
  const verficationCodeRef = useRef()

  const _confirmEmailCode = async ({ code }, { setSubmitting, setStatus }) => {
    const params = new URLSearchParams(search)
    setStatus()
    try {
      const externalId = getExternalId()

      await userApi.confirmSignUp(userData.email, code, {
        bpId: organizationId,
        ...(externalId ? { externalId } : {})
      })

      removeExternalId()

      await new Promise(resolve => setTimeout(resolve, 3000))

      const user = await loginWithTrace(
        userData.email.toLowerCase(),
        userData.password,
        { bpId: organizationId }
      )

      if (user.challengeName === 'SMS_MFA') {
        setUser(user)

        storage.authSavedUser = getSimplifiedCognitoUser(user)

        params.set('state', `sms_code_${state}`)
        history.push({ search: params.toString() })

        return
      }

      const stateParam = params.get('state')
      if (stateParam) {
        params.delete('state')
      }
      history.replace({ search: params.toString() })
    } catch (error) {
      switch (error.code) {
        case 'CodeMismatchException':
          setStatus(<T label='label.emailCodeNotValid' />)
          setSubmitting(false)
          break

        case 'NotAuthorizedException':
          console.error(
            '* NotAuthorizedException caught while confirming email code',
            error
          )
          setSubmitting(false)
          retryLogin()
          break

        default:
          setStatus(<T label='text.errorRegisteringUser' />)
          console.error(
            '* Unexpected error caught while registering user',
            error
          )
          setSubmitting(false)
      }
    }
  }

  useEffect(() => {
    if (verficationCodeRef.current) {
      verficationCodeRef.current.focus()
    }
  }, [])

  return (
    <>
      <Box mb={4}>
        <Typography variant='base14Light'>
          <T label='text.verifyEmail' args={{ email: userData?.email }} />
        </Typography>

        <Typography variant='base14Light'>
          <T label='text.fillInValidationCode' />
        </Typography>
      </Box>

      <Form
        initialValues={{ code: '' }}
        validate={handleCodeValidation}
        onSubmit={_confirmEmailCode}
      >
        {({ isSubmitting, status }) => (
          <Flex
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
          >
            <TextField
              name='code'
              id='code'
              label={<T label='label.verificationCode' />}
              autoComplete='off'
              inputMode='numeric'
              placeholder={translate('label.6digitCode')}
              ref={verficationCodeRef}
              required
            />

            {status && (
              <Typography
                variant='base12Light'
                color='statusError'
                textAlign='center'
                mt={2}
                mb={4}
              >
                {status}
              </Typography>
            )}

            <Button
              variant='primary'
              mx='auto'
              type='submit'
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              <T label='label.validateCode' />
            </Button>
          </Flex>
        )}
      </Form>
    </>
  )
}

const handleCodeValidation = ({ code }) => {
  let errors = {}

  if (!code) {
    errors.code = <T label='label.required' />
  } else if (!/^\d+$/.test(code) || code.length !== 6) {
    errors.code = <T label='label.verificationCodeDigits' />
  }
  return errors
}

ConfirmEmailCode.propTypes = {
  userData: PropTypes.object,
  setUser: PropTypes.func,
  retryLogin: PropTypes.func,
  state: PropTypes.string.isRequired
}
