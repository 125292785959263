import { useContext, useMemo } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import { Box, Typography } from '@etvas/etvaskit'

import { productPurchase } from '@shared/constants/product'
import { formatPrice } from '@shared/funcs'
import { I18nContext } from '@shared/i18n'
import { productShape, purchaseStatus } from '@shared/models'

export const ChargeText = ({
  product,
  onlyWithTrial,
  hasWrapper,
  ...props
}) => {
  const { translate } = useContext(I18nContext)
  const hasTrial = useMemo(
    () => product.trialPeriod && product.trialUnit,
    [product]
  )

  if (onlyWithTrial) {
    if (!product.trialPeriod || !product.trialUnit) {
      return null
    }

    if (product.purchaseStatus === purchaseStatus.Purchased) {
      return null
    }
  }

  const {
    subscriptionMonths,
    subscriptionType,
    trialPeriod,
    trialUnit,
    price,
    currency
  } = product
  const day = (
    hasTrial ? moment().add(trialPeriod, trialUnit) : moment()
  ).format('LL')

  let chargeText = {
    label: 'label.chargeOnce',
    args: [day]
  }

  const labelSuffix = subscriptionMonths === 1 ? 'one' : 'many'

  if (subscriptionType === productPurchase.recurringSubscriptionType) {
    chargeText = {
      label: hasTrial
        ? `label.chargeTrialMonths.${labelSuffix}`
        : `label.chargeMonths.${labelSuffix}`,
      args: [day, subscriptionMonths, formatPrice(price, { currency })],
      mark: 1
    }
  }

  return (
    <Box
      p={hasWrapper ? 4 : 0}
      bg={hasWrapper ? 'brandLightest' : 'transparent'}
    >
      <Typography {...props}>
        <span
          dangerouslySetInnerHTML={{
            __html: translate(
              chargeText.label,
              chargeText.args,
              chargeText.mark
            )
          }}
        />
      </Typography>
    </Box>
  )
}

ChargeText.propTypes = {
  product: productShape,
  onlyWithTrial: PropTypes.bool,
  hasWrapper: PropTypes.bool
}

ChargeText.defaultProps = {
  onlyWithTrial: false,
  hasWrapper: false
}
