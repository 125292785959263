import { useMutation, useQuery } from '@apollo/client'

import { UPDATE_BILLING_INFO } from './graphql/mutations'
import { GET_BILLING_INFO } from './graphql/queries'

export const useBillingInfo = () => {
  const { data: billingInfoResult, loading: isLoadingBillingInfo } =
    useQuery(GET_BILLING_INFO)

  const [updateBillingInfo, { loading: isUpdatingBillingInfo }] =
    useMutation(UPDATE_BILLING_INFO)

  const requestUpdateBillingInfo = async input => {
    try {
      const { data } = await updateBillingInfo({
        variables: { input }
      })
      const billingInfo = data?.updateBillingInfo ?? null
      return { success: !!billingInfo, billingInfo }
    } catch (err) {
      console.error('* ERROR updating billing info', err)
      return {
        success: false,
        error: err.message ?? ''
      }
    }
  }

  const emptyBillingInfo = {
    name: '',
    vat: '',
    address: {
      line1: '',
      city: '',
      postalCode: '',
      countryCode: ''
    }
  }

  return {
    isLoadingBillingInfo,
    isUpdatingBillingInfo,
    requestUpdateBillingInfo,
    billingInfo: billingInfoResult?.getBillingInfo ?? emptyBillingInfo
  }
}
