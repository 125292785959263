import PropTypes from 'prop-types'

import { Card } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { Section } from '@shared/components'

const TitleCard = ({ title, children, ...props }) => (
  <Section title={<T label={title} />} {...props}>
    <Card flexGrow='1' p={6} pb={2}>
      {children}
    </Card>
  </Section>
)

TitleCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}

export default TitleCard
