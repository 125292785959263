import { v4 } from 'uuid'

import currentLocation from '@shared/funcs/currentLocation'
import isEmbeddedView from '@shared/funcs/isEmbedded'
import isLandingPage from '@shared/funcs/isLandingPage'
import { LocalStorage } from '@shared/store'

/**
 * Number of minutes after which the session is considered expired
 */
const SESSION_EXPIRES_IN_MINUTES = 60

/**
 * Storage session token name
 */
const SESSION_TOKEN_NAME = '_etv_sess'

const KEEP_ALIVE_GLOBAL = false

/**
 * Default storage mechanism.
 */
const STORAGE = LocalStorage

/** Helper */
const sessionLocation = isLandingPage() ? 'L' : isEmbeddedView() ? 'E' : 'C'

/**
 * What information should we collect in the session?
 */
const collector = [{ name: '_', collect: '1' }]

const getNamespace = () =>
  `${currentLocation.slug}${KEEP_ALIVE_GLOBAL ? '' : `-${sessionLocation}`}`

const generateId = () => `${currentLocation.slug}-${v4()}`
const _valueOf = expr => (typeof expr === 'function' ? expr() : expr)

const writeArchive = (sessions, storage = null) =>
  (storage || STORAGE).setItem(SESSION_TOKEN_NAME, JSON.stringify(sessions))

const cleanupArchive = (stored, storage = null) => {
  if (!stored) {
    return {}
  }
  const sessions =
    typeof stored === 'string' ? JSON.parse(stored) : { ...stored }
  const decay = SESSION_EXPIRES_IN_MINUTES * 6 * 1000
  let dirty = false
  Object.keys(sessions).forEach(namespace => {
    const session = sessions[namespace]
    if (session?.ts < decay) {
      delete sessions[namespace]
      dirty = true
    }
  })
  if (dirty) {
    writeArchive(sessions, storage)
  }
  return sessions
}

const readArchive = (storage = null) => {
  const stored = (storage || STORAGE).getItem(SESSION_TOKEN_NAME)
  if (!stored) {
    return {}
  }
  return cleanupArchive(stored, storage)
}

const archiver = {
  readItem: storage => {
    const ns = getNamespace()
    const sessions = readArchive(storage)
    return sessions[ns] || null
  },
  writeItem: (value, storage) => {
    const ns = getNamespace()
    writeArchive({
      ...readArchive(storage),
      [ns]: value
    })
  },
  destroyItem: storage => {
    const ns = getNamespace()
    const sessions = readArchive(storage)
    delete sessions[ns]
    writeArchive(sessions)
  }
}

const collect = (customCollector = null) =>
  (customCollector || collector).reduce(
    (result, info) => ({
      ...result,
      [info.name]: _valueOf(info.collect)
    }),
    {}
  )

const create = (customCollector = null, storage = null) => {
  const session = {
    id: generateId(),
    ts: Date.now(),
    lc: sessionLocation,
    info: collect(customCollector)
  }
  archiver.writeItem(session, storage)
  return session
}

const isActive = (customCollector = null, storage = null) => {
  const stored = archiver.readItem(storage)
  if (!stored) {
    return false
  }

  const decay = Date.now() - SESSION_EXPIRES_IN_MINUTES * 60 * 1000
  if (stored.ts < decay) {
    archiver.destroyItem(storage)
    return false
  }

  const collected = collect(customCollector)
  const isDifferent = Object.keys(collected).some(
    key => collected[key] !== stored.info[key]
  )
  return !isDifferent
}

const keepAlive = (customCollector = null, storage = null) => {
  if (!isActive(customCollector, storage)) {
    return null
  }
  const stored = archiver.readItem(storage)
  stored.ts = Date.now()
  const fresh = {
    ...stored,
    ts: Date.now()
  }
  archiver.writeItem(fresh, storage)
  return fresh
}

const getActive = (customCollector = null, storage = null) =>
  isActive(customCollector, storage) ? archiver.readItem(storage) : null

const isLocation = sess => ({
  Portal: sess.lc === 'C',
  Landing: sess.lc === 'L',
  Embedded: sess.lc === 'E'
})

const Prefixes = {
  Portal: '',
  Landing: 'landing.',
  Embedded: 'embedded.'
}

export const userSessionTrack = {
  Prefixes,
  create,
  keepAlive,
  isActive,
  getActive,
  isLocation,
  clear: () => archiver.destroyItem()
}
