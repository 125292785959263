import { useContext, useMemo, useState } from 'react'

import { AuthContext } from '@services/AuthProvider'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Card,
  Checkbox,
  Flex,
  Image,
  Typography
} from '@etvas/etvaskit'

import { useOnce } from '@shared/hooks'
import { T } from '@shared/i18n'

import { useWaitingList } from '../services/useWaitingList'

export const UnderMaintenance = ({ product, bypassMaintenance }) => {
  const { cognitoUser } = useContext(AuthContext)
  const history = useHistory()
  const [isSubscribed, setIsSubscribed] = useState(true)
  const { subscribeToWaitingList, unsubscribeFromWaitingList, loading, error } =
    useWaitingList('ProductUseResumed', product.id)

  const canTestProduct = useMemo(
    () =>
      cognitoUser?.userRoles?.find(role => role.type === 'platform') ||
      cognitoUser?.userRoles?.find(
        role =>
          role.type === 'partner' &&
          role.organizationId === product.servicePartner.id
      ),
    [cognitoUser?.userRoles, product.servicePartner.id]
  )

  useOnce(async () => {
    const response = await subscribeToWaitingList()
    if (response?.hasErrors) {
      setIsSubscribed(false)
    }
  })

  const handleCheckboxChange = async ev => {
    if (loading) {
      return
    }
    const didCheck = ev.target.checked
    setIsSubscribed(didCheck)
    const action = didCheck
      ? subscribeToWaitingList
      : unsubscribeFromWaitingList
    const response = await action()
    if (response?.hasErrors) {
      setIsSubscribed(prevCheck => !prevCheck)
    }
  }

  return (
    <Card pb={10} px={[6, 0]}>
      <Flex flexDirection='column' alignItems='center'>
        <Image src='/under-maintenance.png' mb={6} />
        <Typography variant='title32Light' mb={2}>
          <T label='title.underMaintenance' />
        </Typography>
        <Typography variant='base16Light' color='baseGray' textAlign='center'>
          <T label='text.underMaintenance1' args={{ product: product?.name }} />
        </Typography>
        <Typography
          variant='base16Light'
          color='baseGray'
          textAlign='center'
          mb={6}
        >
          <T label='text.underMaintenance2' />
        </Typography>
        <Checkbox
          tinted
          checked={isSubscribed}
          name='notify'
          label={<T label='text.notifyMaintenance' />}
          onChange={handleCheckboxChange}
        />
        {error && (
          <Typography
            variant='base14Light'
            color='statusError'
            textAlign='center'
          >
            <T label='error.unknown' />
          </Typography>
        )}
        <Flex mt={4}>
          <Button
            variant='primary'
            onClick={() => history.push('/my-products')}
          >
            <T label='label.takeMeBack' />
          </Button>
          {canTestProduct && (
            <Button variant='primary' onClick={bypassMaintenance} ml={3}>
              <T label='label.bypassMaintenance' />
            </Button>
          )}
        </Flex>
      </Flex>
    </Card>
  )
}

UnderMaintenance.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    servicePartner: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  bypassMaintenance: PropTypes.func
}
