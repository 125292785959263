import { useMemo } from 'react'

import PropTypes from 'prop-types'
import { Remarkable } from 'remarkable'

import { Typography } from '@etvas/etvaskit'

const Markdown = ({ md, options, ...props }) => {
  const converter = useMemo(
    () =>
      new Remarkable({
        html: false,
        breaks: true,
        xhtmlOut: true,
        ...options
      }),
    [options]
  )
  return (
    <Typography
      {...props}
      dangerouslySetInnerHTML={{ __html: converter.render(md) }}
    />
  )
}

Markdown.propTypes = {
  md: PropTypes.string,
  options: PropTypes.object
}

Markdown.defaultProps = {
  options: {}
}

export default Markdown
