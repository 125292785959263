import { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Grid } from '@shared/components'
import { productPurchase } from '@shared/constants'
import { slugify } from '@shared/funcs'
import { T } from '@shared/i18n'

import { CancelSubscriptionModal } from './components'
import { getPurchasedProductsGrid } from './grids/purchasedProducts'
import { usePurchasedProducts } from './services'

const {
  isOneTimeSubscription,
  isRecurringSubscription,
  isLifetimeSubscription,
  isFreeProduct
} = productPurchase

const PurchasedProducts = () => {
  const history = useHistory()
  const [cancelProduct, setCancelProduct] = useState(null)
  const { isLoadingPurchasedProducts, purchasedProducts } =
    usePurchasedProducts()

  const closeCancelModal = () => {
    setCancelProduct(null)
  }
  const openCancelModal = product => {
    setCancelProduct(product)
  }

  const isProductCancellable = product =>
    product?.isDemo ||
    ((isOneTimeSubscription(product) || isRecurringSubscription(product)) &&
      !isFreeProduct(product) &&
      !isLifetimeSubscription(product))

  const navigateToProductDetails = product => {
    const productLink = `/products/${slugify(product.name)}/${product.id}`
    history.push(productLink)
  }

  const grid = getPurchasedProductsGrid({
    isProductCancellable,
    openCancelModal,
    navigateToProductDetails
  })

  return (
    <>
      <Grid
        busy={isLoadingPurchasedProducts}
        items={purchasedProducts}
        name={grid.name}
        columns={grid.columns}
        emptyGridText={<T label='text.emptyGrid' />}
      />
      {cancelProduct !== null ? (
        <CancelSubscriptionModal
          closeModal={closeCancelModal}
          purchasedProduct={cancelProduct}
        />
      ) : null}
    </>
  )
}

export default PurchasedProducts
