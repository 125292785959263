import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Button, CardWithImage, Flex, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

export const EmptyBox = ({ children, noProducts, ...props }) => {
  const history = useHistory()
  const navigateToDiscover = () => {
    if (noProducts === 'link') {
      window.top.postMessage(
        {
          channel: 'etvas-channel',
          action: 'navigate-to',
          payload: { destination: 'discover' }
        },
        '*'
      )
      return
    }

    history.push('/')
  }
  return (
    <Box height={[600, 437, 437, 327]} {...props}>
      <StyledCardWithImage
        imageUrl='/empty-my-products.png'
        imageSize={1 / 2.4}
        width='100%'
        height='100%'>
        <Flex
          flexDirection='column'
          justifyContent='center'
          height='100%'
          py={[2, 8]}
          pl={[2, 8]}
          pr={0}>
          {children || (
            <>
              <Typography variant='title32Light'>
                <T label='text.noProducts' />
              </Typography>
              <Typography variant='base16Light' mt={4} mb={[4, 4, 4, 16]}>
                <T label='text.visitMyProducts' />
              </Typography>
            </>
          )}
          <Box mt={[4, 4, 4, 16]}>
            <Button variant='primary' onClick={navigateToDiscover}>
              <T label='button.discoverProducts' />
            </Button>
          </Box>
        </Flex>
      </StyledCardWithImage>
    </Box>
  )
}

const StyledCardWithImage = styled(CardWithImage)`
  background-color: unset;
  box-shadow: none;
  border: 1px dashed #d4dee8;
`

EmptyBox.propTypes = {
  children: PropTypes.node,
  noProducts: PropTypes.oneOf([true, 'link'])
}

EmptyBox.defaultProps = {
  noProducts: true
}
