import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '@services/graphql/clients'

import { purchaseStatus } from '@shared/models'

import { PURCHASE_PRODUCT } from './graphql/mutations'

export const usePurchaseProduct = () => {
  const [purchaseProduct, { loading: isPurchasing }] =
    useMutation(PURCHASE_PRODUCT)

  const requestPurchaseProduct = async id => {
    try {
      const { data } = await purchaseProduct({
        variables: {
          input: {
            id
          }
        }
      })

      if (data?.purchaseProduct?.status === purchaseStatus.Purchased) {
        try {
          apolloClient.writeFragment({
            id: `Product:${id}`,
            fragment: gql`
              fragment UpdatePurchaseStatus on Product {
                __typename
                purchaseId
                purchaseStatus
              }
            `,
            data: {
              purchaseId: data.purchaseProduct.id,
              purchaseStatus: purchaseStatus.Purchased,
              __typename: 'Product'
            }
          })
        } catch (err) {
          console.error(
            '* ERROR updating purchase details cache for a demo mode product',
            err
          )
        }
      }

      return { success: true, data: data.purchaseProduct }
    } catch (err) {
      console.error('* ERROR purchasing product', err)
      if (err.graphQLErrors?.length) {
        const [{ errorType, errorInfo }] = err.graphQLErrors
        if (errorType === 'ValidationException' && errorInfo.id === 'taken') {
          return {
            hasErrors: true,
            errorMessage:
              'It appears you have already purchased this product. Please refresh and check again.'
          }
        }
      }
      return {
        hasErrors: true,
        errorMessage:
          'An unexpected error occurred when purchasing product. Please try again later.'
      }
    }
  }

  return { isPurchasing, requestPurchaseProduct }
}
