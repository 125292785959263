import PropTypes from 'prop-types'

import { Button, Flex, Typography } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

const PurchaseFailed = ({ product, onFinish }) => (
  <Flex
    alignItems='center'
    justifyContent='center'
    flexDirection='column'
    p={6}
  >
    <Typography variant='titleLarge' mt={4} fontWeight='bold'>
      <T label='title.purchaseFailed' />
    </Typography>
    <Typography variant='labelSmall' mt={6}>
      <T label='text.purchaseFailed' args={[product.name]} />
    </Typography>
    <Button variant='primary' onClick={onFinish} mt={10}>
      <T label='label.close' />
    </Button>
  </Flex>
)

PurchaseFailed.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string
  }),
  onFinish: PropTypes.func
}

export default PurchaseFailed
