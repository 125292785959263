import { apolloClient } from '@services/graphql/clients'
import axios from 'axios'

import { GET_COGNITO_TOKENS } from '../graphql/queries'

export const exchangeToken = async sso =>
  sso.type === 'authCode'
    ? exchangeByAuthCode(sso.value)
    : exchangeByRefreshToken(sso.value)

const exchangeByAuthCode = async code => {
  const {
    data: {
      getCognitoTokens: { accessToken, idToken, refreshToken }
    }
  } = await apolloClient.query({
    context: { unauthenticated: true },
    fetchPolicy: 'network-only',
    query: GET_COGNITO_TOKENS,
    variables: { input: { code } }
  })
  return {
    accessToken,
    idToken,
    refreshToken
  }
}

const exchangeByRefreshToken = async refreshToken => {
  const endpoint = `https://${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/token`
  const userPoolWebClientId = process.env.REACT_APP_WEB_CLIENT_ID

  const params = [
    'grant_type=refresh_token',
    `client_id=${userPoolWebClientId}`,
    `refresh_token=${refreshToken}`
  ].join('&')

  const response = await axios.post(endpoint, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  return {
    accessToken: response.data.access_token,
    idToken: response.data.id_token,
    refreshToken: refreshToken
  }
}
