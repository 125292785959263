import { useState } from 'react'

import css from '@styled-system/css'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Flex, Icon } from '@etvas/etvaskit'

import { Sidebar } from '../../app/navigation/components'
import { Footer } from './LayoutFooter'
import { Logo } from './Logo'

export const AppLayout = ({ variant, children }) => {
  const { mobile, web } = variant

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      {isOpen && (
        <Shadow
          id='sidebar-shadow'
          onClick={toggle}
          left={{ [mobile]: SIDEBAR_WIDTH, [web]: 0 }}
        />
      )}
      <Flex id='page-container'>
        <SidebarTogglerWrapper
          width={isOpen ? SIDEBAR_WIDTH : TOPBAR_SIZE}
          height={TOPBAR_SIZE}
          display={{ [mobile]: 'block', [web]: 'none' }}
        >
          <SidebarToggler onClick={toggle}>
            <Icon
              name={isOpen ? 'backburger' : 'forwardburger'}
              color='baseWhite'
              size='medium'
            />
          </SidebarToggler>
        </SidebarTogglerWrapper>

        <Header
          height={{ [mobile]: TOPBAR_SIZE, [web]: LOGO_HEIGHT }}
          width={{ [mobile]: '100%', [web]: SIDEBAR_WIDTH }}
          px={{
            [mobile]: `calc(${TOPBAR_SIZE} + 12px)`,
            [web]: 4
          }}
          py={{
            [mobile]: 3,
            [web]: 4
          }}
          id='header'
        >
          <Logo />
        </Header>

        <SidebarWrapper
          id='sidebar'
          height={{
            [mobile]: `calc(100% -${TOPBAR_SIZE})`,
            [web]: `calc(100% -${LOGO_HEIGHT})`
          }}
          top={{ [mobile]: TOPBAR_SIZE, [web]: LOGO_HEIGHT }}
          width={SIDEBAR_WIDTH}
          left={{
            [mobile]: isOpen ? 0 : `-${SIDEBAR_WIDTH}`,
            [web]: 0
          }}
        >
          <Menu id='sidebar-menu' flexDirection='column' height='100%'>
            <Sidebar isOpen={isOpen} />
          </Menu>
        </SidebarWrapper>

        <LayoutPage
          id='layout-page'
          mt={{ [mobile]: TOPBAR_SIZE, [web]: 0 }}
          ml={{ [mobile]: 0, [web]: SIDEBAR_WIDTH }}
        >
          <LayoutBody
            id='layout-body'
            px={{ [mobile]: 2, [web]: 6 }}
            maxWidth={{ [mobile]: '100%', [web]: 1200 }}
          >
            {children}
          </LayoutBody>

          <FooterWrapper height={FOOTER_HEIGHT}>
            <Footer id='layout-footer' />
          </FooterWrapper>
        </LayoutPage>
      </Flex>
    </>
  )
}

const SIDEBAR_WIDTH = '240px'
const TOPBAR_SIZE = '58px'
const LOGO_HEIGHT = '80px'
const FOOTER_HEIGHT = '288px'
const TRANSITION = 'all 200ms ease-in-out'

const Header = styled(Box)(
  css({
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'white',
    zIndex: 8
  })
)
const Shadow = styled(Box)(
  css({
    zIndex: '9',
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'transparent'
  })
)

const LayoutBody = styled(Box)(
  css({
    paddingTop: 6
  })
)

const SidebarWrapper = styled(Box)(
  css({
    backgroundColor: 'brand',
    position: 'fixed',
    bottom: 0,
    zIndex: '9',
    transition: TRANSITION
  })
)

const Menu = styled(Flex)(
  css({
    overflowY: 'auto'
  })
)

const LayoutPage = styled(Box)(
  css({
    display: 'block',
    minHeight: '100vh',
    paddingBottom: FOOTER_HEIGHT,
    position: 'relative',
    width: '100%'
  })
)

const FooterWrapper = styled(Box)(
  css({
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0
  })
)

const SidebarTogglerWrapper = styled(Box)(
  css({
    transition: TRANSITION,
    zIndex: 9,
    top: 0,
    position: 'fixed'
  })
)

const SidebarToggler = styled.button(
  css({
    backgroundColor: 'brand',
    border: 'none',
    outline: 'none',
    width: '100%',
    height: '100%'
  })
)

AppLayout.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.shape({
    mobile: PropTypes.string,
    web: PropTypes.string
  })
}

AppLayout.defaultProps = {
  variant: { mobile: 'default', web: 'lg' }
}
