import { useContext, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import {
  Box,
  Button,
  Flex,
  Form,
  Space,
  TextField,
  Typography
} from '@etvas/etvaskit'

import { getExternalId, loginWithTrace, userApi } from '@shared/funcs'
import { I18nContext, T } from '@shared/i18n'
import { emailRegex, isPasswordWeak } from '@shared/validators'

import { CustomerLinks } from '../CustomerLinks'
import { ConfirmEmailCode } from './ConfirmEmailCode'

export const Register = ({ userData, updateUserData, goToCheckEmail }) => {
  const history = useHistory()
  const location = useLocation()
  const [needsEmailCodeConfirmation, setNeedsEmailCodeConfirmation] =
    useState(false)
  const { organizationId } = useContext(OrganizationContext)
  const { translate, language } = useContext(I18nContext)

  const initialValues = {
    firstName: '',
    lastName: '',
    email: userData?.email || '',
    password: ''
  }

  const handleSubmit = async (
    values,
    { setSubmitting, setStatus, setFieldStatus }
  ) => {
    try {
      setStatus()

      const externalId = getExternalId()

      const user = await userApi.signUp(
        userDataToApi(
          { ...values, locale: language },
          { bpId: organizationId, ...(externalId ? { externalId } : {}) }
        )
      )

      if (user.userConfirmed) {
        await loginWithTrace(userData.email.toLowerCase(), values.password, {
          bpId: organizationId
        })

        return
      }

      const params = new URLSearchParams(location.search)
      params.set('state', 'email_code_register')

      history.push({
        ...location,
        search: params.toString()
      })

      setNeedsEmailCodeConfirmation(true)
      updateUserData(values)
    } catch (error) {
      switch (error.code) {
        case 'UsernameExistsException':
          setFieldStatus('email', <T label='text.emailTaken' />)
          break
        default:
          setStatus(<T label='text.errorRegisteringUser' />)
          console.error(
            '* Unexpected error caught while registering user',
            error
          )
      }
      setSubmitting(false)
    }
  }

  if (needsEmailCodeConfirmation) {
    return (
      <ConfirmEmailCode
        userData={userData}
        setUser={() => {}}
        state='register'
      />
    )
  }

  return (
    <>
      <Space mb={4}>
        <Typography variant='base14Light'>
          <T label='text.fillPersonalInfo' />
        </Typography>
      </Space>

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={handleValidation}
      >
        {({ isSubmitting, status }) => (
          <>
            <Box mb={2}>
              <TextField
                required
                id='firstName'
                name='firstName'
                autoComplete='given-name'
                label={<T label='label.firstName' />}
                placeholder='John'
              />
              <TextField
                required
                id='lastName'
                name='lastName'
                autoComplete='family-name'
                label={<T label='label.lastName' />}
                placeholder='Marry'
              />
              <TextField
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                label={<T label='label.emailAddress' />}
                placeholder='john@doe.com'
                disabled
                required
              />
              <HiddenTextField
                id='hidden-field'
                name='hidden-field'
                type='text'
              />
              <TextField
                required
                id='password'
                type='password'
                name='password'
                autoComplete='new-password'
                label={<T label='label.password' />}
                placeholder={translate('placeholder.secret')}
              />
            </Box>
            {status && (
              <Typography
                variant='base12Light'
                color='statusError'
                textAlign='center'
                mb={4}
              >
                {status}
              </Typography>
            )}
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <CustomerLinks
                textAlign='center'
                label='text.agreeCreateLpAccount'
                mb={4}
              />
              <Flex alignItems='center' mb={4}>
                <Button
                  variant='primary'
                  type='submit'
                  disabled={isSubmitting}
                  mr={3}
                >
                  <T label='label.continue' />
                </Button>
                <Button
                  onClick={goToCheckEmail}
                  variant='link'
                  disabled={isSubmitting}
                >
                  <T label='label.changeEmail' />
                </Button>
              </Flex>
            </Flex>
          </>
        )}
      </Form>
    </>
  )
}

const handleValidation = ({ firstName, lastName, email, password }) => {
  let errors = {}

  if (!firstName || firstName.trim().length < 1) {
    errors.firstName = <T label='label.required' />
  }

  if (!lastName || lastName.trim().length < 1) {
    errors.lastName = <T label='label.required' />
  }

  if (!email) {
    errors.email = <T label='label.required' />
  } else if (!emailRegex.test(email)) {
    errors.email = <T label='text.invalidEmailFormat' />
  }

  if (!password) {
    errors.password = <T label='label.required' />
  } else if (isPasswordWeak(password)) {
    errors.password = <T label='text.passwordNotStrong' />
  }

  return errors
}

const userDataToApi = (userData, clientMetadata) => ({
  username: userData.email.toLowerCase(),
  password: userData.password,
  attributes: {
    email: userData.email.toLowerCase(),
    // eslint-disable-next-line camelcase
    given_name: userData.firstName,
    // eslint-disable-next-line camelcase
    family_name: userData.lastName,
    locale: userData.locale
  },
  clientMetadata
})

const HiddenTextField = styled(TextField)`
  position: absolute;
  left: -100px;
  top: -100px;
  width: 0;
  height: 0;
`

Register.propTypes = {
  userData: PropTypes.object,
  updateUserData: PropTypes.func,
  goToCheckEmail: PropTypes.func
}
