import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Button, Input } from '@etvas/etvaskit'

import { I18nContext } from '@etvas/i18n'

import { getObjectValue } from '@shared/funcs'
import { T } from '@shared/i18n'

import { useDebounce } from '../../services/hooks/useDebounce'

export const useFilterProducts = ({ products, filterBy }) => {
  const { translate } = useContext(I18nContext)
  const inputRef = useRef()
  const wrapperRef = useRef()
  const [isSearchExpanded, setIsSearchExpanded] = useState(false)
  const [searchValue, setSearchValue] = useState()
  const [filteredProducts, setFilteredProducts] = useState()
  const debouncedSearchTerm = useDebounce(searchValue, 200)

  const handleSearch = value => setSearchValue(value)

  useEffect(() => {
    if (debouncedSearchTerm === undefined) {
      return
    }
    const getValues = product =>
      filterBy.map(attribute => getObjectValue(product, attribute))
    const searchTerm = debouncedSearchTerm.trim().toLowerCase()
    const isMatching = strings =>
      strings.some(string => string.toLowerCase().includes(searchTerm))
    const filteredProducts = products.filter(product =>
      isMatching(getValues(product))
    )
    setFilteredProducts(filteredProducts)
  }, [debouncedSearchTerm, products, filterBy])

  useLayoutEffect(() => {
    let timer
    if (searchValue) {
      timer = setTimeout(() => {
        window.scrollTo(0, 0)
      }, 200)
    }
    return () => clearTimeout(timer)
  }, [searchValue])

  useLayoutEffect(() => {
    if (isSearchExpanded) {
      inputRef?.current?.focus()
    }
  }, [isSearchExpanded])

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        isSearchExpanded &&
        wrapperRef &&
        !wrapperRef.current.contains(event.target)
      ) {
        setIsSearchExpanded(state => !state)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [searchValue, isSearchExpanded])

  const FilterBox = (
    <Box ref={wrapperRef}>
      <HiddenWrapper hidden={!isSearchExpanded}>
        <Input
          id='search-products'
          value={searchValue}
          onChange={e => handleSearch(e.target.value)}
          type='search'
          noBottomSpace
          width={['100%', '100%', '500px']}
          placeholder={translate('label.searchProducts')}
          ref={inputRef}
        />
      </HiddenWrapper>

      <HiddenWrapper hidden={isSearchExpanded}>
        <ButtonWrapper
          variant='link'
          icon='magnify'
          iconPosition='right'
          color='textInputPlaceholder'
          onClick={() => setIsSearchExpanded(true)}
        >
          <T label='label.search' />
        </ButtonWrapper>
      </HiddenWrapper>
    </Box>
  )

  return [FilterBox, { filteredProducts }]
}

const ButtonWrapper = styled(Button)`
  height: 44px;
  color: ${({ theme }) => theme.colors.textInputPlaceholder} !important;
`

const HiddenWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`

HiddenWrapper.propTypes = {
  display: PropTypes.bool
}
