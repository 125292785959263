import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { Flex, Link, NotFound, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

const NotFoundPage = ({ showHomepageLink, ...props }) => (
  <Flex width='100%' height='100vh' {...props}>
    <NotFound>
      <Flex alignItems='center' flexDirection='column'>
        <Typography variant='titleLarge' mb={6}>
          <T label='title.pageNotFound' />
        </Typography>
        <Typography variant='textSmall' mb={10}>
          <T label='text.pageNotFound' />
        </Typography>
        {showHomepageLink ? (
          <Link component={RouterLink} to='/' variant='button'>
            <T label='label.goToHomepage' />
          </Link>
        ) : null}
      </Flex>
    </NotFound>
  </Flex>
)

NotFoundPage.propTypes = {
  showHomepageLink: PropTypes.bool
}

NotFoundPage.defaultProps = {
  showHomepageLink: true
}

export default NotFoundPage
