import { Flex, Link, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

export const ImprintSupport = props => {
  const supportEmail = 'support@etvas.com'

  return (
    <Flex flexDirection='row' justifyContent='center' {...props}>
      <Link
        href='https://www.etvas.com/de-de/impressum'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Typography variant={['labelSmall', 'textSmall']} color='outline'>
          <T label='text.imprint' />
        </Typography>
      </Link>
      <Typography variant={['labelSmall', 'textSmall']} color='outline'>
        &nbsp;{'|'}&nbsp;
      </Typography>
      <Link target='_blank' href={`mailto:${supportEmail}`}>
        <Typography variant={['labelSmall', 'textSmall']} color='outline'>
          <T label='text.support' />
        </Typography>
      </Link>
    </Flex>
  )
}
