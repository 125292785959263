const sendWindowMessage = (action, payload, channel = 'etvas-channel') => {
  if (!window || !window.top) {
    return false
  }
  window.top.postMessage(
    {
      channel,
      action,
      payload
    },
    '*'
  )
  return true
}

export default sendWindowMessage
