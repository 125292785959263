import { Box, Image, Typography } from '@etvas/etvaskit'

import { servicePartnerShape } from '@shared/models'

export const ServicePartnerLogo = ({ servicePartner, ...props }) => {
  const { name, logoSmall } = servicePartner
  return (
    <Box height='24px' {...props}>
      {logoSmall ? (
        <Image src={logoSmall} alt={name} height='100%' />
      ) : (
        <Typography variant='labelButton' color='lightGray'>
          {name}
        </Typography>
      )}
    </Box>
  )
}

ServicePartnerLogo.propTypes = {
  servicePartner: servicePartnerShape.isRequired
}
