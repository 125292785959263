import { useEffect } from 'react'

import { useTermsPage } from '@services/hooks/useTermsPage'
import PropTypes from 'prop-types'

import { ActivityIndicator, Box, Flex, Typography } from '@etvas/etvaskit'

import { getQueryParam } from '@shared/funcs'
import { T, setLanguage } from '@shared/i18n'

export const CashbackTermsAndConditions = () => (
  <TermsPage {...config.cashbackTermsAndConditions} />
)
export const CashbackPrivacyPolicy = () => (
  <TermsPage {...config.cashbackPrivacyPolicy} />
)
export const CashbackDataProcessing = () => (
  <TermsPage {...config.cashbackDataProcessing} />
)

const config = {
  cashbackTermsAndConditions: {
    title: 'title.cashback.termsAndConditions',
    pageId: 'cashbackTermsAndConditions'
  },
  cashbackPrivacyPolicy: {
    title: 'title.cashback.privacyPolicy',
    pageId: 'cashbackPrivacyPolicy'
  },
  cashbackDataProcessing: {
    title: 'title.cashback.dataProcessing',
    pageId: 'cashbackDataProcessing'
  }
}

const TermsPage = ({ title, pageId, ...props }) => {
  useEffect(() => {
    const lang = getQueryParam('lang')
    setLanguage(lang ?? 'en')
  }, [])

  const { isLoading, page } = useTermsPage(pageId)

  if (isLoading) {
    return (
      <ActivityIndicator
        variant='runningbar'
        colors={{ background: 'transparent', primary: 'brand' }}
      />
    )
  }
  const { html } = page

  return (
    <Flex {...props} justifyContent='center' flexDirection='column'>
      <Typography variant='title32Light' mb={[3, 6]}>
        <T label={title} />
      </Typography>
      <Box px={2}>
        <Typography>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Typography>
      </Box>
    </Flex>
  )
}

TermsPage.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired
}
