const replaceLink = (rel, url) => {
  const link = document.createElement('link')
  const existingLink = document.querySelector(`link[rel*='${rel}']`)
  link.type = 'image/png'
  link.rel = rel
  link.href = url

  // eslint-disable-next-line prefer-destructuring
  const head = document.getElementsByTagName('head')[0]

  if (existingLink) {
    head.removeChild(existingLink)
  }
  head.appendChild(link)
}

const set = url => {
  replaceLink('icon', url)
  replaceLink('apple-touch-icon', url)
}

export const favicon = {
  set
}
