import PropTypes from 'prop-types'

import { Box, CardSkeleton, Flex, Grid, TextSkeleton } from '@etvas/etvaskit'

const CardTextSkeleton = ({ contentLines, footerLines, ...props }) => (
  <Flex
    height='100%'
    flexDirection='column'
    justifyContent='space-between'
    {...props}
  >
    <TextSkeleton width='75%' />
    <TextSkeleton lines={contentLines} />
    <TextSkeleton width={['60%', '30%']} lines={footerLines} />
  </Flex>
)

CardTextSkeleton.propTypes = {
  contentLines: PropTypes.number,
  footerLines: PropTypes.number
}

export const AvailableProductsSkeleton = () => (
  <Flex width='100%' flexDirection='column'>
    <CardSkeleton
      height={352}
      width='100%'
      imageSize={0.4}
      imagePosition='left'
    >
      <CardTextSkeleton contentLines={3} footerLines={2} maxWidth='80%' />
    </CardSkeleton>

    <Box mt={12} mb='45px'>
      <Grid cols={2} hspace='1.5rem' vspace='1.5rem'>
        {[...Array(4).keys()].map(index => (
          <Grid.Item key={index}>
            <CardSkeleton height={322} imageSize={0.4} imagePosition='left'>
              <CardTextSkeleton contentLines={3} footerLines={1} width='80%' />
            </CardSkeleton>
          </Grid.Item>
        ))}
      </Grid>
    </Box>
  </Flex>
)
