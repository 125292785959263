import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

import css from '@styled-system/css'
import PropTypes from 'prop-types'
import Swipe from 'react-easy-swipe'
import styled from 'styled-components'

import { Box, Flex, Icon, Touchable, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

import { isEmbedded, isImage } from '../funcs'
import styles from './MediaSlider.styles'
import { VideoPlayer } from './VideoPlayer'

const MOBILE_SLIDE_OFFSET = 30

const ProductSlider = ({
  media,
  ratio,
  initialIndex = 0,
  isSingle,
  onSelect
}) => {
  const slides = useMemo(
    () =>
      media.map(url => ({
        id: Math.floor(10000 * Math.random()),
        src: url,
        isImage: isImage(url)
      })),
    [media]
  )

  const [currentIndex, setCurrentIndex] = useState(initialIndex)
  const [slideWidth, setSlideWidth] = useState(992 / 2)
  const [slideHeight, setSlideHeight] = useState('100%')
  const [doubled, setDoubled] = useState(1)
  const [swipeStart, setSwipeStart] = useState(0)
  const [swipeOffset, setSwipeOffset] = useState(0)
  const [swipeDuration, setSwipeDuration] = useState(0)

  useLayoutEffect(() => {
    const adjustWidth = () => {
      const el = document.getElementById('slider-container')
      const containerWidth = el.offsetWidth
      const mediaWidth = containerWidth / 2
      if (containerWidth > 600 && !isSingle) {
        setDoubled(2)
        setSlideWidth(mediaWidth)
        setSlideHeight(containerWidth / 2 / ratio)
      } else {
        setDoubled(1)
        setSlideWidth(containerWidth)
        setSlideHeight(containerWidth / ratio)
      }
    }

    window.addEventListener('resize', adjustWidth)
    adjustWidth()
    return () => {
      window.removeEventListener('resize', adjustWidth)
    }
  }, [isSingle, ratio])

  const swipe = dir => {
    let nextIndex = currentIndex + dir
    if (dir === -1 && nextIndex < 0) {
      nextIndex = slides.length - doubled
    } else if (dir === 1 && nextIndex > slides.length - doubled) {
      nextIndex = 0
    }
    setCurrentIndex(nextIndex)
  }

  const _onSwipeStart = () => {
    setSwipeStart(Date.now())
    setSwipeOffset(0)
  }
  const _onSwipeEnd = () => {
    setSwipeStart(0)
    if (swipeOffset > 100 && currentIndex > 0) {
      swipe(-1)
    } else if (swipeOffset < -100 && currentIndex < slides.length - doubled) {
      swipe(1)
    }
    setSwipeDuration(Date.now() - swipeStart)
    setSwipeOffset(0)
  }

  const handleClick = idx => {
    if (isEmbedded()) {
      return
    }

    if (onSelect && swipeDuration < 300) {
      onSelect(idx)
    }
    setSwipeDuration(0)
  }

  const _onSwipeMove = position => {
    setSwipeOffset(position.x)
  }

  const _currentPosition = (() =>
    -1 * currentIndex * slideWidth +
    (doubled === 1 ? MOBILE_SLIDE_OFFSET * currentIndex : 0) +
    swipeOffset)()

  const scrollStyles = {
    position: 'absolute',
    transform: `translate3d(${_currentPosition}px, 0, 0)`,
    width: `${
      slideWidth * slides.length -
      (doubled === 1 ? MOBILE_SLIDE_OFFSET * (slides.length - 1) : 0)
    }px`
  }

  if (!swipeStart) {
    scrollStyles.transition = 'all .25s cubic-bezier(.82 ,0, .22, .99)'
  }

  const mediaWidth = useCallback(
    idx =>
      doubled === 1 && idx !== media.length - 1
        ? slideWidth - MOBILE_SLIDE_OFFSET
        : slideWidth,
    [doubled, media.length, slideWidth]
  )

  return (
    <>
      <Flex width='100%' height={slideHeight}>
        <StyledContainer id='slider-container'>
          <StyledSwipe
            onSwipeStart={_onSwipeStart}
            onSwipeMove={_onSwipeMove}
            allowMouseEvents={true}
            onSwipeEnd={_onSwipeEnd}>
            <Flex style={scrollStyles}>
              {slides.map((slide, idx) =>
                slide.isImage ? (
                  <StyledImage
                    key={slide.id}
                    url={slide.src}
                    height={slideHeight}
                    width={mediaWidth(idx)}
                    onClick={() => handleClick(idx)}
                  />
                ) : (
                  <StyledVideo
                    key={slide.id}
                    height={slideHeight}
                    width={mediaWidth(idx)}
                    onClick={() => handleClick(idx)}>
                    <VideoPlayer
                      style={{ objectFit: isSingle ? 'contain' : 'cover' }}
                      loop
                      muted={!(isSingle && currentIndex === idx)}
                      controlsList='nodownload noremoteplayback'
                      disablePictureInPicture
                      height={isSingle ? 'auto' : '100%'}
                      width={isSingle ? '100%' : 'auto'}
                      video={slide.src}
                    />
                  </StyledVideo>
                )
              )}
            </Flex>
          </StyledSwipe>
        </StyledContainer>
      </Flex>
      <Flex mt={3} justifyContent='space-between'>
        <StyledTouch onClick={() => swipe(-1)}>
          <Flex>
            <StyledIcon name='chevronLeft' />
            <Typography variant='labelButton' color='brand'>
              <T label='label.prev' />
            </Typography>
          </Flex>
        </StyledTouch>
        <StyledTouch onClick={() => swipe(1)}>
          <Flex>
            <Typography variant='labelButton' color='brand'>
              <T label='label.next' />
            </Typography>
            <StyledIcon name='chevronRight' />
          </Flex>
        </StyledTouch>
      </Flex>
    </>
  )
}

const StyledSwipe = styled(Swipe)(css(styles.swipeContainer))
const StyledIcon = styled(Icon)(css(styles.icon))
const StyledTouch = styled(Touchable)(css(styles.nav))
const StyledContainer = styled(Box)(css(styles.container))

const StyledImage = styled(Box).attrs(({ url, width, height }) => ({
  style: {
    height,
    backgroundImage: `url(${url})`
  }
}))(css(styles.image))

const StyledVideo = styled(Box).attrs(({ width, height }) => ({
  style: {
    height,
    overflow: 'hidden'
  }
}))(css(styles.image))

ProductSlider.propTypes = {
  media: PropTypes.arrayOf(PropTypes.string),
  initialIndex: PropTypes.number,
  isSingle: PropTypes.bool,
  ratio: PropTypes.number,
  onSelect: PropTypes.func
}

export const MediaSlider = ({
  media,
  initialIndex,
  onSelect,
  isSingle,
  ratio
}) => {
  switch (media.length) {
    case 0:
      return null

    case 1:
      return (
        <MediaWrapper>
          {isImage(media[0]) ? (
            <StyledSingleImage src={media[0]} />
          ) : (
            <VideoPlayer loop muted video={media[0]} />
          )}
        </MediaWrapper>
      )
    default:
      return (
        <ProductSlider
          initialIndex={initialIndex}
          isSingle={isSingle}
          onSelect={onSelect}
          media={media}
          ratio={ratio}
        />
      )
  }
}

const MediaWrapper = styled.div(css(styles.singleMediaWrapper))
const StyledSingleImage = styled.img(css(styles.singleImage))

MediaSlider.propTypes = {
  media: PropTypes.arrayOf(PropTypes.string),
  isSingle: PropTypes.bool,
  initialIndex: PropTypes.number,
  ratio: PropTypes.number,
  onSelect: PropTypes.func
}
