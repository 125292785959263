import { useReducer } from 'react'

export const paginationActions = {
  SET_PIVOT: 'SET_PIVOT',
  RESET_PIVOT: 'RESET_PIVOT',
  SET_PAGE_LIMIT: 'SET_PAGE_LIMIT',
  SET_FILTER_START: 'SET_FILTER_START',
  SET_FILTER_END: 'SET_FILTER_END',
  RESET_FILTERS: 'RESET_FILTERS'
}

export const usePaginatedState = (pageLimit = 20) => {
  const initialControlState = {
    pivot: pageLimit,
    pageLimit: pageLimit,
    filter: {
      start: undefined,
      end: undefined
    }
  }

  const dataReducer = (state, { type, payload }) => {
    switch (type) {
      case paginationActions.SET_PIVOT:
        return {
          ...state,
          pivot: payload
        }
      case paginationActions.RESET_PIVOT:
        return {
          ...state,
          pivot: pageLimit
        }
      case paginationActions.SET_PAGE_LIMIT:
        return {
          ...state,
          pageLimit: payload
        }
      case paginationActions.SET_FILTER_END:
        return {
          ...state,
          pivot: state.pageLimit,
          filter: { ...state.filter, end: payload }
        }
      case paginationActions.SET_FILTER_START:
        return {
          ...state,
          pivot: state.pageLimit,
          filter: { ...state.filter, start: payload }
        }
      case paginationActions.RESET_FILTERS:
        return {
          ...state,
          pivot: state.pageLimit,
          filter: { ...initialControlState.filter }
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(dataReducer, initialControlState)

  return { state, dispatch }
}
