import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { extractErrorsFromException } from '@shared/funcs'

import { DELETE_MY_ACCOUNT, UPDATE_PROFILE_DATA } from './graphql/mutations'

export const useProfile = () => {
  const [deleteProfile, { loading: isDeletingProfile }] =
    useMutation(DELETE_MY_ACCOUNT)

  const [updateProfile, { loading: isUpdatingProfile }] =
    useMutation(UPDATE_PROFILE_DATA)

  const requestDeleteProfile = useCallback(async () => {
    try {
      const { data } = await deleteProfile()
      return data?.deleteMyAccount?.success
    } catch (error) {
      console.error('* Error while deleting profile', error)
      return false
    }
  }, [deleteProfile])

  const requestUpdateProfile = useCallback(
    async ({ firstName, lastName, locale }) => {
      try {
        const input = { firstName, lastName, locale }

        await updateProfile({ variables: { input } })
        return true
      } catch (error) {
        const mappedErrors = extractErrorsFromException(error)

        console.error(
          '* ERROR caught while updating customer profile data',
          error
        )
        return mappedErrors
      }
    },
    [updateProfile]
  )

  return {
    requestDeleteProfile,
    isDeletingProfile,
    requestUpdateProfile,
    isUpdatingProfile
  }
}
