import { T } from '@shared/i18n'

import { emailRegex } from './emailRegex'

const validateSignIn = ({ username, password }) => {
  let errors = {}

  if (!username) {
    errors.username = <T label='label.required' />
  } else if (!emailRegex.test(username)) {
    errors.username = <T label='text.invalidEmailFormat' />
  }
  if (!password) {
    errors.password = <T label='label.required' />
  }

  return errors
}

export default validateSignIn
