import { useContext, useEffect, useLayoutEffect, useState } from 'react'

import { CouponContext } from '@services/CouponProvider'
import { useTracking } from '@services/hooks/useTracking'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Card, Flex, Icon, Modal, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'
import { LocalStorage } from '@shared/store'

export const CouponModal = props => {
  const {
    discountValue,
    requestAttachPaymentCoupon,
    couponCode,
    isPaymentCouponUpdating,
    isPaymentCouponFetching,
    elapsed,
    isCouponAvailable,
    isCouponTaken,
    canAttachPaymentCoupon,
    setShowBanner
  } = useContext(CouponContext)

  const [isModalShown, setModalShown] = useState(false)
  const [neverShowAgain, setNeverShowAgain] = useState(
    !!LocalStorage.getItem(`coupon-${couponCode}`)
  )
  const { track } = useTracking()

  const neverShowModal = () => {
    setNeverShowAgain(true)
    LocalStorage.setItem(`coupon-${couponCode}`, true)
  }

  const closeModal = () => {
    track({
      name: 'modal.hide',
      value: 'coupon'
    })
    setModalShown(false)
  }
  const dontShowAndClose = () => {
    track({
      name: 'button.click',
      value: 'coupon.never'
    })
    neverShowModal()
    closeModal()
  }

  useEffect(() => {
    if (isCouponTaken) {
      const timer = setTimeout(() => {
        setShowBanner(false)
      }, 2500)

      return () => clearTimeout(timer)
    }
  }, [isCouponTaken, setShowBanner])

  useEffect(() => {
    if (
      !isPaymentCouponFetching &&
      elapsed &&
      !neverShowAgain &&
      isCouponAvailable &&
      canAttachPaymentCoupon &&
      !isCouponTaken
    ) {
      setModalShown(true)
    } else {
      setModalShown(false)
    }
  }, [
    isPaymentCouponFetching,
    elapsed,
    neverShowAgain,
    isCouponAvailable,
    canAttachPaymentCoupon,
    setModalShown,
    isCouponTaken
  ])

  useLayoutEffect(() => {
    if (isModalShown) {
      track({
        name: 'modal.show',
        value: 'coupon'
      })
    }
  }, [track, isModalShown])

  const handleConfirm = async () => {
    track({
      name: 'button.click',
      value: 'coupon.redeem-modal'
    })
    const { success } = await requestAttachPaymentCoupon(couponCode)

    if (!success) {
      console.error('Error attaching payment coupon')
      return
    }
    setTimeout(closeModal, 3000)
  }

  if (!isModalShown) {
    return null
  }

  return (
    <T>
      {({ translate }) => (
        <Modal
          backDrop='whiteShadow'
          alignItems='center'
          justifyContent='center'
          {...props}
        >
          <ModalContent onClose={closeModal}>
            <ModalCard
              width={['90vw', '90vw', '90vw', '41vw']}
              height={['90vh', 'auto']}
              px={[5, 9]}
              pt={9}
              pb={6}
              backgroundImage={`url(/bg-coupon.png)`}
              backgroundSize='cover'
              backgrounRepeat='no-repeat'
              backgroundPosition='center'
              borderRadius='8px'
            >
              <Text variant='labelLarge' color='white' mb={8}>
                <b>{translate('title.discountPopup')}</b>
              </Text>
              <Text
                variant='titleLargest'
                size={48}
                sizeMobile={38}
                color='white'
                lineHeight={64}
                lineHeightMobile={40}
                mb={6}
                dangerouslySetInnerHTML={{
                  __html: translate('text.discountPopup', {
                    discount: discountValue
                  })
                }}
              />
              <Text
                variant='textSmall'
                color='white'
                size={14}
                lineHeight={24}
                mb={6}
              >
                {translate('text.discountBanner', {
                  discount: discountValue,
                  _discount: discountValue
                })}
              </Text>

              <Flex
                flexWrap='wrap'
                alignItems='center'
                mt={'auto'}
                flexDirection={['column-reverse', 'row']}
                justifyContent='space-between'
              >
                <HideLink
                  variant='textSmall'
                  color='white'
                  onClick={dontShowAndClose}
                >
                  {translate('popup.option')}
                </HideLink>
                <Button
                  type='button'
                  onClick={handleConfirm}
                  variant={!isCouponTaken ? 'primary' : 'positive'}
                  width={['100%', 'auto']}
                  mb={['25px', '0px']}
                  loading={isPaymentCouponUpdating}
                >
                  {!isCouponTaken ? (
                    translate('button.discountBanner')
                  ) : (
                    <>
                      <Icon name='checkMark' />
                      {translate('coupon.added')}
                    </>
                  )}
                </Button>
              </Flex>
            </ModalCard>
          </ModalContent>
        </Modal>
      )}
    </T>
  )
}

const ModalContent = styled(Modal.Content)`
  button {
    color: white;
    font-size: 32px;
  }
`

const Text = styled(Typography)`
  font-size: ${props => props.size}px;
  line-height: ${props => props.lineHeight}px;
  height: unset;
  width: unset;
  overflow: hidden;

  @media (max-width: 600px) {
    line-height: ${props => props.lineHeightMobile}px;
    font-size: ${props => props.sizeMobile}px;
  }
`

const HideLink = styled(Typography)`
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`

const ModalCard = styled(Card)`
  display: flex;
  flex-direction: column;
`

CouponModal.propTypes = {
  closeModal: PropTypes.func,
  discount: PropTypes.number,
  dontShowAgain: PropTypes.func,
  couponCode: PropTypes.string,
  requestAttachPaymentCoupon: PropTypes.func,
  isPaymentCouponUpdating: PropTypes.bool,
  isCouponTaken: PropTypes.bool
}
