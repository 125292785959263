import { useContext } from 'react'

import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

import { AuthContext } from 'app/services/AuthProvider'

const AuthRoute = ({ children, component, ...rest }) => {
  const { authenticated } = useContext(AuthContext)

  if (authenticated) {
    return <Redirect to='/' />
  }
  return (
    <Route component={component} {...rest}>
      {children}
    </Route>
  )
}

AuthRoute.propTypes = {
  children: PropTypes.node,
  component: PropTypes.func
}

export default AuthRoute
