import { useContext, useEffect, useMemo, useRef } from 'react'

import { useTracking } from '@services/hooks/useTracking'
import PropTypes from 'prop-types'

import { Box, Button, Form, Grid, Typography } from '@etvas/etvaskit'

import { ModalDialog } from '@shared/components'
import { currentCountryCode } from '@shared/constants'
import { isTintedPreference } from '@shared/funcs'
import { I18nContext, T } from '@shared/i18n'
import { useBillingInfo } from '@shared/services/useBillingInfo'

import FormFragment from './FormFragment'
import ModalSkel, { Skeleton } from './ModalSkel'
import { b2i, handleValidation, i2b } from './utils'

const BillingInfoModal = ({
  product,
  onCancel,
  onProceed,
  showModal,
  ...props
}) => {
  const didTrackEvent = useRef(false)
  const modalWidth = ['90%', '400px']
  const { translate } = useContext(I18nContext)

  const {
    billingInfo,
    requestUpdateBillingInfo,
    isLoadingBillingInfo,
    isUpdatingBillingInfo
  } = useBillingInfo()

  const initialValues = useMemo(
    () =>
      b2i(billingInfo, {
        defaultCountryCode: currentCountryCode?.toUpperCase()
      }),
    [billingInfo]
  )

  const { track } = useTracking()

  const isEmptyBillingInfo = useMemo(
    () =>
      !billingInfo?.name ||
      !billingInfo?.address?.line1 ||
      !billingInfo?.address?.postalCode ||
      !billingInfo?.address?.countryCode ||
      !billingInfo?.address?.city,
    [billingInfo]
  )

  useEffect(() => {
    if (billingInfo && !isLoadingBillingInfo && !isEmptyBillingInfo) {
      onProceed()
    }
  }, [billingInfo, isEmptyBillingInfo, isLoadingBillingInfo, onProceed])

  useEffect(() => {
    if (
      !isLoadingBillingInfo &&
      isEmptyBillingInfo &&
      product &&
      !didTrackEvent.current
    ) {
      track({
        name: 'modal.show',
        value: 'billing-info',
        context: { productId: product?.id }
      })
      didTrackEvent.current = true
    }
  }, [isEmptyBillingInfo, product, isLoadingBillingInfo, track])

  const handleSubmit = async values => {
    const payload = i2b(values)
    track({
      name: 'button.click',
      value: 'billing-info-save',
      context: { productId: product.id }
    })
    const { success } = await requestUpdateBillingInfo(payload)
    if (success) {
      onProceed()
    }
  }

  const isLoading = isLoadingBillingInfo || !isEmptyBillingInfo

  if (isLoading) {
    return showModal ? (
      <ModalSkel width={modalWidth} />
    ) : (
      <Skeleton noShadow width='100%' />
    )
  }

  return (
    <BillingInfoWrapper
      showModal={showModal}
      p={showModal ? 6 : 0}
      onCancel={onCancel}
      title={<T label='title.billingInformation' />}
      width={!showModal ? '100%' : modalWidth}
    >
      <Form
        initialValues={initialValues}
        validate={values => handleValidation(values, translate)}
        onSubmit={handleSubmit}
        {...props}
      >
        {({ errors }) => (
          <Grid cols={2} vspace='1'>
            <FormFragment
              tinted={isTintedPreference()}
              translate={translate}
              errors={errors}
            />
            <Grid.Item span={2}>
              <Button
                variant='primary'
                type='submit'
                mt={[0, 4]}
                mx='auto'
                loading={isUpdatingBillingInfo}
                disabled={isUpdatingBillingInfo}
              >
                <T label='text.proceed' />
              </Button>
            </Grid.Item>
          </Grid>
        )}
      </Form>
    </BillingInfoWrapper>
  )
}

BillingInfoModal.propTypes = {
  product: PropTypes.any,
  onCancel: PropTypes.func,
  onProceed: PropTypes.func,
  showModal: PropTypes.bool
}

BillingInfoModal.defaultProps = {
  showModal: true
}

const BillingInfoWrapper = ({
  showModal,
  title,
  onCancel,
  children,
  ...props
}) =>
  showModal ? (
    <ModalDialog
      onDismiss={onCancel}
      title={title}
      {...props}
    >
      {children}
    </ModalDialog>
  ) : (
    <Box {...props}>
      <Typography variant='titleLarge'>{title}</Typography>
      {children}
    </Box>
  )
BillingInfoWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  showModal: PropTypes.bool,
  onCancel: PropTypes.func
}

export default BillingInfoModal
