import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography, themed } from '@etvas/etvaskit'

const ConfirmationText = ({ children, ...props }) => (
  <StyledTypography {...props}>{children}</StyledTypography>
)

const StyledTypography = styled(Typography)`
  color: ${themed('colors.error')};
`

ConfirmationText.propTypes = {
  children: PropTypes.any
}

export default ConfirmationText
