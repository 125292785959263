import css from '@styled-system/css'
import styled from 'styled-components'

import { Box, Flex, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

export const DemoModeNotification = () => (
  <NotificationWrapper>
    <Notification>
      <Flex justifyContent='flex-start' alignItems='center'>
        <img alt='exclamation mark' src='/icons/icon-info.svg' />
        <Typography variant='labelSmall' ml={3}>
          <T label='text.demoModeNotification' />
        </Typography>
      </Flex>
    </Notification>
  </NotificationWrapper>
)

const NotificationWrapper = styled(Box)(
  css({
    zIndex: 8,
    bottom: 7,
    right: 6,
    left: [16, 'unset'],
    position: 'fixed'
  })
)

const Notification = styled(Flex)(
  css({
    paddingX: 4,
    paddingY: 3,
    borderRadius: 3,
    backgroundColor: 'white',
    boxShadow:
      '0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3)'
  })
)
