import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import ExternalWrapper from '../../external'
import { usePostHeight } from '../hooks/usePostHeight'

const EmbeddedProductUse = ({ name }) => {
  const { productId } = useParams()
  usePostHeight(`${name}-${productId}`)
  return (
    <ExternalWrapper productId={productId} noProducts='link' hideBackButton />
  )
}

EmbeddedProductUse.propTypes = {
  name: PropTypes.string
}

export default EmbeddedProductUse
