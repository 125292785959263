import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { extractErrorsFromException } from '@shared/funcs'
import purchaseStatus from '@shared/models/purchaseStatus'
import { PURCHASED_PRODUCT_FRAGMENT } from '@shared/services/graphql/fragments'

import { CANCEL_PURCHASE } from './graphql/mutations'

export const useCancelPurchase = () => {
  const [cancelPurchase, { loading: isCancellingPurchase }] =
    useMutation(CANCEL_PURCHASE)

  const requestCancelPurchase = useCallback(
    async input => {
      try {
        const response = await cancelPurchase({
          variables: { input },
          update(cache, { data: { cancelPurchase } }) {
            cache.modify({
              fields: {
                listPurchasedProducts(
                  existingPurchasedProducts,
                  { readField }
                ) {
                  const purchasedProductRef = cache.readFragment({
                    id: `PurchasedProduct:${cancelPurchase.id}`,
                    fragment: PURCHASED_PRODUCT_FRAGMENT
                  })
                  if (!purchasedProductRef) {
                    return existingPurchasedProducts
                  }
                  const purStatus = readField(
                    'purchaseStatus',
                    purchasedProductRef
                  )
                  const isDemo = readField('isDemo', purchasedProductRef)
                  const isSuspended =
                    purStatus === purchaseStatus.Suspended ||
                    purStatus === purchaseStatus.Resuming

                  if (isDemo || isSuspended) {
                    return existingPurchasedProducts.filter(
                      purchasedProductRef =>
                        cancelPurchase.id !==
                        readField('id', purchasedProductRef)
                    )
                  }

                  return existingPurchasedProducts
                }
              }
            })
          }
        })
        return response
      } catch (error) {
        const mappedErrors = extractErrorsFromException(error)
        console.error('* ERROR  while cancelling a product purchase', error)
        return mappedErrors
      }
    },
    [cancelPurchase]
  )

  return { requestCancelPurchase, isCancellingPurchase }
}
