import { useQuery } from '@apollo/client'

import { GET_TERMS_PAGE } from '../graphql/queries'

export const useTermsPage = (id, parser) => {
  const { data, loading, error } = useQuery(GET_TERMS_PAGE, {
    variables: {
      input: {
        id,
        parser
      }
    },
    context: {
      unauthenticated: true
    }
  })

  if (error) {
    console.error(`Unable to get the terms page ${id} with parser ${parser}`)
  }

  return {
    page: data?.getTermsPage,
    isLoading: loading,
    error
  }
}
