import { useEffect, useMemo, useState } from 'react'

import { PaymentMethodsDropdown } from '@shared/components'
import { PAYMENT_METHODS } from '@shared/constants'
import { useFeatureFlags } from '@shared/hooks/useFeatureFlags'
import { usePaymentMethods } from '@shared/services/usePaymentMethods'

import CardEditor from './CardEditor'
import PaymentMethodSkeleton from './PaymentMethodSkeleton'
import SepaEditor from './SepaEditor'

const PaymentMethodEditor = () => {
  const { allowedPaymentMethods } = useFeatureFlags()
  const paymentMethodsResult = usePaymentMethods()
  const [showAddForm, setShowAddForm] = useState(false)
  const { paymentMethods: allPaymentMethods, isPaymentMethodsFetching } =
    paymentMethodsResult
  const [selectedMethod, setSelectedMethod] = useState(PAYMENT_METHODS.card)

  const paymentMethods = useMemo(
    () =>
      allPaymentMethods.filter(method =>
        allowedPaymentMethods.includes(method.type)
      ),
    [allPaymentMethods, allowedPaymentMethods]
  )

  const paymentMethod = useMemo(
    () => (paymentMethods.length > 0 ? paymentMethods[0] : null),
    [paymentMethods]
  )

  useEffect(() => {
    if (!showAddForm && paymentMethods.length > 0) {
      setSelectedMethod(paymentMethods[0].type)
    }
  }, [paymentMethods, showAddForm])

  if (isPaymentMethodsFetching) {
    return <PaymentMethodSkeleton />
  }

  const renderPaymentMethod = method => {
    switch (method) {
      case PAYMENT_METHODS.card:
        return (
          <CardEditor
            showAddForm={showAddForm}
            paymentMethod={paymentMethod}
            setShowAddForm={setShowAddForm}
            paymentMethodsResult={paymentMethodsResult}
          />
        )
      case PAYMENT_METHODS.sepa:
        return (
          <SepaEditor
            showAddForm={showAddForm}
            paymentMethod={paymentMethod}
            setShowAddForm={setShowAddForm}
            paymentMethodsResult={paymentMethodsResult}
          />
        )
      default:
        console.error(' * Unknown payment type: ', method)
        return null
    }
  }

  return (
    <>
      <PaymentMethodsDropdown
        disabled={!showAddForm}
        value={selectedMethod}
        onChange={setSelectedMethod}
        mb={3}
        required
      />

      {renderPaymentMethod(selectedMethod)}
    </>
  )
}

export default PaymentMethodEditor
