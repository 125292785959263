import { useCallback, useContext, useMemo, useState } from 'react'

import { AuthContext } from '@services/AuthProvider'
import css from '@styled-system/css'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import {
  Box,
  BrandingContext,
  Flex,
  Icon,
  Touchable,
  Typography
} from '@etvas/etvaskit'

import { AccountAvatar } from '@shared/components'
import { sidebar } from '@shared/constants'
import { hexToRgb, rgbToHsl } from '@shared/funcs'
import { useFeatureFlags } from '@shared/hooks'
import { T } from '@shared/i18n'

import LogoutModal from './LogoutModal'
import { SidebarFooter } from './SidebarFooter'
import { SidebarSkeleton } from './SidebarSkeleton'

const Sidebar = () => {
  const { authenticated, currentUser, isLoadingUserProfile } =
    useContext(AuthContext)
  const { sidebar: sidebarFlags } = useFeatureFlags()

  const [isVisible, setIsVisible] = useState(false)
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false)
  const toggleVisibility = useCallback(
    () => setIsVisible(!isVisible),
    [isVisible, setIsVisible]
  )

  const branding = useContext(BrandingContext)
  const color = branding.cssVars.brandColor
  const bgColor = branding.cssVars.brandColorDark

  const rgbColor = useMemo(() => hexToRgb(color.substr(1)), [color])
  const hsl = useMemo(
    () => rgbToHsl(rgbColor[0], rgbColor[1], rgbColor[2]),
    [rgbColor]
  )

  if (!authenticated) {
    return <SidebarSkeleton />
  }

  const hslString = `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2] + 10}%)`
  const invertTextColor = hsl[2] < 60 ? 'baseWhite' : 'baseBlack'

  const displayLogoutConfirmation = () => setShowLogoutConfirmation(true)
  const closeLogoutConfirmation = () => setShowLogoutConfirmation(false)

  const sidebarItems = sidebar.filter(
    ({ id }) => !sidebarFlags.hide.includes(id)
  )

  return (
    <>
      <Flex
        flexDirection='column'
        alignItems='stretch'
        justifyContent='flex-start'
        height='100%'
        px={4}
        pt={7}
      >
        <Box pb={7} px={1}>
          <AccountAvatar
            user={currentUser}
            color={invertTextColor}
            isLoadingUserProfile={isLoadingUserProfile}
          />
        </Box>
        <Box>
          {sidebarItems.map(({ id, route, label, icon, ...props }) => (
            <MenuLink
              exact={true}
              key={id}
              to={route}
              hsl={hslString}
              onClick={toggleVisibility}
              $bgColor={bgColor}
              {...props}
            >
              <Icon name={icon} color={invertTextColor} size='medium' />

              <Typography variant='base14Bold' color={invertTextColor} ml={4}>
                <T label={label} />
              </Typography>
            </MenuLink>
          ))}
        </Box>
        <MenuItem
          as='a'
          onClick={displayLogoutConfirmation}
          hsl={hslString}
          $bgColor={bgColor}
        >
          <Icon name='logout' color={invertTextColor} size='medium' />

          <Typography variant='base14Bold' color={invertTextColor} ml={4}>
            <T label='label.logout' />
          </Typography>
        </MenuItem>
      </Flex>

      <SidebarFooter id='sidebar-footer' bg={bgColor} />
      {showLogoutConfirmation && (
        <LogoutModal closeModal={closeLogoutConfirmation} />
      )}
    </>
  )
}
const MenuLink = styled(NavLink)`
  ${({ hsl, $bgColor }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      px: 3,
      height: 40,
      borderRadius: 3,
      opacity: 0.3,
      marginBottom: 2,
      background: $bgColor,
      '&.active': {
        opacity: 1,
        backgroundColor: hsl
      },
      '&:hover': {
        backgroundColor: hsl,
        opacity: 0.9
      }
    })}
`
const MenuItem = styled(Touchable)`
  ${({ hsl, $bgColor }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      px: 3,
      height: 40,
      borderRadius: 3,
      opacity: 0.3,
      background: $bgColor,
      '&:hover': {
        backgroundColor: hsl,
        opacity: 0.9
      }
    })}
`

export default Sidebar
