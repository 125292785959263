import gql from 'graphql-tag'

export const ProfileFragment = gql`
  fragment ProfileData on Profile {
    id
    firstName
    lastName
    locale
    phoneNumber
    email
  }
`
