import { useContext, useState } from 'react'

import css from '@styled-system/css'
import styled from 'styled-components'

import { Box, Flex, Icon, Link, Touchable, Typography } from '@etvas/etvaskit'

import { CookieBannerTeleport } from '@shared/constants'
import { storage } from '@shared/funcs'
import { I18nContext, T } from '@shared/i18n'

const CookieBanner = () => {
  const { translate } = useContext(I18nContext)

  const [isShowingCookieBanner, setShowingCookieBanner] = useState(
    !storage.hideCookieBanner
  )

  const hideCookieBanner = () => {
    storage.hideCookieBanner = true
    setShowingCookieBanner(false)
  }

  if (!isShowingCookieBanner) {
    return (
      <BannerWrapper>
        <CookieBannerTeleport.Target />
      </BannerWrapper>
    )
  }

  return (
    <BannerWrapper>
      <CookieBannerTeleport.Target />
      <StyledBanner borderRadius={[0, 0, '8px']} mb={[0, 0, 8]} mr={[0, 0, 8]}>
        <Flex flexDirection='column' justifyContent='center' flex='1'>
          <Typography variant='labelSmall'>
            <T label='label.byUsingThis' />
            <Typography variant='labelSmallBold' display='inline'>
              <Link
                href={translate('link.cookies')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <T label='label.cookiePolicy' />
              </Link>
            </Typography>
          </Typography>
        </Flex>
        <Flex flexDirection='column' justifyContent='center' mx={1}>
          <IconBtn onClick={hideCookieBanner}>
            <Icon name='plus' />
          </IconBtn>
        </Flex>
      </StyledBanner>
    </BannerWrapper>
  )
}
const IconBtn = styled(Touchable)(
  css({
    backgroundColor: '#cad2d9',
    color: 'white',
    borderRadius: '50%',
    transform: 'rotate(45deg)',
    width: 20,
    height: 20,
    right: 1,
    top: 1,
    fontWeight: 'bolder',
    fontSize: '14px',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#6f8497'
    }
  })
)

const BannerWrapper = styled(Box)`
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9;
  width: 100%;
  @media (min-width: 768px) {
    width: 448px;
    left: auto;
  }
`

const StyledBanner = styled(Flex)(
  css({
    display: 'flex',
    backgroundColor: 'white',
    px: 4,
    py: 4,
    boxShadow:
      '0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3)'
  })
)

export default CookieBanner
