import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { AuthContext } from '@services/AuthProvider'

import { GET_PRODUCT_DETAILS } from '@shared/services/graphql/queries'

import { GET_PARTIAL_PRODUCT_DETAILS } from './graphql/queries'

export const useEmbeddedData = productId => {
  const { authenticated } = useContext(AuthContext)

  const productDetailsQuery = authenticated
    ? GET_PRODUCT_DETAILS
    : GET_PARTIAL_PRODUCT_DETAILS

  const { loading, data, error } = useQuery(productDetailsQuery, {
    fetchPolicy: 'no-cache',
    variables: { input: { id: productId } },
    context: { unauthenticated: !authenticated },
    onError: error => console.error(' * Could not get product details', error)
  })

  return { loading, product: data?.getProductDetails, error }
}
