import PropTypes from 'prop-types'

import { Button, Flex, Grid, Typography } from '@etvas/etvaskit'

import { Bullets, CardElementWrapper } from '@shared/components'
import { PAYMENT_METHODS } from '@shared/constants'
import { T } from '@shared/i18n'

const PaymentMethodView = ({ handleClick, paymentMethod }) => {
  const { type } = paymentMethod
  const renderPaymentMethod = type => {
    switch (type) {
      case PAYMENT_METHODS.card:
        return (
          <CardMethodView
            paymentMethod={paymentMethod}
            handleClick={handleClick}
            type={type}
          />
        )
      case PAYMENT_METHODS.sepa:
        return (
          <SepaMethodView
            paymentMethod={paymentMethod}
            handleClick={handleClick}
            type={type}
          />
        )
      default:
        console.error(' * Unknown payment type: ', type)
        return null
    }
  }

  return renderPaymentMethod(type)
}

PaymentMethodView.propTypes = {
  handleClick: PropTypes.func,
  paymentMethod: PropTypes.object
}

const SepaMethodView = ({ paymentMethod, handleClick }) => (
  <Grid vspace='0' cols={2}>
    <Grid.Item span={2}>
      <Typography variant='inputLabel'>
        <T label='label.iban' />
      </Typography>
      <CardElementWrapper mt={1} mb={4}>
        <Flex justifyContent='space-between' alignItems='center'>
          <Typography
            variant='labelSmall'
            color={Bullets.defaultColor}
            letterSpacing={3}
          >
            <Bullets />
            <Bullets />
            <Bullets />
            {paymentMethod.lastFour}
          </Typography>
        </Flex>
      </CardElementWrapper>
    </Grid.Item>

    <Grid.Item span={2}>
      <Flex justifyContent='flex-end' py={4}>
        <Button
          variant='link'
          width={{ _: '100%', md: 'auto' }}
          ml={1}
          onClick={handleClick}
          type='button'
        >
          <T label='label.replace' />
        </Button>
      </Flex>
    </Grid.Item>
  </Grid>
)

SepaMethodView.propTypes = {
  handleClick: PropTypes.func,
  paymentMethod: PropTypes.shape({
    lastFour: PropTypes.string
  })
}

const CardMethodView = ({ handleClick, paymentMethod }) => (
  <Grid vspace='0' cols={2}>
    <Grid.Item span={2}>
      <Typography variant='inputLabel' width='fit-content'>
        <T label='label.cardNumber' />
      </Typography>
      <CardElementWrapper mt={1} mb={4}>
        <Flex justifyContent='space-between' alignItems='center'>
          <Typography
            variant='labelSmall'
            color={Bullets.defaultColor}
            letterSpacing={3}
          >
            <Bullets />
            <Bullets />
            <Bullets />
            {paymentMethod.lastFour}
          </Typography>
        </Flex>
      </CardElementWrapper>
    </Grid.Item>
    <Grid.Item>
      <Typography variant='inputLabel' width='fit-content'>
        <T label='label.cardExpire' />
      </Typography>
      <CardElementWrapper mt={1} mb={4}>
        <Typography variant='labelSmall' color={Bullets.defaultColor}>
          {`${paymentMethod.expirationMonth}/${
            paymentMethod.expirationYear - 2000
          }`}
        </Typography>
      </CardElementWrapper>
    </Grid.Item>
    <Grid.Item>
      <Typography variant='inputLabel' width='fit-content'>
        <T label='label.cardCVC' />
      </Typography>
      <CardElementWrapper mt={1} mb={4}>
        <Bullets num={3} />
      </CardElementWrapper>
    </Grid.Item>
    <Grid.Item span={2}>
      <Flex justifyContent='flex-end' py={4}>
        <Button
          variant='link'
          width={{ _: '100%', md: 'auto' }}
          ml={1}
          onClick={handleClick}
          type='button'
        >
          <T label='label.replace' />
        </Button>
      </Flex>
    </Grid.Item>
  </Grid>
)

CardMethodView.propTypes = {
  handleClick: PropTypes.func,
  paymentMethod: PropTypes.shape({
    lastFour: PropTypes.string,
    expirationMonth: PropTypes.number,
    expirationYear: PropTypes.number
  })
}

export default PaymentMethodView
