export const areValuesChanged = (initialValues, currentValues) =>
  Object.keys(initialValues).some(
    key => currentValues[key] !== initialValues[key]
  )

export const b2i = (billingInfo, { defaultCountryCode = '' } = {}) => ({
  fullName: billingInfo?.name ?? '',
  address: billingInfo?.address?.line1 ?? '',
  zip: billingInfo?.address?.postalCode ?? '',
  country: billingInfo?.address?.countryCode ?? defaultCountryCode,
  city: billingInfo?.address?.city ?? '',
  vat: billingInfo?.vat ?? ''
})

export const i2b = values => ({
  name: values.fullName,
  vat: values.vat,
  address: {
    line1: values.address,
    city: values.city,
    postalCode: values.zip,
    countryCode: values.country
  }
})

export const handleValidation = (values, translationService) => {
  const fields = ['fullName', 'address', 'zip', 'city', 'country']
  return fields.reduce((errors, fieldName) => {
    const v = values[fieldName] ? values[fieldName].trim() : ''
    if (v.length === 0 || v === '-') {
      errors[fieldName] = translationService('label.required')
    }
    return errors
  }, {})
}
