import { CardElement, IbanElement } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'

import { Box, Typography } from '@etvas/etvaskit'

import { PAYMENT_METHODS } from '@shared/constants'
import { T } from '@shared/i18n'

import CardElementWrapper from './CardElementWrapper'
import PaymentMethodsDropdown from './PaymentMethodsDropdown'

const PaymentMethodInput = ({
  tinted,
  method,
  onChange,
  handlePaymentSelection,
  disabled,
  ...props
}) => {
  const style = {
    base: {
      fontSize: '14px',
      '&::placeholder': {
        color: '#A0AAB3'
      }
    }
  }

  return (
    <Box {...props}>
      <PaymentMethodsDropdown
        value={method}
        disabled={disabled}
        onChange={handlePaymentSelection}
        mb={2}
      />

      <Box>
        <Typography variant='inputLabel' width='fit-content'>
          <T
            label={
              method === PAYMENT_METHODS.card
                ? 'title.paymentInformation'
                : 'label.iban'
            }
          />
        </Typography>
        <CardElementWrapper mt={1} tinted={tinted}>
          {method === PAYMENT_METHODS.card && (
            <CardElement
              onChange={onChange}
              options={{
                style,
                hidePostalCode: true
              }}
            />
          )}

          {method === PAYMENT_METHODS.sepa && (
            <IbanElement
              onChange={onChange}
              options={{
                style,
                supportedCountries: ['SEPA']
              }}
            />
          )}
        </CardElementWrapper>
      </Box>
    </Box>
  )
}

PaymentMethodInput.propTypes = {
  tinted: PropTypes.bool,
  onChange: PropTypes.func,
  method: PropTypes.string,
  disabled: PropTypes.bool,
  handlePaymentSelection: PropTypes.func
}

export default PaymentMethodInput
