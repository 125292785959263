/**
 * Strips specified keys from the query string of a Location object
 * and returns the corresponding URL
 *
 * @param {Location} location A window.location object
 * @param {Array} stripQueryKeys What query keys should be stripped
 * @returns an URL with the stripped query keys, including hash (if any), or the intact Location object if invalid parameters
 */
export const getStripedURL = (location, stripQueryKeys) => {
  if (!location?.full || !location?.pathname) {
    return location
  }

  if (!stripQueryKeys || stripQueryKeys.length === 0) {
    return location
  }

  const url = [location.full, location.pathname]
  const query = Object.keys(location.query)
    .filter(name => !stripQueryKeys.includes(name))
    .map(
      name =>
        `${encodeURIComponent(name)}=${encodeURIComponent(
          location.query[name]
        )}`
    )
    .join('&')
  if (query) {
    url.push(`?${query}`)
  }
  if (location.hash) {
    url.push(location.hash)
  }
  return url.join('')
}
