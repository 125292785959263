import { useMutation, useQuery } from '@apollo/client'
import { apolloClient } from '@services/graphql/clients'

import { ADD_PAYMENT_METHOD } from './graphql/mutations'
import { GET_PAYMENT_METHODS } from './graphql/queries'

export const usePaymentMethods = () => {
  const {
    data: paymentMethodsResult,
    loading: isPaymentMethodsFetching,
    error: errorGettingPaymentMethods
  } = useQuery(GET_PAYMENT_METHODS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    partialRefetch: true
  })

  const [addPaymentMethod, { loading: isAddingPaymentMethod }] =
    useMutation(ADD_PAYMENT_METHOD)

  const requestAddPaymentMethod = async id => {
    try {
      const { data } = await addPaymentMethod({
        variables: { input: { id } }
      })
      const paymentMethod = data?.attachPaymentMethod?.paymentMethod
      if (paymentMethod) {
        _updateCachedPaymentMethods(paymentMethod)
      }
      return { success: true, paymentMethod }
    } catch (err) {
      console.error('* ERROR adding payment method', err)
      return {
        success: false
      }
    }
  }

  const requestAddSetupIntent = async id => {
    try {
      const { data } = await addPaymentMethod({
        variables: { input: { id, setupForLater: true } }
      })
      const setupIntent = data?.attachPaymentMethod?.setupIntent
      const paymentMethod = data?.attachPaymentMethod?.paymentMethod
      if (setupIntent && paymentMethod && setupIntent.status === 'succeeded') {
        _updateCachedPaymentMethods(paymentMethod)
      }
      return { success: true, paymentMethod, setupIntent }
    } catch (err) {
      console.error('* ERROR adding payment method', err)
      return {
        success: false
      }
    }
  }

  const _updateCachedPaymentMethods = paymentMethod => {
    apolloClient.writeQuery({
      query: GET_PAYMENT_METHODS,
      data: {
        listPaymentMethods: [paymentMethod]
      }
    })
  }

  return {
    requestAddPaymentMethod,
    requestAddSetupIntent,
    forceUpdateCache: _updateCachedPaymentMethods,
    isAddingPaymentMethod,
    paymentMethods: paymentMethodsResult?.listPaymentMethods ?? [],
    isPaymentMethodsFetching,
    errorGettingPaymentMethods
  }
}
