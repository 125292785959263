import { BlockSkeleton, Flex, TextSkeleton } from '@etvas/etvaskit'

export const SidebarSkeleton = () => (
  <div>
    <Flex
      alignItems='center'
      justifyContent='stretch'
      mb={[10, null, 15]}
      width='100%'
      p={[4, null, 6]}
    >
      <BlockSkeleton width='48px' height='48px' mr={4} />
      <Flex flexGrow={1}>
        <TextSkeleton height='50%' width='100%' lines={1} />
      </Flex>
    </Flex>

    <TextSkeleton lines={3} justify='equal' p={[4, null, 6]} />
  </div>
)
