import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Flex, themed } from '@etvas/etvaskit'

const CardItem = ({ type, last4, ...props }) => (
  <StyledCard {...props}>
    <b>**** **** **** {last4}</b>
    <span>{type}</span>
  </StyledCard>
)

const StyledCard = styled(Flex)`
  ${themed('CreditCard.item')};
`
CardItem.propTypes = {
  type: PropTypes.string,
  last4: PropTypes.string
}
export default CardItem
