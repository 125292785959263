import PropTypes from 'prop-types'

import { Box, Typography } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { VideoPlayer } from '@shared/components'

const ProductVideo = ({ video, ...props }) => (
  <Box {...props}>
    <Typography variant='labelButton' color='#A0AAB3' mb={1}>
      <T label='text.productVideo' />
    </Typography>
    <VideoPlayer video={video} />
  </Box>
)

ProductVideo.propTypes = {
  video: PropTypes.string
}

export default ProductVideo
