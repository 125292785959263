import { useContext } from 'react'

import { AuthContext } from '@services/AuthProvider'
import PropTypes from 'prop-types'

import { BlockSkeleton } from '@etvas/etvaskit'

import { BillingInfoCard } from '@shared/components/BillingInfo'

import AccountInformation from '../../account/AccountInformation'
import PaymentCoupon from '../../account/PaymentCoupon'
import PaymentHistory from '../../account/PaymentHistory'
import PurchasedProducts from '../../account/PurchasedProducts'
import PaymentMethodEditor from '../../account/components/PaymentMethodEditor'
import { usePostHeight } from '../hooks/usePostHeight'

const EmbeddedSettingsTile = ({ name, tile, ...props }) => {
  usePostHeight(name)
  const { currentUser, isLoadingUserProfile, isFetchingCognitoUser } =
    useContext(AuthContext)

  const tiles = {
    account: { component: AccountInformation, props: { user: currentUser } },
    billing: { component: BillingInfoCard, props: {} },
    payment: { component: PaymentMethodEditor, props: {} },
    coupon: { component: PaymentCoupon, props: {} },
    products: { component: PurchasedProducts, props: {} },
    payments: { component: PaymentHistory, props: {} }
  }

  if (!tiles[tile]) {
    console.error('* Cannot find tile', tile)
    return null
  }

  if (isLoadingUserProfile || isFetchingCognitoUser) {
    return <BlockSkeleton height='2rem' my={2} />
  }

  const RenderedTile = tiles[tile].component
  return <RenderedTile {...tiles[tile].props} {...props} />
}

EmbeddedSettingsTile.propTypes = {
  name: PropTypes.string,
  tile: PropTypes.string.isRequired
}

export default EmbeddedSettingsTile
