import { useCallback, useContext } from 'react'

import { useTracking } from '@services/hooks/useTracking'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Flex, Typography } from '@etvas/etvaskit'

import { I18nContext, T } from '@etvas/i18n'

import isEmbedded from '@shared/funcs/isEmbedded'

const PurchaseSuccess = ({ product, onUse }) => {
  const { translate } = useContext(I18nContext)
  const { track } = useTracking()

  const productUsed = useCallback(() => {
    track({
      name: 'button.click',
      value: 'product-use',
      context: { productId: product?.id }
    })
    if (product.appInNewTab) {
      return true
    }
    onUse()
  }, [track, product, onUse])

  const buttonBehavior = product.appInNewTab
    ? {
        as: 'a',
        href: isEmbedded()
          ? product.appURL
          : `/products/product/${product.id}/use`,
        target: '_blank',
        type: ''
      }
    : { type: 'button' }

  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      p={6}
    >
      <Image src={'/astronaut.png'} />
      <Typography variant='titleLarge' mt={7} fontWeight='bold'>
        <T label='title.productPurchase' />
      </Typography>
      <Typography
        mt={10}
        variant='labelSmall'
        dangerouslySetInnerHTML={{
          __html: translate('text.purchaseSuccessfull', [product.name])
        }}
      />
      <Button
        mt={[4, 10]}
        variant='primary'
        onClick={productUsed}
        alignItems='center'
        justifyContent='center'
        data-testId='open-product'
        {...buttonBehavior}
      >
        <T label='label.open' />
      </Button>
    </Flex>
  )
}
const Image = styled.div`
  width: 128px;
  height: 128px;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.src});
`

PurchaseSuccess.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    appInNewTab: PropTypes.bool,
    appURL: PropTypes.string
  }),
  onUse: PropTypes.func
}

export default PurchaseSuccess
