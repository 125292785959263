import { Credentials } from '@aws-amplify/core'
import { awsConfig } from '@config/aws.config'
import { Amplify, Auth } from 'aws-amplify'

import { apolloClient } from 'app/services/graphql/clients'

import { rumSession, userSessionTrack } from '@shared/funcs'

const getCurrentUser = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  if (!cognitoUser) {
    return null
  }

  const {
    signInUserSession: {
      idToken: { payload }
    }
  } = cognitoUser
  return {
    id: payload.userId,
    sub: payload.sub,
    firstName: payload.given_name || payload.name,
    lastName: payload.family_name,
    email: payload.email,
    phoneNumber: payload.phone_number,
    userRoles: JSON.parse(payload.userRoles)
  }
}

const _setAuthFlowType = flowType =>
  Amplify.configure({
    ...awsConfig,
    Auth: {
      ...awsConfig.Auth,
      authenticationFlowType: flowType
    }
  })

const signIn = async (email, password, clientMetadata) => {
  try {
    await apolloClient.clearStore()
  } catch (err) {
    console.error('* Error caught while clearing GraphQL cache', err)
  }

  try {
    _setAuthFlowType('USER_SRP_AUTH')
    return await Auth.signIn(email, password, clientMetadata)
  } catch (err) {
    _setAuthFlowType('USER_PASSWORD_AUTH')
    return Auth.signIn(email, password, clientMetadata)
  }
}

const _clearApolloStore = async () => {
  try {
    await apolloClient.clearStore()
  } catch (err) {
    console.error('* Error caught while clearing GraphQL cache', err)
  }
}

const _clearSession = () => {
  userSessionTrack.clear()
}

const signOut = async () => {
  await Auth.signOut()
  await _clearApolloStore()
  _clearSession()
  rumSession.removeUser()
}

const softSignOut = async () => {
  await _clearApolloStore()
  await Credentials.clear()
  _clearSession()
  rumSession.removeUser()
}

const userApi = {
  getCurrentUser,
  getCurrentSession: async () => Auth.currentSession(),
  signOut: signOut,
  softSignOut: softSignOut,
  signIn: signIn,
  completeNewPassword: async (user, password, clientMetadata) =>
    Auth.completeNewPassword(user, password, undefined, clientMetadata),
  sendVerificationCode: async (email, clientMetadata) =>
    Auth.forgotPassword(email, clientMetadata),
  forgotPasswordSubmit: async (email, code, newPassword, clientMetadata) =>
    Auth.forgotPasswordSubmit(email, code, newPassword, clientMetadata),
  confirmSignIn: async (user, code, mfaType) =>
    Auth.confirmSignIn(user, code, mfaType),
  confirmSignUp: async (user, code, clientMetadata) =>
    Auth.confirmSignUp(user, code, { clientMetadata }),
  signUp: async userData => Auth.signUp(userData),
  resendEmailCode: async (email, clientMetadata) =>
    Auth.resendSignUp(email, clientMetadata)
}

export default userApi
