import { SessionStorage } from '@shared/store'

const _key = '_etv_sso_cache'

const _storage = {
  read: () => {
    const data = SessionStorage.getItem(_key)
    return data ? JSON.parse(data) : null
  },
  write: value =>
    SessionStorage.setItem(
      _key,
      value !== undefined ? JSON.stringify(value) : ''
    ),
  clear: () => SessionStorage.removeItem(_key)
}

const thirtyMinutes = 30 * 60 * 1000

export const getCachedTokens = ssoToken => {
  try {
    const content = _storage.read()
    if (
      !content ||
      !content.a ||
      !content.r ||
      !content.i ||
      !content.e ||
      !content.s
    ) {
      return null
    }
    if (content.s !== ssoToken || content.e < Date.now()) {
      _storage.clear()
      return null
    }

    return {
      accessToken: content.a,
      refreshToken: content.r,
      idToken: content.i
    }
  } catch (err) {
    console.warn('* Could not get cached SSO token', err)
    return null
  }
}

export const setCachedTokens = (
  ssoToken,
  { refreshToken, accessToken, idToken }
) => {
  try {
    _storage.write({
      a: accessToken,
      r: refreshToken,
      i: idToken,
      e: Date.now() + thirtyMinutes,
      s: ssoToken
    })
    return true
  } catch (err) {
    console.warn('* Could not cache SSO token', err)
    return false
  }
}
