import { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Typography } from '@etvas/etvaskit'

import getQueryParam from '@shared/funcs/getQueryParam'
import { T, setLanguage } from '@shared/i18n'

import { AvailableProducts } from '../../discover/components'
import { useEmbeddedProducts } from '../../embedded/services/useEmbeddedProducts'
import { NavBar, PageFooter } from '../components'
import { useAllowedRedirect } from '../hooks/useAllowedRedirect'

export const DiscoverLanding = () => {
  const { isRedirectAllowed } = useAllowedRedirect()
  const { products, isLoadingProducts } = useEmbeddedProducts()
  const { lang } = useParams()

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  const redirectURL = getQueryParam('redirectPurchaseURL')
  if (redirectURL && !isRedirectAllowed(redirectURL)) {
    return (
      <Box px={2} py={10}>
        <Typography
          variant='title24Light'
          color='statusError'
          textAlign='center'
        >
          <T label='error.redirectURLNotAllowed' />
        </Typography>
      </Box>
    )
  }

  return (
    <Box bg='divider'>
      <NavBar scrollOffset={0} />
      <StyledContainer>
        <AvailableProducts
          isFetching={isLoadingProducts}
          products={products}
          seeMoreURLPrefix={`/l/${lang ?? 'en'}`}
          showSeeMore
        />
      </StyledContainer>
      <PageFooter />
    </Box>
  )
}

const StyledContainer = styled(Box)`
  max-width: 1200px;
  margin: 0 32px;
  padding-top: 32px;
  @media (min-width: 1199px) {
    margin: 0 auto;
  }
`
