import { useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

export const useOnRouteChange = action => {
  const location = useLocation()
  const prevLocation = useRef()

  useEffect(() => {
    if (location?.pathname !== prevLocation.current?.pathname) {
      action && action(location)
    }
    prevLocation.current = location
  }, [location, action])
}
