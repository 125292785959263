import { _border } from '../_utils'
import { inputFontSize, inputHeight, inputPadding, inputRadius } from '../sizes'
import {
  creditCardItemBorder,
  creditCardItemCodeColor,
  creditCardItemTypeColor
} from '../theme-colors'

const creditCardStyles = {
  item: {
    border: _border(creditCardItemBorder),
    height: inputHeight,
    padding: inputPadding,
    borderRadius: inputRadius,
    fontSize: inputFontSize,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '> span': {
      textTransform: 'uppercase',
      color: creditCardItemTypeColor,
      fontWeight: 'bold'
    },
    '> b': {
      fontFamily: '"Courier New", Courier, monospace',
      color: creditCardItemCodeColor
    }
  }
}

export default creditCardStyles
