import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { AuthContext } from '@services/AuthProvider'
import { OrganizationContext } from '@services/OrganizationProvider'
import PropTypes from 'prop-types'

import { usePaymentCoupon } from '@shared/services/usePaymentCoupon'

export const CouponContext = createContext()

const CouponProvider = ({ noStart, children }) => {
  const { authenticated } = useContext(AuthContext)
  const { slug } = useContext(OrganizationContext)
  const isCouponAvailable = slug === process.env.REACT_APP_COUPON
  const [elapsed, setElapsed] = useState(false)
  const [showBanner, setShowBanner] = useState(true)

  const {
    getCanAttachPaymentCoupon,
    canAttachPaymentCoupon,
    getPaymentCoupon,
    paymentCoupon,
    isPaymentCouponFetching,
    isPaymentCouponUpdating,
    requestAttachPaymentCoupon,
    requestDetachPaymentCoupon,
    couponCode,
    discountValue
  } = usePaymentCoupon()

  useEffect(() => {
    if (authenticated) {
      getPaymentCoupon()
      isCouponAvailable && getCanAttachPaymentCoupon()
    }
  }, [
    getCanAttachPaymentCoupon,
    getPaymentCoupon,
    authenticated,
    isCouponAvailable
  ])

  useEffect(() => {
    if (!noStart && authenticated && isCouponAvailable) {
      const timer = setTimeout(() => {
        setElapsed(true)
      }, 15000)

      return () => clearTimeout(timer)
    }
  }, [authenticated, isCouponAvailable, noStart])

  const isCouponTaken = useMemo(
    () => couponCode === paymentCoupon?.id,
    [couponCode, paymentCoupon]
  )

  return (
    <CouponContext.Provider
      value={{
        discountValue,
        requestAttachPaymentCoupon,
        requestDetachPaymentCoupon,
        isPaymentCouponUpdating,
        isPaymentCouponFetching,
        couponCode,
        paymentCoupon,
        elapsed,
        isCouponAvailable,
        canAttachPaymentCoupon,
        isCouponTaken,
        showBanner,
        setShowBanner,
        getPaymentCoupon
      }}
    >
      {children}
    </CouponContext.Provider>
  )
}

CouponProvider.propTypes = {
  children: PropTypes.node,
  noStart: PropTypes.bool
}

CouponProvider.defaultProps = {
  noStart: false
}

export default CouponProvider
