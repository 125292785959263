import PropTypes from 'prop-types'

import { Box, Button, Flex, Typography } from '@etvas/etvaskit'

import { getCountryLabel } from '@shared/constants/countryCodes'
import { T } from '@shared/i18n'
import { useBillingInfo } from '@shared/services/useBillingInfo'

const creditorId = 'BE39ZZZCITD000000037'
const creditorName = 'Stripe Technology Europe Ltd.'
const creditorAddress =
  'C/O A&L Goodbody IFSC North Wall Quay Dublin Dublin D01 H104 IE'

const SepaMandate = ({ confirm, isConfirming, displayDetails }) => {
  const { billingInfo } = useBillingInfo()

  const address = billingInfo?.address
  const customerAddress = `${address?.line1}, ${address?.city}, ${
    address?.postalCode
  }, ${getCountryLabel(address?.countryCode)}`

  const orderDetails = [
    {
      label: 'label.creditorId',
      value: creditorId
    },
    {
      label: 'label.name',
      value: creditorName
    },
    {
      label: 'label.address',
      value: creditorAddress
    }
  ]

  const yourDetails = [
    {
      label: 'label.accountHolderName',
      value: billingInfo?.name
    },
    {
      label: 'label.customer',
      value: billingInfo?.name
    },
    {
      label: 'label.address',
      value: customerAddress
    }
  ]

  return (
    <>
      <Typography variant='title32Bold' mb={5}>
        <T label='title.sepaMandate' />
      </Typography>

      {displayDetails && (
        <>
          <Box mb={4}>
            <Typography variant='base14Bold' color='baseGray' mb={2}>
              <T label='label.orderDetails' />
            </Typography>
            {orderDetails.map(detail => (
              <Box key={detail.label} mb={2}>
                <Typography variant='base14Bold' display='inline'>
                  <T label={detail.label} />
                </Typography>
                <Typography variant='base14Light' display='inline'>
                  {' : '}
                  {detail.value}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box>
            <Typography variant='base14Bold' color='baseGray' mb={2}>
              <T label='label.yourDetails' />
            </Typography>
            {yourDetails.map(detail => (
              <Box key={detail.label} mb={2}>
                <Typography variant='base14Bold' display='inline'>
                  <T label={detail.label} />
                </Typography>
                <Typography variant='base14Light' display='inline'>
                  {' : '}
                  {detail.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}

      <Flex flexDirection={displayDetails ? 'column' : 'column-reverse'}>
        <Button
          onClick={confirm}
          disabled={isConfirming}
          loading={isConfirming}
          mx='auto'
          my={4}
        >
          <T label='label.confirm' />
        </Button>

        <Typography variant='base14Light'>
          <T label='text.sepaMandate' />
        </Typography>
      </Flex>
    </>
  )
}

SepaMandate.propTypes = {
  confirm: PropTypes.func,
  isConfirming: PropTypes.bool,
  displayDetails: PropTypes.bool
}

SepaMandate.defaultProps = {
  displayDetails: false
}

export default SepaMandate
