import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { usePollingInvoice } from '@shared/services/usePollingInvoice'

const PollWaitInvoice = ({
  paymentMethod,
  onStopPolling,
  purchaseId,
  ...props
}) => {
  const { pollInvoice } = usePollingInvoice(purchaseId)

  useEffect(() => {
    if (pollInvoice !== null) {
      onStopPolling(pollInvoice, paymentMethod)
    }
  }, [pollInvoice, paymentMethod, onStopPolling])

  return pollInvoice ? null : (
    <Typography variant='textSmall' {...props}>
      <T label='text.waitingForPayGateway' />
    </Typography>
  )
}

PollWaitInvoice.propTypes = {
  purchaseId: PropTypes.string,
  onStopPolling: PropTypes.func,
  paymentMethod: PropTypes.object
}

export default PollWaitInvoice
