import { useEffect, useState } from 'react'

import { returnNumber } from '../funcs'

export const useMatchWidth = breakpoint => {
  const [matches, setMatches] = useState(
    () => window.innerWidth > returnNumber(breakpoint) * 16
  )

  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${breakpoint})`)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }

    if (media.addEventListener) {
      media.addEventListener('change', listener)
      return () => media.removeEventListener('change', listener)
    }
  }, [matches, breakpoint])

  return matches
}
