import Cookies from 'js-cookie'

import currentLocation from '@shared/funcs/currentLocation'

import { KEYS, STORAGE, keyed } from './constants'

export const isCookieStorageAvailable = () => {
  if (navigator?.cookieEnabled) {
    // browser report
    return true
  }
  try {
    // try and create a cookie
    document.cookie = '__cookie_test__=1'
    // find the created cookie
    const ret = document.cookie.indexOf('__cookie_test__=') !== -1
    // remove the cookie
    document.cookie = '__cookie_test__=1; Expires=Thu, 01-Jan-1970 00:00:01 GMT'
    return ret
  } catch (_) {
    return false
  }
}

export const createCookieStorage = mimic => {
  const mk = !mimic || mimic === STORAGE.Cookie ? '' : KEYS[mimic]
  const expires = mimic === STORAGE.Session ? undefined : 365
  const { domain } = currentLocation
  const path = '/'
  return {
    __type: STORAGE.Cookie,
    getItem: key => Cookies.get(`${mk}${key}`),
    setItem: (key, value) =>
      Cookies.set(`${mk}${key}`, value, { domain, expires, path }),
    removeItem: key => Cookies.remove(`${mk}${key}`, { domain, path, expires }),
    hasItem: key => {
      const value = Cookies.get(`${mk}${key}`)
      return value !== undefined && value !== null
    },
    clear: () => {
      Object.keys(Cookies.get())
        .filter(name => (mk ? name.startsWith(mk) : !keyed(name)))
        .forEach(key => {
          Cookies.remove(key, { domain, path, expires })
        })
    }
  }
}
