import { useContext } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'

import { Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

export const FooterTooltip = () => {
  const { organization } = useContext(OrganizationContext)
  if (!organization) {
    return null
  }

  return (
    <Typography variant='textSmall'>
      <T label='text.footer' args={[organization.name]} />
    </Typography>
  )
}
