import { useContext } from 'react'

import { OrganizationContext } from 'app/services/OrganizationProvider'

export const useAllowedRedirect = () => {
  const { organization } = useContext(OrganizationContext)

  const redirectDomains = organization?.redirectDomains ?? []

  const isRedirectAllowed = redirectURL =>
    !!redirectDomains.filter(domain => redirectURL.startsWith(domain)).length

  return { isRedirectAllowed }
}
