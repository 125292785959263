import { NetworkStatus, useQuery } from '@apollo/client'

import { extractErrorsFromException } from '@shared/funcs'
import useEvictOnUnmount from '@shared/hooks/useEvictOnUnmount'

import { GET_PAYMENT_HISTORY } from './graphql/queries'

export const usePaymentHistory = ({ count }) => {
  const {
    loading,
    data,
    error,
    fetchMore,
    variables,
    client,
    refetch,
    networkStatus
  } = useQuery(GET_PAYMENT_HISTORY, {
    variables: {
      input: {
        count
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  useEvictOnUnmount('listInvoices')

  const isInitialLoading = networkStatus === NetworkStatus.loading
  const fetchStatuses = [
    NetworkStatus.fetchMore,
    NetworkStatus.refetch,
    NetworkStatus.setVariables
  ]
  const fetchMoreOrRefetch = fetchStatuses.includes(networkStatus)

  const _refetch = async count => {
    try {
      const result = await refetch({
        input: {
          count
        }
      })
      return result.data.listInvoices
    } catch (err) {
      console.error(' * Error while refetching list invoices')
      return extractErrorsFromException(err)
    }
  }

  return {
    loading,
    nextPageToken: data?.listInvoices?.nextPageToken,
    listInvoices: data?.listInvoices?.items ?? [],
    fetchMoreOrRefetch,
    isInitialLoading,
    error,
    variables,
    client,
    refetch: _refetch,
    fetchMore
  }
}
