import { etvasTheme } from '@etvas/etvaskit'

export let GRID = {
  header: {
    display: 'grid',
    height: '40px',
    width: '100%',
    outline: 'none'
  },
  row: {
    display: 'grid',
    height: '44px',
    width: '100%',
    outline: 'none',
    borderRadius: etvasTheme.radii[3],
    backgroundColor: etvasTheme.colors.white,
    border: `1px solid ${etvasTheme.colors.lighterOutline}`,
    marginBottom: 4
  },
  'row-extended': {
    background: etvasTheme.colors.white,
    marginBottom: 0,
    borderRadius: 0,
    borderTopLeftRadius: etvasTheme.radii[3],
    borderTopRightRadius: etvasTheme.radii[3],
    border: `1px solid ${etvasTheme.colors.lighterOutline}`,
    borderBottom: 'none'
  },
  cell: {
    minWidth: '0%',
    height: '44px'
  }
}

const breakpointQuery = `@media (max-width: ${etvasTheme.breakpoints.md})`

GRID.header[breakpointQuery] = {
  display: 'none'
}

GRID.row[breakpointQuery] = {
  gridTemplateColumns: '1fr',
  borderColor: 'transparent',
  height: 'auto',
  marginBottom: '32px'
}

GRID.cell[breakpointQuery] = {
  minHeight: '47px',
  height: 'auto',
  borderRadius: etvasTheme.radii[3],
  border: `1px solid ${etvasTheme.colors.lighterOutline}`,
  margin: '4px 0',
  padding: '0 8px',
  textAlign: 'center',
  justifyContent: 'center',
  div: {
    whiteSpace: 'normal'
  }
}

export const GRID_DOT = {
  minWidth: etvasTheme.sizes.small,
  height: etvasTheme.sizes.small,
  borderRadius: '50%',
  display: 'block'
}
