import gql from 'graphql-tag'

export const GET_ARTICLE_DETAILS = gql`
  query getBlogItem($input: IDInput!) {
    getBlogItem(input: $input) {
      id
      title
      featuredImageURL
      content {
        html
        plain
      }
    }
  }
`
