import { etvasTheme } from '@etvas/etvaskit'

import { useMatchWidth } from './useMatchWidth'

export const useScreenType = () => {
  const isDesktop = useMatchWidth(etvasTheme.breakpoints.lg)
  const isTablet = useMatchWidth(etvasTheme.breakpoints.md)

  return isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'
}
