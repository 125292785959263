import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { GET_AVAILABLE_PRODUCTS } from '@shared/services/graphql/queries'

import { GET_BLOG_ITEMS } from './graphql/queries'

const queryConfig = {
  fetchPolicy: 'cache-and-network',
  nextFetchPolicy: 'cache-first',
  partialRefetch: true
}

export const useProducts = () => {
  const { data: available, loading: isLoadingAvailable } = useQuery(
    GET_AVAILABLE_PRODUCTS,
    queryConfig
  )

  const { data: blogs, loading: isLoadingBlogs } = useQuery(
    GET_BLOG_ITEMS,
    queryConfig
  )

  const _available = useMemo(() => {
    if (isLoadingAvailable) {
      return []
    }

    return available?.listAvailableProducts ?? []
  }, [available, isLoadingAvailable])

  return {
    availableProducts: _available,
    articles: blogs?.listBlogItems ?? [],
    isLoadingAvailable,
    isLoadingBlogs
  }
}
