import styled from 'styled-components'

import { productPurchase } from '@shared/constants/product'
import { formatPrice } from '@shared/funcs'
import { T } from '@shared/i18n'
import { productShape } from '@shared/models'

export const PriceOption = ({ product }) => {
  const recurringSubscription = productPurchase.isRecurringSubscription(product)

  return (
    <>
      <b>
        {formatPrice(product.price, {
          currency: product.currency,
          zero: <T label='label.free' />
        })}
      </b>
      {product.subscriptionMonths && recurringSubscription ? (
        <span>
          {' / '}
          <WrapTypography>
            <T
              label='label.months'
              args={[product.subscriptionMonths]}
              mark={0}
            />
          </WrapTypography>
        </span>
      ) : null}
    </>
  )
}

const WrapTypography = styled.span`
  white-space: nowrap;
`

PriceOption.propTypes = {
  product: productShape.isRequired
}
