const modalAssets = {
  get: {
    modalTitle: 'title.getProduct',
    aboutTo: 'text.aboutToGetService',
    clickAccept: 'text.byClickingIAccept'
  },
  purchase: {
    modalTitle: 'title.purchaseConfirmation',
    aboutTo: 'text.aboutToPurchaseService',
    clickAccept: 'text.byClickingIAccept'
  },
  activate: {
    modalTitle: 'title.reactivateConfirmation',
    aboutTo: 'text.aboutToReactivateService',
    clickAccept: 'text.byReactivateClickingIAccept'
  }
}

export default modalAssets
