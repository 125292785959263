import PropTypes from 'prop-types'

import { Flex, Typography } from '@etvas/etvaskit'

export const BgTypography = ({
  typographyContent,
  typographyColor,
  ...props
}) => (
  <Flex
    height='64px'
    backgroundColor='baseGrayLightest'
    alignItems='center'
    justifyContent='center'
    borderRadius='2px'
    {...props}
  >
    <Typography variant='base14Light' color={typographyColor}>
      {typographyContent}
    </Typography>
  </Flex>
)

BgTypography.propTypes = {
  typographyContent: PropTypes.node,
  typographyColor: PropTypes.string
}

BgTypography.defaultProps = {
  typographyColor: 'baseMetal'
}
