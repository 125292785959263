import gql from 'graphql-tag'

export const GRANT_EXTERNAL_ACCESS_TOKEN = gql`
  mutation ($input: IDInput!) {
    grantAccessToPurchase(input: $input) {
      jwt
    }
  }
`

export const UPDATE_USER = gql`
  mutation ($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`

export const COLLECT_CONSENT = gql`
  mutation collectConsent {
    collectConsent {
      success
    }
  }
`
