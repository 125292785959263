import { useContext, useEffect, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Flex, Image } from '@etvas/etvaskit'

export const NavBar = ({ scrollOffset, children }) => {
  const [scrollDistance, setScrollDistance] = useState(false)

  const { organization } = useContext(OrganizationContext)

  const logoLeft = organization.landingLeftLogo || organization.logo
  const logoRight = organization.landingRightLogo
  const isSingleLogo = !logoRight

  useEffect(() => {
    const listener = () => {
      setScrollDistance(window.scrollY)
    }
    window.addEventListener('scroll', listener)
    listener()
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  return (
    <>
      <StyledContainer
        height='fit-content'
        isScrolled={!!scrollDistance}
        background={organization.landingHeaderColor}
      >
        <Wrapper
          py={[2, 6, 5, 5, 0]}
          px={[4, 6]}
          flexDirection={['column', 'column', 'row', 'row']}
        >
          {isSingleLogo ? (
            <Box>
              <Logo organization={organization} src={logoLeft} />
            </Box>
          ) : (
            <Flex width='100%' justifyContent='space-between'>
              <Logo organization={organization} src={logoLeft} />
              <Logo organization={organization} src={logoRight} />
            </Flex>
          )}
        </Wrapper>
        {scrollDistance > scrollOffset && children}
      </StyledContainer>
    </>
  )
}

const Logo = ({ src, organization }) => (
  <Image
    src={src}
    alt={organization.name}
    width='auto'
    maxWidth='100%'
    height={['30px', '35px']}
  />
)

Logo.propTypes = {
  src: PropTypes.string,
  organization: PropTypes.object
}

const StyledContainer = styled(Box)`
  ${({ isScrolled, background }) =>
    css({
      top: 0,
      position: 'sticky',
      zIndex: '1',
      transition: 'all 0.25s ease-in-out',
      background: background || 'white',
      boxShadow: isScrolled ? '0px 1px 1px rgba(0, 0, 0, 0.2)' : 'none'
    })}
`

const Wrapper = styled(Flex)`
  margin: 0 auto;
  max-width: 1200px;
  align-items: center;
  justify-content: space-between;
`

NavBar.propTypes = {
  scrollOffset: PropTypes.number,
  children: PropTypes.node
}
