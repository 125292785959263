import { SessionStorage } from '@shared/store'

const PREFIX_EID = 'etv_eid_'
const EXTERNAL_ID_QUERY_NAME = 'eid'

export const persistExternalId = location => {
  const eid = location.query[EXTERNAL_ID_QUERY_NAME]

  if (!eid) {
    return
  }

  SessionStorage.setItem(PREFIX_EID, eid)
}

export const getExternalId = () => SessionStorage.getItem(PREFIX_EID)

export const removeExternalId = () => SessionStorage.removeItem(PREFIX_EID)
