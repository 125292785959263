import gql from 'graphql-tag'

import {
  AVAILABLE_PRODUCT_FRAGMENT,
  PRODUCT_PURCHASE_STATUS_FRAGMENT
} from './fragments'

export const GET_AVAILABLE_PRODUCTS = gql`
  query listAvailableProducts {
    listAvailableProducts {
      ...productPurchaseStatus
      ...availableProduct
    }
  }
  ${PRODUCT_PURCHASE_STATUS_FRAGMENT}
  ${AVAILABLE_PRODUCT_FRAGMENT}
`

export const GET_AVAILABLE_PRODUCTS_SUMMARY = gql`
  query listAvailableProducts {
    listAvailableProducts {
      ...availableProduct
    }
  }
  ${AVAILABLE_PRODUCT_FRAGMENT}
`

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetails($input: IDInput!) {
    getProductDetails(input: $input) {
      purchaseId
      purchaseStatus
      ...availableProduct
    }
  }
  ${AVAILABLE_PRODUCT_FRAGMENT}
`

export const GET_PAYMENT_METHODS = gql`
  query listPaymentMethods {
    listPaymentMethods {
      ... on PaymentMethodCard {
        id
        type
        brand
        lastFour
        expirationMonth
        expirationYear
      }
      ... on PaymentMethodSepa {
        id
        type
        lastFour
        countryCode
        bankCode
        branchCode
      }
    }
  }
`

export const GET_PURCHASE_LATEST_INVOICE = gql`
  query getPurchaseLatestInvoice($input: IDInput!) {
    getPurchaseLatestInvoice(input: $input) {
      id
      paymentStatus
      clientSecret
    }
  }
`

export const GET_PURCHASE_DETAILS = gql`
  query getPurchaseDetails($input: IDInput!) {
    getPurchaseDetails(input: $input) {
      status
      trialEndsAt
    }
  }
`

export const GET_BILLING_INFO = gql`
  query getBillingInfo {
    getBillingInfo {
      _id
      name
      vat
      address {
        line1
        city
        postalCode
        countryCode
      }
    }
  }
`

export const GET_PAYMENT_COUPON = gql`
  query getPaymentCoupon {
    getPaymentCoupon {
      id
      name
      amountOff
      percentOff
      validUntil
    }
  }
`
export const GET_CAN_ATTACH_PAYMENT_COUPON = gql`
  query canAttachPaymentCoupon($input: IDInput!) {
    canAttachPaymentCoupon(input: $input) {
      success
    }
  }
`

export const GET_COGNITO_TOKENS = gql`
  query getCognitoTokens($input: CodeInput!) {
    getCognitoTokens(input: $input) {
      idToken
      refreshToken
      accessToken
    }
  }
`
