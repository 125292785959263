import gql from 'graphql-tag'

export const GET_PRODUCT_TERMS_AND_CONDITIONS = gql`
  query ($input: IDInput!) {
    getProductTermsAndConditions(input: $input) {
      id
      termsAndConditions
    }
  }
`
