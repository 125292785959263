import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { AuthContext } from '@services/AuthProvider'

import { GET_PRODUCT_DETAILS } from '../../productDetails/services/graphql/queries'

export const useEmbeddedProductDetails = id => {
  const { authenticated } = useContext(AuthContext)
  const {
    data,
    loading: isLoadingProduct,
    error
  } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { input: { id } },
    skip: !authenticated
  })

  return {
    product: data?.getProductDetails ?? {},
    isLoadingProduct,
    error
  }
}
