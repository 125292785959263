import gql from 'graphql-tag'

import { AVAILABLE_PRODUCT_FRAGMENT } from '@shared/services/graphql/fragments'

export const GET_PARTIAL_PRODUCT_DETAILS = gql`
  query getProductEmbeddedDetails($input: IDInput!) {
    getProductDetails(input: $input) {
      ...availableProduct
    }
  }
  ${AVAILABLE_PRODUCT_FRAGMENT}
`
