import { useContext } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'

import { getLanguage, setLanguage } from '@shared/i18n'

export const useSwitchToAvailableLanguage = () => {
  const { organization } = useContext(OrganizationContext)

  const language = getLanguage()

  const languages = organization?.languages ?? []
  if (!languages.includes(language)) {
    setLanguage('en')
  }
}
