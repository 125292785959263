import gql from 'graphql-tag'

export const SUBSCRIBE_TO_WAITING_LIST = gql`
  mutation subscribeToWaitingList($input: WaitingListInput!) {
    subscribeToWaitingList(input: $input) {
      success
    }
  }
`

export const UNSUBSCRIBE_FROM_WAITING_LIST = gql`
  mutation unsubscribeFromWaitingList($input: WaitingListInput!) {
    unsubscribeFromWaitingList(input: $input) {
      success
    }
  }
`
