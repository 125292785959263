import styled from 'styled-components'

import { Box } from '@etvas/etvaskit'

export default styled(Box)`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 100px;
  background-image: url('${({ bgUrl }) => bgUrl}');
  @media (min-width: 500px) {
    height: 190px;
  }
`
