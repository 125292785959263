import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { Box, Typography } from '@etvas/etvaskit'

import { Section } from '@shared/components'
import { T } from '@shared/i18n'

import ProductInfo from '../../productDetails/components/ProductInfo'
import { useEmbeddedData } from '../hooks/useEmbeddedData'
import { usePostHeight } from '../hooks/usePostHeight'

const EmbeddedProductDetails = ({ name, options }) => {
  const { productId } = useParams()
  const { product, loading, error } = useEmbeddedData(productId)
  usePostHeight(`${name}-${productId}`)

  if (error) {
    return (
      <Box p={3}>
        <Typography
          variant='base12Light'
          color='statusError'
          textAlign='center'
        >
          <T label='error.unknown' />
        </Typography>
      </Box>
    )
  }

  return (
    <Box p={3}>
      <Section>
        <ProductInfo
          product={product}
          isFetching={loading}
          showAction='embed'
          hideRating={options?.hideRating}
        />
      </Section>
    </Box>
  )
}

EmbeddedProductDetails.propTypes = {
  name: PropTypes.string,
  options: PropTypes.shape({
    hideRating: PropTypes.bool
  })
}

export default EmbeddedProductDetails
