import { useEffect, useMemo, useState } from 'react'

import { useGrantToken } from '@services/hooks/useGrantToken'
import PropTypes from 'prop-types'
import { useLocation, useParams } from 'react-router-dom'

import {
  ActivityIndicator,
  EmbededAppContainer,
  Flex,
  Typography,
  styled
} from '@etvas/etvaskit'

import { EmptyBox, NavBack } from '@shared/components'
import { T } from '@shared/i18n'
import { purchaseStatus } from '@shared/models'

import { ExpiredSessionModal } from './components'
import { UnderMaintenance } from './components/UnderMaintenance'
import { useIntercom } from './services/useInterCom'
import { useProductAppInfo } from './services/useProductAppInfo'

const ONE_HOUR = 60 * 60 * 1000

const ExternalWrapper = ({ hideBackButton, noProducts, spaced }) => {
  useIntercom()

  const [isErrorRequest, setErrorRequest] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)

  const { productId } = useParams()
  const { product, isLoadingProductAppInfo } = useProductAppInfo(productId)
  const [didBypassMaintenance, setDidBypassMaintenance] = useState(false)
  const isUnderMaintenance = !!product?.suspendedAt && !didBypassMaintenance
  const { requestGrantToken, isRequestingGrantToken, token } = useGrantToken()

  const location = useLocation()

  const productURL = useMemo(() => {
    if (token && product?.appURL) {
      const separator = product.appURL.includes('?') ? '&' : '?'
      return `${product.appURL}${separator}token=${encodeURIComponent(token)}`
    }
    return null
  }, [product, token])

  useEffect(() => {
    let timer

    if (!isErrorRequest && productURL) {
      timer = setTimeout(() => {
        setIsSessionExpired(true)
      }, ONE_HOUR)
    }

    return () => clearTimeout(timer)
  }, [productURL, isErrorRequest])

  useEffect(() => {
    if (
      product &&
      !isUnderMaintenance &&
      product.appURL &&
      product.purchaseStatus === purchaseStatus.Purchased &&
      !isRequestingGrantToken &&
      !isLoadingProductAppInfo &&
      !token
    ) {
      try {
        requestGrantToken(product.purchaseId)
      } catch (err) {
        console.error('Error caught while requesting token', err)
        setErrorRequest(true)
      }
    }
  }, [
    isLoadingProductAppInfo,
    isRequestingGrantToken,
    product,
    requestGrantToken,
    token,
    isUnderMaintenance
  ])

  useEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    if (!isErrorRequest && productURL && product?.appInNewTab) {
      window.location.replace(productURL)
    }
  }, [productURL, product, isErrorRequest])

  const isBusy = isLoadingProductAppInfo || isRequestingGrantToken

  const canGoBack = !hideBackButton && !isBusy && !!location.key

  if (!isErrorRequest && isBusy) {
    return (
      <ActivityIndicator
        variant='runningbar'
        colors={{
          background: 'transparent',
          primary: 'brand'
        }}
        top={0}
        position='fixed'
        left={0}
        right={0}
      />
    )
  }

  const isNotPurchased =
    isErrorRequest ||
    !productURL ||
    product?.purchaseStatus !== purchaseStatus.Purchased

  return (
    <>
      {isSessionExpired && <ExpiredSessionModal />}
      <Flex
        flexDirection='column'
        overflowX='hidden'
        px={spaced ? 8 : 0}
        py={spaced ? 8 : [4, 0]}>
        {canGoBack && (
          <NavBack alignSelf='flex-start' mb={6}>
            <T label='label.back' />
          </NavBack>
        )}
        {isUnderMaintenance ? (
          <UnderMaintenance
            product={product}
            bypassMaintenance={() => setDidBypassMaintenance(true)}
          />
        ) : isNotPurchased ? (
          <EmptyBox noProducts={noProducts} mt={6}>
            <Typography variant='title32Light'>
              <T
                label='title.noProductPurchase'
                args={{ name: product?.name || '' }}
              />
            </Typography>
            <Typography variant='base16Light' mt={4} mb={[4, 4, 4, 16]}>
              <T
                label='text.noProductPurchase'
                args={{ name: product?.name || '' }}
              />
            </Typography>
          </EmptyBox>
        ) : (
          <StyledEmbededAppContainer
            src={productURL}
            allowtransparency='true'
            defaultHeight={['calc(100vh - 85px)', 'calc(100vh - 80px)']}
            useForeignModalShadow
          />
        )}
      </Flex>
    </>
  )
}

const StyledEmbededAppContainer = styled(EmbededAppContainer)`
  min-height: 700px;
`

ExternalWrapper.propTypes = {
  spaced: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  noProducts: PropTypes.string
}

ExternalWrapper.defaultProps = {
  spaced: false,
  hideBackButton: false
}

export default ExternalWrapper
