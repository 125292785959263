import { Section } from '@shared/components'
import { T } from '@shared/i18n'

import { NoProductsFound } from '../discover/components'
import { useFilterProducts } from '../discover/useFilterProducts'
import { PurchasedProducts } from './components'
import { usePurchasedProducts } from './services/usePurchasedProducts'

const filterBy = ['name', 'servicePartner.name', 'summary']

const MyProducts = () => {
  const { products, isLoadingProducts } = usePurchasedProducts()

  const [FilterBox, { filteredProducts }] = useFilterProducts({
    products,
    filterBy
  })

  return (
    <>
      <Section
        title={<T label='menu.myProducts' />}
        addon={FilterBox}
        py={0}
        backgroundColor='background'
      />
      {filteredProducts?.length === 0 ? (
        <NoProductsFound mt={5} mb={2} />
      ) : (
        <PurchasedProducts
          isFetching={isLoadingProducts}
          products={filteredProducts || products}
        />
      )}
    </>
  )
}

export default MyProducts
