import PropTypes from 'prop-types'

import { DropdownField, Grid, TextField } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { countryCodes, getCountryLabel } from '@shared/constants/countryCodes'

const FormFragment = ({ showVat, tinted, translate, errors }) => (
  <>
    <Grid.Item span={2}>
      <TextField
        tinted={tinted}
        name='fullName'
        id='fullName'
        label={translate('label.companyName')}
        placeholder={translate('label.companyName')}
        required
      />
    </Grid.Item>
    <Grid.Item>
      <TextField
        tinted={tinted}
        name='address'
        id='address'
        label={translate('label.address')}
        placeholder={translate('label.address')}
        required
      />
    </Grid.Item>
    <Grid.Item>
      <TextField
        tinted={tinted}
        name='zip'
        id='zip'
        label={translate('label.postalCode')}
        placeholder={translate('label.postalCode')}
        required
      />
    </Grid.Item>
    <Grid.Item>
      <TextField
        tinted={tinted}
        name='city'
        id='city'
        label={translate('label.city')}
        placeholder={translate('label.city')}
        required
      />
    </Grid.Item>
    <Grid.Item>
      <DropdownField
        tinted={tinted}
        options={countryCodes}
        itemFilter={(search, code) =>
          getCountryLabel(code)?.toLocaleLowerCase().includes(search)
        }
        id='country'
        name='country'
        label={translate('label.country')}
        placeholder={translate('dropdown.placeholder')}
        searchPlaceholder={translate('dropdown.searchPlaceholder')}
        searchMaxResults={27}
        error={errors.country}
        required
      />
    </Grid.Item>
    {showVat ? (
      <Grid.Item span={2}>
        <TextField
          tinted={tinted}
          name='vat'
          id='vat'
          label={translate('label.vat')}
          placeholder={translate('label.vat')}
          optionalText={<T label='label.optional' />}
        />
      </Grid.Item>
    ) : null}
  </>
)

FormFragment.propTypes = {
  tinted: PropTypes.bool,
  translate: PropTypes.func,
  errors: PropTypes.any,
  showVat: PropTypes.bool
}

FormFragment.defaultProps = {
  tinted: false,
  showVat: false
}

export default FormFragment
