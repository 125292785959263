import PropTypes from 'prop-types'
import { useParams } from 'react-router'

import { Flex, Typography } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { Skeleton } from '@shared/components/BillingInfo/ModalSkel'
import { usePurchaseModals } from '@shared/services/usePurchaseModals'

import { usePostHeight } from '../hooks/usePostHeight'
import { useEmbeddedProductDetails } from '../services'

const EmbeddedPurchaseFlow = ({ name }) => {
  usePostHeight(name)
  const { productId } = useParams()
  const { product, isLoadingProduct, error } =
    useEmbeddedProductDetails(productId)

  const { PurchaseModal, modalProps } = usePurchaseModals({
    product,
    showEmbeddedUse: true,
    initPurchase: true,
    showModals: false
  })

  if (isLoadingProduct) {
    return <Skeleton noShadow width='100%' />
  }

  if (error) {
    return (
      <Flex alignItems='center' flexDirection='column'>
        <Typography variant='titleLarge' p={3}>
          <T label='error.unknown' />
        </Typography>
      </Flex>
    )
  }

  return <PurchaseModal {...modalProps} />
}

EmbeddedPurchaseFlow.propTypes = {
  name: PropTypes.string
}

export default EmbeddedPurchaseFlow
