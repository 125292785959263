import PRIMARY_COLORS from './primary-colors'

export const {
  white,
  danger,
  accent,
  accentFade,
  brand,
  black,
  brandLight,
  shadow,
  darkGray,
  lightGray,
  dark,
  downgrade,
  whiteShadow,
  ash
} = PRIMARY_COLORS

export const headerBg = PRIMARY_COLORS.white
export const burgerFg = PRIMARY_COLORS.white
export const burgerBg = PRIMARY_COLORS.brand

export const pageShadow = PRIMARY_COLORS.mist

export const sidebarBg = PRIMARY_COLORS.brand
export const sidebarFg = PRIMARY_COLORS.white
export const sidebarBgHover = PRIMARY_COLORS.mist
export const sidebarFgHover = PRIMARY_COLORS.brand

export const sectionTitleFg = {
  xs: PRIMARY_COLORS.accent,
  md: PRIMARY_COLORS.dark
}

export const sectionSubtitleFg = PRIMARY_COLORS.dark

export const inputBorder = PRIMARY_COLORS.gray
export const inputFg = PRIMARY_COLORS.dark
export const inputDisabledFg = PRIMARY_COLORS.gray
export const inputBg = PRIMARY_COLORS.white
export const primaryButtonBg = PRIMARY_COLORS.brand
export const primaryButtonFg = PRIMARY_COLORS.white
export const buttonDisabled = PRIMARY_COLORS.light
export const buttonDisabledBorder = PRIMARY_COLORS.accent
export const outlineBorderColor = PRIMARY_COLORS.brand

export const checkBg = PRIMARY_COLORS.accent
export const checkFg = PRIMARY_COLORS.white
export const checkLabelFg = PRIMARY_COLORS.dark

export const sectionSeparatorFg = PRIMARY_COLORS.lighter

export const creditCardItemBorder = PRIMARY_COLORS.brand
export const creditCardItemTypeColor = PRIMARY_COLORS.accent
export const creditCardItemCodeColor = PRIMARY_COLORS.brand

export const productCardBg = PRIMARY_COLORS.lightest
export const productCardPartnerLinkFg = PRIMARY_COLORS.dark
export const productCardLinkFg = PRIMARY_COLORS.black
export const productCardTitleFg = PRIMARY_COLORS.black
export const productCardRatingsFg = PRIMARY_COLORS.accent
export const productPurchaseButtonBg = PRIMARY_COLORS.accent
export const productPurchaseButtonFg = PRIMARY_COLORS.white
export const productUseButtonBg = PRIMARY_COLORS.brand
export const productUseButtonFg = PRIMARY_COLORS.white
export const productTrialFg = PRIMARY_COLORS.gray
export const productTagBg = PRIMARY_COLORS.accent
export const productTagFg = PRIMARY_COLORS.white

export const productPageDescriptionBg = PRIMARY_COLORS.lighter

export const comingSoonTextColor = PRIMARY_COLORS.black
export const comingSoonContainerBg = PRIMARY_COLORS.white
export const blogArticleTouchableTextColor = PRIMARY_COLORS.black

export const modalShadow = PRIMARY_COLORS.overdrive
export const modalTagBg = PRIMARY_COLORS.lightest
export const modalTagFg = PRIMARY_COLORS.dark
export const modalCardBorder = PRIMARY_COLORS.dark
export const modalCardTitleFg = PRIMARY_COLORS.dark
export const modalOverlay = PRIMARY_COLORS.mist

export const reviewBg = PRIMARY_COLORS.lightest
export const reviewDateFg = PRIMARY_COLORS.gray

export const avatarIconBg = PRIMARY_COLORS.lighter
export const avatarIconFg = PRIMARY_COLORS.gray
export const avatarUserFg = PRIMARY_COLORS.white

export const navBackFg = PRIMARY_COLORS.gray

export const authTemplateBorder = PRIMARY_COLORS.brand
export const authTemplateBg = PRIMARY_COLORS.white
export const authTemplateLink = PRIMARY_COLORS.dark

export const articleDetailsTitleFg = PRIMARY_COLORS.highlight
export const articleDetailsBodyFg = PRIMARY_COLORS.dark

export const highlightBg = PRIMARY_COLORS.brandLight

export const noProductsBoxBg = '#F1F3F8'
