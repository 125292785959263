import PropTypes from 'prop-types'

import { Box, Flex, Typography } from '@etvas/etvaskit'

export const Section = ({ children, title, addon, ...props }) => (
  <Box py={4} {...props}>
    {addon ? (
      <Flex
        pb={3}
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent={['flex-start', 'flex-start', 'space-between']}
      >
        <Typography variant='labelLarge' color='inputActive'>
          {title}
        </Typography>
        <Box
          position='relative'
          width={['100%', '100%', 'auto']}
          mt={[3, 3, 0]}
        >
          <Box ml='auto'>{addon}</Box>
        </Box>
      </Flex>
    ) : (
      <Typography mb={3} variant='titleSmall' color='inputActive'>
        {title}
      </Typography>
    )}
    {children}
  </Box>
)

Section.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  addon: PropTypes.node
}
