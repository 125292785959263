import { Button, Flex, Space, Touchable, Typography } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { GridMainComponent } from '@shared/components'
import { productPurchase } from '@shared/constants/product'

import { ProductPurchaseDetails } from '../components'

export const getPurchasedProductsGrid = ({
  isProductCancellable,
  openCancelModal,
  navigateToProductDetails
}) => ({
  name: 'purchased-products',
  columns: [
    {
      name: 'name',
      header: <T label='label.productName' />,
      render: item => (
        <Space ml={4} width='100%'>
          <Touchable onClick={() => navigateToProductDetails(item)}>
            <GridMainComponent
              dotColor={
                productPurchase.isProductCanceled(item) ||
                productPurchase.isProductSuspended(item)
                  ? 'statusError'
                  : 'statusSuccess'
              }
            >
              {item.name}
            </GridMainComponent>
          </Touchable>
        </Space>
      ),
      sort: 'name',
      width: '2fr'
    },
    {
      name: 'details',
      header: <T label='label.details' />,
      render: item => (
        <Typography variant='base14Bold'>
          <ProductPurchaseDetails product={item} />
        </Typography>
      ),
      width: '3fr'
    },
    {
      name: 'action',
      render: item => (
        <Flex mr={[0, 0, 4]}>
          {productPurchase.isProductSuspended(item) && (
            <Button
              onClick={() => navigateToProductDetails(item)}
              mr={3}
              variant='link'
            >
              <T label='label.reactivate' />
            </Button>
          )}
          <Button
            onClick={() => openCancelModal(item)}
            disabled={
              !isProductCancellable(item) ||
              productPurchase.isProductCanceled(item)
            }
            variant='link'
          >
            <T label='label.cancelSubscription' />
          </Button>
        </Flex>
      ),
      align: 'flex-end',
      width: '100px'
    }
  ]
})
