import { Flex, Typography } from '@etvas/etvaskit'

import { T } from '@shared/i18n'

import { noProductsBoxBg } from '../../../../assets/styles/theme-colors'

export const NoProductsFound = props => (
  <Flex
    height='64px'
    justifyContent='center'
    alignItems='center'
    backgroundColor={noProductsBoxBg}
    borderRadius='2px'
    {...props}
  >
    <Typography color='lighterText' variant='textLarge'>
      <T label='label.noProductsFound' />
    </Typography>
  </Flex>
)
