import axios from 'axios'
import { Statsig } from 'statsig-react'

import {
  getOfferId,
  getQueryParam,
  isEmbedded,
  isLandingPage,
  storage,
  userApi,
  userSessionTrack
} from '@shared/funcs'
import { useScreenType } from '@shared/hooks'

import { useSessionStorage } from './useSessionStorage'

const CHANNELS = {
  pub: '/traces',
  auth: '/events'
}

if (getQueryParam('skipTracking') === 'true') {
  storage.skipTracking = true
}

const consoleTrack = {
  post: async (url, payload, options) =>
    new Promise(resolve => {
      setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log('TRACKING', {
          url,
          payload,
          auth: !!options?.headers?.Authorization
        })
        resolve(true)
      }, 250)
    })
}

const onlyTrackToConsole =
  process.env.REACT_APP_TRACK_TO_CONSOLE || storage.skipTracking

const client = onlyTrackToConsole
  ? consoleTrack
  : axios.create({
      baseURL: process.env.REACT_APP_TRACE_BASE_URL
    })

let tk = null
let running = false
let eventsQueue = []
let debouncedEventsQueue = []

const debounceAnnounce = () => {
  clearTimeout(tk)
  if (debouncedEventsQueue.length === 0) {
    return
  }
  tk = setTimeout(() => {
    eventsQueue = [...eventsQueue, ...debouncedEventsQueue]
    debouncedEventsQueue = []
    announce()
  }, 500)
}

const _wait = (ms = 250) =>
  new Promise(resolve => setTimeout(() => resolve(true), ms))

const sendEventsThrough = async ({ path, events }, token) => {
  if (!events.length) {
    return
  }

  const options = {
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: token } : {})
    }
  }

  if (process.env.REACT_APP_NODE_ENV === 'development_e2e') {
    for (const event of events) {
      await client.post(path, [event], options)
    }
    return
  }

  client.post(path, events, options)
}

const areEventsEqual = (e1, e2) => e1.name === e2.name && e1.value === e2.value

const dedupe = events =>
  events.filter(
    (value, index, original) =>
      original.findIndex(item => areEventsEqual(item, value)) === index
  )

const announce = async () => {
  while (running) {
    await _wait()
  }
  try {
    if (eventsQueue.length === 0) {
      console.warn('* Received empty events for tracking, request skipped')
      return false
    }

    const { organizationId } = storage
    if (!organizationId) {
      return false
    }
    let cognitoSession
    try {
      cognitoSession = await userApi.getCurrentSession()
    } catch (err) {
      console.warn('* Cannot get current session.', err)
    }

    const { jwtToken } = cognitoSession?.idToken || {}

    let sessTrack = userSessionTrack.keepAlive()
    if (!sessTrack) {
      console.warn('No active session found, creating a new one.')
      sessTrack = userSessionTrack.create()
      if (!isLandingPage()) {
        eventsQueue.unshift({
          name: 'session.start',
          value: sessTrack.id
        })
      }
    }

    const channels = {
      pub: {
        path: CHANNELS.pub,
        events: []
      },
      auth: {
        path: CHANNELS.auth,
        events: []
      }
    }

    const landingPrefix = userSessionTrack.Prefixes.Landing

    const offerId = getOfferId()

    const _normalize = ({ name, value, context }) => ({
      name:
        isLandingPage() && !name.startsWith(landingPrefix)
          ? `${landingPrefix}${name}`
          : name,
      value,
      context: {
        ...(context || {}),
        sessionId: sessTrack.id,
        bpId: organizationId,
        ...(offerId ? { offerId } : {})
      }
    })

    const deduped = dedupe(eventsQueue)

    const invalidEvent = deduped.find(ev => typeof ev.value !== 'string')
    if (invalidEvent) {
      console.error(` * Invalid tracking value for ${invalidEvent?.name} `, {
        deduped,
        invalidEvent,
        isLandingPage: isLandingPage(),
        auth: !!jwtToken
      })
    }

    deduped.forEach(event => {
      const _channel = jwtToken ? channels.auth : channels.pub
      _channel.events.push(_normalize(event))
    })

    sendEventsThrough(channels.pub)

    if (jwtToken) {
      sendEventsThrough(channels.auth, jwtToken)
    }
    eventsQueue = []
    return true
  } catch (err) {
    console.warn('* Error caught while tracking', err.response?.data || err)
  } finally {
    running = false
  }
}

const processEventsForABTesting = (actions, screenType) => {
  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    return
  }

  actions.forEach(({ name }) => {
    Statsig.logEvent(name)
    Statsig.logEvent(`${name}.${screenType}`)
  })
}

export const useTracking = () => {
  const { getPI, savePI, clearPI } = useSessionStorage()
  const screenType = useScreenType()

  const track = (actions, sendToStatsig = false) => {
    if (isEmbedded()) {
      return
    }
    const _actions = Array.isArray(actions) ? [...actions] : [actions]
    eventsQueue = [...eventsQueue, ..._actions]
    announce()

    if (sendToStatsig) {
      processEventsForABTesting(_actions, screenType)
    }
  }

  const debounceTrack = productId => {
    if (isEmbedded()) {
      return
    }
    const currentSavedProductsIds = getPI()
    if (currentSavedProductsIds.includes(productId)) {
      return null
    }
    savePI([productId])

    const exists = debouncedEventsQueue.find(item => item.value === productId)
    if (!exists) {
      debouncedEventsQueue.push({ name: 'product.discover', value: productId })
      debounceAnnounce()
    }
  }

  const clearDebounceHistory = () => clearPI()

  return { track, debounceTrack, clearDebounceHistory }
}
