import qs from 'querystring'

export const buildDeepLinkURL = (baseURL, params) => {
  const templateReplacedURL = buildTemplatedStringURL(baseURL, params)

  return templateReplacedURL !== baseURL
    ? templateReplacedURL
    : buildQueryStringAppendURL(baseURL, params)
}

const buildTemplatedStringURL = (baseURL, params) =>
  replaceParams(baseURL, params)

const buildQueryStringAppendURL = (baseURL, params) => {
  const query = qs.stringify(params)

  return baseURL.includes('?') ? `${baseURL}&${query}` : `${baseURL}?${query}`
}

const replaceParams = (baseURL, params) =>
  Object.keys(params).reduce(
    (acc, paramKey) => replaceParam(acc, paramKey, params[paramKey]),
    baseURL
  )

const replaceParam = (baseURL, param, value) =>
  tryDepthReplace(tryBasicReplace(baseURL, param, value), param, value)

const tryBasicReplace = (baseURL, param, value) =>
  baseURL.replace(`__${param}__`, value)

const tryDepthReplace = (baseURL, param, value) => {
  const regex = new RegExp(`__${param}\\((\\d+)\\)__`)
  const [match, depthString] = baseURL.match(regex) ?? []

  if (!match) {
    return baseURL
  }

  const depth = parseInt(depthString, 10)

  const encodedValue = Array.from({ length: depth }).reduce(
    encodeURIComponent,
    value
  )

  return baseURL.replace(match, encodedValue)
}
