export const getElement = element => {
  if (typeof element === 'string') {
    return document.querySelector(element)
  }
  return element
}

export const isElementInViewport = element => {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.right <= window.innerWidth &&
    rect.bottom <= window.innerHeight &&
    rect.left >= 0
  )
}
