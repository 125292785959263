import { useMemo, useState } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import {
  ActivityIndicator,
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Modal,
  Table,
  Typography
} from '@etvas/etvaskit'

import { T } from '@shared/i18n'

const PaymentCouponView = ({ coupon, onDetach, isBusy, ...props }) => {
  const [isModalShown, setModalShown] = useState(false)
  const couponValue = useMemo(
    () =>
      coupon.amountOff
        ? `€${(coupon.amountOff / 100).toFixed(2)}`
        : `${coupon.percentOff.toFixed(2)}%`,
    [coupon]
  )

  const couponExpires = useMemo(
    () =>
      coupon.validUntil ? moment(coupon.validUntil).format('D MMM YYYY') : '',
    [coupon]
  )

  const toggleModal = () => {
    if (!isBusy) {
      setModalShown(!isModalShown)
    }
  }

  return (
    <T>
      {({ translate }) => (
        <Box mt={4}>
          <Table textAlign='left' breakpoint={400} {...props}>
            <Table.Header>
              <Table.Row>
                <Table.Cell>{translate('label.coupon')}</Table.Cell>
                <Table.Cell>{translate('label.couponValue')}</Table.Cell>
                <Table.Cell>
                  {coupon.validUntil ? translate('label.expires') : ''}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell leader>
                  <Flex alignItems='center'>
                    <Icon name='devices' size='22px' />
                    <Box ml={2}>
                      {coupon.name}
                    </Box>
                  </Flex>
                </Table.Cell>
                <Table.Cell>
                  <Typography
                    variant='labelSmallBold'
                  >
                    {couponValue}
                  </Typography>
                </Table.Cell>
                <Table.Cell>
                  <Typography
                    variant='labelSmallBold'
                  >
                    {couponExpires}
                  </Typography>
                </Table.Cell>
                <Table.Cell>
                  <Button variant='link' onClick={toggleModal}>
                    <T label='label.couponRemove' />
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {isModalShown && (
            <Modal
              backDrop='whiteShadow'
              alignItems='center'
              justifyContent='center'
            >
              <Modal.Content maxWidth={'400px'} onClose={toggleModal}>
                <Card p={10}>
                  {isBusy && (
                    <ActivityIndicator
                      variant='runningbar'
                      colors={{ background: 'transparent', primary: 'brand' }}
                    />
                  )}
                  <Flex flexDirection='column'>
                    <Typography variant='titleLargest'>
                      {translate('title.couponRemove')}
                    </Typography>
                    <Typography pt={6} pb={4} variant='labelSmall'>
                      {translate('text.couponRemove', [coupon.name])}
                    </Typography>

                    <Flex mt={8}>
                      <Button
                        variant='primary'
                        onClick={toggleModal}
                        disabled={isBusy}
                      >
                        {translate('label.goBack')}
                      </Button>
                      <Button
                        variant='primary'
                        type='submit'
                        ml={3}
                        onClick={onDetach}
                        loading={isBusy}
                        disabled={isBusy}
                      >
                        {translate('text.confirm')}
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </Modal.Content>
            </Modal>
          )}
        </Box>
      )}
    </T>
  )
}

PaymentCouponView.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    percentOff: PropTypes.number,
    amountOff: PropTypes.number,
    validUntil: PropTypes.number
  }),
  onDetach: PropTypes.func,
  isBusy: PropTypes.bool
}

export default PaymentCouponView
