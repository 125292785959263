import { useContext } from 'react'

import { CouponContext } from '@services/CouponProvider'

import PaymentCouponEditor from './components/PaymentCouponEditor'
import PaymentCouponSkeleton from './components/PaymentCouponSkeleton'
import PaymentCouponView from './components/PaymentCouponView'

const PaymentCoupon = () => {
  const {
    paymentCoupon,
    requestAttachPaymentCoupon,
    requestDetachPaymentCoupon,
    isPaymentCouponUpdating,
    isPaymentCouponFetching
  } = useContext(CouponContext)

  if (isPaymentCouponFetching) {
    return <PaymentCouponSkeleton />
  }

  if (!paymentCoupon) {
    return (
      <PaymentCouponEditor
        onAttach={requestAttachPaymentCoupon}
        isBusy={isPaymentCouponUpdating}
      />
    )
  }
  return (
    <PaymentCouponView
      onDetach={requestDetachPaymentCoupon}
      isBusy={isPaymentCouponUpdating}
      coupon={paymentCoupon}
    />
  )
}

export default PaymentCoupon
