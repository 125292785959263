import { useContext } from 'react'

import { AuthContext } from '@services/AuthProvider'
import { OrganizationContext } from '@services/OrganizationProvider'
import { Redirect } from 'react-router-dom'

import { AuthTemplate } from '@shared/components'

import RegisterConsent from './components/RegisterConsent'

const RegisterUserConsent = () => {
  const { isLoadingUserProfile, hasConsent } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)

  if (isLoadingUserProfile) {
    return null
  }

  if (hasConsent) {
    return <Redirect to='/' />
  }

  return (
    <AuthTemplate brandMedia={organization?.brandMedia}>
      <RegisterConsent />
    </AuthTemplate>
  )
}

export default RegisterUserConsent
