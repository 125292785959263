const sidebar = [
  { id: 'discover', label: 'menu.discover', route: '/', icon: 'discover' },
  {
    id: 'myProducts',
    label: 'menu.myProducts',
    route: '/my-products',
    icon: 'shopping'
  },
  {
    id: 'account',
    label: 'menu.myAccount',
    route: '/account',
    icon: 'badgeAccountHorizontal'
  }
]

export default sidebar
