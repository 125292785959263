import { KEYS, STORAGE, keyed } from './constants'
import { _isAvailable } from './local'

export const isSessionStorageAvailable = () => _isAvailable('sessionStorage')

export const createSessionStorage = mimic => {
  const mk = !mimic || mimic === STORAGE.Session ? '' : KEYS[mimic]

  return {
    __type: STORAGE.Session,
    getItem: key => window.sessionStorage.getItem(`${mk}${key}`),
    setItem: (key, value) =>
      window.sessionStorage.setItem(`${mk}${key}`, value),
    removeItem: key => window.sessionStorage.removeItem(`${mk}${key}`),
    hasItem: key => {
      const value = window.sessionStorage.getItem(`${mk}${key}`)
      return value !== undefined && value !== null
    },
    clear: () => {
      Object.keys(window.sessionStorage)
        .filter(key => (mk ? key.startsWith(mk) : !keyed(key)))
        .forEach(key => window.sessionStorage.removeItem(key))
    }
  }
}
