const mediaSliderStyles = {
  nav: {
    height: '100%',
    textAlign: 'center'
  },
  swipeContainer: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  icon: {
    background: 'outline',
    color: 'brand',
    borderRadius: '50%'
  },
  container: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '.15rem'
  },
  image: {
    backgroundSize: 'cover',
    marginRight: 1,
    marginLeft: 1
  },
  singleMediaWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  singleImage: {
    objectFit: 'contain',
    borderRadius: '.15rem',
    maxWidth: '100%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}

export default mediaSliderStyles
