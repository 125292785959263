import gql from 'graphql-tag'

import {
  AVAILABLE_PRODUCT_FRAGMENT,
  PRODUCT_PURCHASE_STATUS_FRAGMENT
} from '@shared/services/graphql/fragments'

export const GET_PURCHASED_PRODUCTS = gql`
  query listMyProducts {
    listMyProducts {
      ...productPurchaseStatus
      ...availableProduct
    }
  }
  ${PRODUCT_PURCHASE_STATUS_FRAGMENT}
  ${AVAILABLE_PRODUCT_FRAGMENT}
`
