const primaryColors = {
  // styleguide colors
  brand: 'var(--etvas-brand-color)',
  brandFade: 'rgba(var(--etvas-brand-color), .3)',
  brandLight: 'rgba(var(--etvas-brand-color), .1)',
  accent: 'var(--etvas-accent-color)',
  accentFade: 'rgba(var(--etvas-accent-color), .3)',
  white: '#ffffff',
  black: '#000000',
  lightGray: '#a0aab2',
  darkGray: '#35373b',
  whiteShadow: 'rgba(255, 255, 255, .75)',
  etvas: '#00C0FC',

  // legacy colors
  mist: 'rgba(255, 255, 255, .7)',
  shadow: 'rgba(19, 51, 77, 0.15)',
  overdrive: 'rgba(255, 255, 255, .98)',
  dark: '#343434',
  gray: '#999',
  light: '#a0aab3',
  lighter: '#ccc',
  lightest: '#fbfdff',
  bodyBg: '#fbfdff',
  danger: 'red',
  downgrade: '#C2C2C2',
  ash: '#B3B3B3',
  highlight: '#1E1E1E'
}

export default primaryColors
