import styled from 'styled-components'

import { Box, themed } from '@etvas/etvaskit'

export default styled(Box)`
  border-radius: 3px;
  padding: 12px;
  height: 42px;
  border: 1px solid
    ${({ tinted }) =>
      tinted ? 'transparent' : themed('colors.inputBorderGray')};
  background-color: ${({ tinted }) => (tinted ? 'white' : 'transparent')};
  &:hover {
    border-color: ${({ active, tinted }) =>
      active
        ? themed('colors.brandLight')
        : tinted
          ? 'transparent'
          : themed('colors.inputBorderGray')};
  }
`
