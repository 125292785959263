import { useState } from 'react'

export const useBoolean = (initial = false) => {
  const [isTrue, setBoolean] = useState(initial)

  const _set = (value = true) => setBoolean(value)
  const _unset = () => setBoolean(initial)
  const _toggle = () => setBoolean(!isTrue)

  return [isTrue, _set, _unset, _toggle]
}
