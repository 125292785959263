import { useContext, useEffect, useState } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import {
  Button,
  Flex,
  Form,
  Icon,
  TextField,
  Typography
} from '@etvas/etvaskit'

import { ModalDialog } from '@shared/components'
import { assertConfirmed } from '@shared/funcs'
import { I18nContext, T } from '@shared/i18n'

import { useCancelPurchase } from '../services'
import ConfirmationText from './ConfirmationText'

const CancelSubscriptionModal = ({
  closeModal,
  purchasedProduct,
  ...props
}) => {
  const { translate } = useContext(I18nContext)
  const [isSuccess, setSuccess] = useState(false)
  const { isCancellingPurchase, requestCancelPurchase } = useCancelPurchase()
  useEffect(() => {
    setSuccess(false)
  }, [])
  const initialValues = { cancelMessage: '' }
  const _handleSubmit = async (_, { setStatus }) => {
    setStatus(null)
    const result = await requestCancelPurchase({
      id: purchasedProduct.purchaseId
    })

    if (result?.hasErrors) {
      setStatus(result.errorMessage)
      return
    }

    setSuccess(!!result)
  }

  const onClose = event => {
    if (isCancellingPurchase) {
      return
    }

    return closeModal(event)
  }

  const hasActiveTrial = !!purchasedProduct?.trialEndsAt
  const trialEndsDate = moment(purchasedProduct?.trialEndsAt).format('LL')

  return (
    <ModalDialog
      alignItems='center'
      justifyContent='center'
      width={['90%', '510px']}
      onDismiss={onClose}
      title={
        <T
          label={
            isSuccess
              ? 'title.canceledSubscription'
              : 'title.cancelSubscription'
          }
        />
      }
      p={6}
      {...props}
    >
      {isSuccess ? (
        <>
          <Typography pt={6} variant='labelSmall'>
            <T
              label='text.canceledSubscription'
              args={{
                productName: purchasedProduct?.name
              }}
            />
          </Typography>
          {hasActiveTrial && (
            <Typography
              pt={6}
              variant='labelSmall'
              dangerouslySetInnerHTML={{
                __html: translate('text.useProductUntil', [trialEndsDate])
              }}
            />
          )}
          <Button mt={6} onClick={closeModal} variant='primary' type='submit'>
            <T label='label.goBack' />
          </Button>
        </>
      ) : (
        <Flex flexDirection='column'>
          {hasActiveTrial && (
            <Flex alignItems='center' pt={4}>
              <Icon name='alertCircle' color='error' size='small' />
              <Typography variant='labelSmallBold' color='error' ml={1}>
                <T label='text.trialUntil' args={[trialEndsDate]} />
              </Typography>
            </Flex>
          )}
          <Typography
            py={4}
            variant='labelSmall'
            dangerouslySetInnerHTML={{
              __html: translate('text.cancelSubscription', {
                productName: purchasedProduct?.name
              })
            }}
          />
          <ConfirmationText variant='labelSmall'>
            <T label='text.cancelSubscriptionInfo' />
          </ConfirmationText>
          <Form initialValues={initialValues} onSubmit={_handleSubmit}>
            {({ values }) => (
              <Flex
                pt={5}
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <TextField
                  name='cancelMessage'
                  id='cancelMessage'
                  disabled={isCancellingPurchase}
                  placeholder={translate('text.typeHere')}
                  required
                />
                <Flex mt={6}>
                  <Button
                    variant='primary'
                    disabled={isCancellingPurchase}
                    onClick={closeModal}
                  >
                    <T label='label.goBack' />
                  </Button>
                  <Button
                    variant='primary'
                    type='submit'
                    ml={3}
                    loading={isCancellingPurchase}
                    disabled={
                      isCancellingPurchase ||
                      !assertConfirmed(
                        values.cancelMessage,
                        translate('label.cancelSubscription')
                      )
                    }
                  >
                    <T label='text.confirm' />
                  </Button>
                </Flex>
              </Flex>
            )}
          </Form>
        </Flex>
      )}
    </ModalDialog>
  )
}

CancelSubscriptionModal.propTypes = {
  closeModal: PropTypes.func,
  purchasedProduct: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    purchaseId: PropTypes.string,
    trialEndsAt: PropTypes.number
  })
}

export default CancelSubscriptionModal
