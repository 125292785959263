import { useEffect, useRef } from 'react'

export const useOnce = effect => {
  const hasMounted = useRef(false)

  useEffect(() => {
    if (!hasMounted.current && effect) {
      hasMounted.current = true
      effect()
    }
  }, [effect])
}
