import { useMemo } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import { Box, Flex, Space, Typography } from '@etvas/etvaskit'

import { PriceOption } from '@shared/components'
import { productPurchase } from '@shared/constants/product'
import { T } from '@shared/i18n'

import { TrialChip } from '../Product/TrialChip'

const PurchaseDetails = ({ product, coupon, ...props }) => {
  const couponValue = useMemo(
    () =>
      coupon
        ? coupon.amountOff
          ? `€${(coupon.amountOff / 100).toFixed(2)}`
          : `${coupon.percentOff.toFixed(2)}%`
        : '',
    [coupon]
  )

  const oneTimeSubscription = productPurchase.isOneTimeSubscription(product)
  const lifetimeSubscription = productPurchase.isLifetimeSubscription(product)

  const expirationDate = useMemo(
    () => moment().add(product.subscriptionMonths, 'month').format('ll'),
    [product]
  )

  return (
    <Box {...props}>
      <Space mt={2}>
        <DisplayItem label={<T label='label.service' />} value={product.name} />
        <DisplayItem
          label={<T label='label.provider' />}
          value={product.servicePartner.name}
        />
        <DisplayItem
          label={<T label='label.price' />}
          value={<PriceOption product={product} />}
        />
        {!lifetimeSubscription && oneTimeSubscription ? (
          <DisplayItem
            label={<T label='label.expirationDate' />}
            value={expirationDate}
          />
        ) : null}
        {coupon ? (
          <DisplayItem
            label={<T label='label.coupon' />}
            value={<T label='text.couponApplied' args={[couponValue]} />}
          />
        ) : null}
        <TrialChip product={product} my={2} />
      </Space>
    </Box>
  )
}

const DisplayItem = ({ label, value, ...props }) => (
  <Flex {...props}>
    <Typography variant='labelSmall' mr={2} color='textInputActive'>
      {label}:
    </Typography>
    <Typography variant='labelSmall' fontWeight='400' color='textInputActive'>
      {value}
    </Typography>
  </Flex>
)

DisplayItem.propTypes = {
  label: PropTypes.node,
  value: PropTypes.node
}

PurchaseDetails.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    servicePartner: PropTypes.shape({
      name: PropTypes.string
    }),
    subscriptionMonths: PropTypes.number,
    subscriptionType: PropTypes.string
  }),
  coupon: PropTypes.shape({
    name: PropTypes.string,
    amountOff: PropTypes.number,
    percentOff: PropTypes.number
  })
}

export default PurchaseDetails
