import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

import { Box } from '@etvas/etvaskit'

import { storage } from '@shared/funcs'

import { EmailCheck } from './EmailCheck'
import { Login } from './Login'
import { RecoverPassword } from './RecoverPassword'
import { Register } from './Register'

export const authStates = {
  checkEmail: 'checkEmail',
  login: 'login',
  register: 'register',
  recoverPassword: 'recoverPassword',
  requireNewPassword: 'requireNewPassword'
}

const AuthFlow = ({ onAuthStateChange, onUserDataChange }) => {
  const history = useHistory()
  const { search } = useLocation()
  const [authState, setAuthState] = useState(authStates.checkEmail)

  const [userData, setUserData] = useState({})
  const [isExistingCustomer, setIsExistingCustomer] = useState()

  const goToCheckEmail = () => {
    setIsExistingCustomer()
    handleAuthStateChange('checkEmail')
  }
  useEffect(() => {
    const params = new URLSearchParams(search)
    const state = params.get('state')

    if (!state) {
      return
    }

    if (storage.authSavedUser) {
      switch (state) {
        case 'sms_code_register':
        case 'email_code_register':
          setAuthState(authStates.register)
          break
        case 'sms_code_login':
        case 'email_code_login':
          setAuthState(authStates.login)
          break
        default:
          break
      }
    }
    return () => {
      params.delete('state')
      history.replace({ search: params.toString() })
    }
  }, [search, history])

  useEffect(() => {
    if (typeof isExistingCustomer === 'boolean') {
      setAuthState(isExistingCustomer ? authStates.login : authStates.register)
    }
  }, [isExistingCustomer])

  useEffect(() => {
    if (onAuthStateChange) {
      onAuthStateChange(authState)
    }
  }, [onAuthStateChange, authState])

  const handleAuthStateChange = newAuthState => {
    setAuthState(newAuthState)
  }

  const updateUserData = newData =>
    setUserData(prev => ({ ...prev, ...newData }))

  useEffect(() => {
    const data = userData[onUserDataChange?.attribute]
    if (!data || !onUserDataChange?.attribute || !onUserDataChange?.handler) {
      return
    }
    onUserDataChange.handler(data)
  }, [onUserDataChange, userData])

  const renderStep = newAuthState => {
    switch (newAuthState) {
      case authStates.checkEmail:
        return (
          <EmailCheck
            userData={userData}
            updateUserData={updateUserData}
            onAvailabilityChange={value => setIsExistingCustomer(!value)}
          />
        )
      case authStates.login:
        return (
          <Login
            userData={userData}
            updateUserData={updateUserData}
            handleAuthStateChange={handleAuthStateChange}
            goToCheckEmail={goToCheckEmail}
          />
        )
      case authStates.register:
        return (
          <Register
            userData={userData}
            updateUserData={updateUserData}
            goToCheckEmail={goToCheckEmail}
          />
        )
      case authStates.recoverPassword:
        return (
          <RecoverPassword
            userData={userData}
            handleAuthStateChange={handleAuthStateChange}
          />
        )

      default:
        console.error('Unhandled auth state: ', newAuthState)
    }
  }

  return <Box>{renderStep(authState)}</Box>
}

AuthFlow.propTypes = {
  onAuthStateChange: PropTypes.func,
  onUserDataChange: PropTypes.shape({
    handler: PropTypes.func,
    attribute: PropTypes.string
  })
}

export default AuthFlow
