import { useContext, useEffect } from 'react'

import { useCheckEmail } from '@services/hooks'
import PropTypes from 'prop-types'

import { Button, Form, Space, TextField, Typography } from '@etvas/etvaskit'

import { I18nContext } from '@etvas/i18n'

import { T } from '@shared/i18n'
import { emailRegex } from '@shared/validators'

export const EmailCheck = ({
  userData,
  onAvailabilityChange,
  updateUserData
}) => {
  const { translate } = useContext(I18nContext)
  const { error, loading, checkEmail, isEmailAvailable, isInvalidEmail } =
    useCheckEmail()
  const initialValues = {
    email: userData?.email ?? ''
  }

  useEffect(() => {
    if (typeof isEmailAvailable === 'boolean') {
      onAvailabilityChange(isEmailAvailable)
    }
  }, [isEmailAvailable, onAvailabilityChange])

  const handleCheckEmail = async (values, { setFieldError }) => {
    const trimmedEmail = values.email.trim().toLowerCase()
    updateUserData({ email: trimmedEmail })

    const response = await checkEmail(trimmedEmail)
    if (isInvalidEmail(response)) {
      setFieldError('email', translate('error.invalidEmail'))
    }
  }

  return (
    <>
      <Space mb={4}>
        <Typography variant='base14Light'>
          <T label='text.enterAuthEmail' />
        </Typography>
      </Space>

      <Form
        validate={validate}
        initialValues={initialValues}
        onSubmit={handleCheckEmail}
      >
        {({ values }) => (
          <>
            <TextField
              name='email'
              label={<T label='label.email' />}
              placeholder={translate('label.email')}
              required
            />
            {error && (
              <Typography
                variant='base12Light'
                color='statusError'
                textAlign='center'
              >
                <T label='error.unknown' />
              </Typography>
            )}
            <Button
              variant='primary'
              width={['100%', 'fit-content']}
              mt={3}
              type='submit'
              mx='auto'
              disabled={loading || !values.email}
              loading={loading}
            >
              <T label='label.continue' />
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

EmailCheck.propTypes = {
  onAvailabilityChange: PropTypes.func,
  updateUserData: PropTypes.func,
  userData: PropTypes.object
}

const validate = ({ email }) => {
  let errors = {}

  if (!email) {
    errors.email = <T label='label.required' />
  } else if (!emailRegex.test(email)) {
    errors.email = <T label='text.invalidEmailFormat' />
  }

  return errors
}
