import { useRef, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Flex, Icon, Touchable } from '@etvas/etvaskit'

import { appendTime, getVideoMime } from '@shared/funcs'

export const VideoPlayer = ({ video, ...props }) => {
  const [started, setStarted] = useState(false)
  const videoRef = useRef()

  const handlePlayPause = () => {
    videoRef.current.play()
    setStarted(true)
  }

  return (
    <Box width='100%' height='100%' position='relative'>
      <Video
        width='100%'
        height='100%'
        ref={videoRef}
        controls={started}
        {...props}
      >
        <source src={appendTime(video)} type={getVideoMime(video)} />
        Your browser does not support HTML 5 video tag.
      </Video>

      {!started && (
        <PlayControl>
          <Touchable onClick={handlePlayPause}>
            <Icon name='mdiPlayCircle' color='baseWhite' size='120px' />
          </Touchable>
        </PlayControl>
      )}
    </Box>
  )
}

VideoPlayer.propTypes = {
  video: PropTypes.string
}

const Video = styled.video`
  border-radius: 8px;
`

const PlayControl = styled(Flex)`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`
