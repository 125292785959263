import { useCallback, useContext, useMemo } from 'react'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { Button } from '@etvas/etvaskit'

import { I18nContext, T } from '@etvas/i18n'

import { showExternal } from '@shared/funcs'

import { isProductPurchased, opensInNewTab } from '../hooks/purchasedService'

// Landing Page Purchase Button
const PurchaseButton = ({ product, onPurchase, loading, ...btnProps }) => {
  const history = useHistory()

  const onUse = useCallback(
    () => showExternal(product, { withRefresh: true, navigate: history.push }),
    [product, history]
  )

  if (opensInNewTab(product)) {
    return (
      <Button
        type=''
        as='a'
        href={`/products/product/${product.id}/use`}
        target='_blank'
        style={{ display: 'inline-flex' }}
        {...btnProps}
      >
        <T label='label.useProduct' />
      </Button>
    )
  }

  return isProductPurchased(product) ? (
    <Button onClick={onUse} loading={loading} disabled={loading} {...btnProps}>
      <T label='label.useProduct' />
    </Button>
  ) : (
    <Button
      onClick={onPurchase}
      loading={loading}
      disabled={loading}
      {...btnProps}
    >
      <ButtonContent product={product} />
    </Button>
  )
}

const ButtonContent = ({ product }) => {
  const { translate } = useContext(I18nContext)
  const hasTrial = useMemo(
    () => product.trialPeriod && product.trialUnit,
    [product]
  )

  if (hasTrial) {
    const unit = translate(`label.dates.${product.trialUnit}`)

    return <T label='label.startTrial' args={[product.trialPeriod, unit]} />
  }

  if (product.price === 0) {
    return <T label='text.getProduct' />
  }

  return <T label='text.purchaseProduct' />
}

PurchaseButton.propTypes = {
  productDetails: PropTypes.object,
  product: PropTypes.object,
  loading: PropTypes.bool,
  isPurchased: PropTypes.bool,
  onPurchase: PropTypes.func
}

ButtonContent.propTypes = {
  product: PropTypes.object
}

export default PurchaseButton
