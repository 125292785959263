import { useContext, useEffect, useRef, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

import {
  Button,
  Flex,
  Form,
  Space,
  TextField,
  Typography
} from '@etvas/etvaskit'

import {
  getCognitoUserFromObject,
  getSimplifiedCognitoUser,
  loginWithTrace,
  storage,
  userApi
} from '@shared/funcs'
import { I18nContext, T } from '@shared/i18n'
import { validateSignIn } from '@shared/validators'

import { ConfirmEmailCode } from './ConfirmEmailCode'
import { ConfirmSMSCode } from './ConfirmSMSCode'
import { RequireNewPassword } from './RequireNewPassword'

export const Login = ({
  userData,
  handleAuthStateChange,
  updateUserData,
  goToCheckEmail
}) => {
  const history = useHistory()
  const { search } = useLocation()
  const { translate } = useContext(I18nContext)
  const { organizationId } = useContext(OrganizationContext)
  const [user, setUser] = useState()
  const [needsSMSCodeConfirmation, setNeedsSMSCodeConfirmation] =
    useState(false)
  const [needsEmailCodeConfirmation, setNeedsEmailCodeConfirmation] =
    useState(false)
  const [needsNewPassword, setNeedsNewPassword] = useState(false)

  const [isSendingRecoverPassCode, setIsSendingRecoverPassCode] =
    useState(false)
  const [recoverPassError, setRecoverPassError] = useState()

  const pwRef = useRef()
  const initialValues = {
    username: userData?.email || '',
    password: ''
  }

  useEffect(() => {
    if (pwRef.current) {
      pwRef.current.focus()
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(search)
    const state = params.get('state')

    const savedUser = storage.authSavedUser
    if (state === 'sms_code_login' && savedUser) {
      const user = getCognitoUserFromObject(savedUser)

      setUser(user)
      setNeedsSMSCodeConfirmation(true)
    }
  }, [search])

  const _signIn = async (
    { username, password },
    { setSubmitting, setStatus }
  ) => {
    try {
      setStatus()
      const user = await loginWithTrace(username.toLowerCase(), password, {
        bpId: organizationId
      })
      updateUserData({ pw: password })

      if (user.challengeName === 'SMS_MFA') {
        setUser(user)
        setNeedsSMSCodeConfirmation(true)

        const params = new URLSearchParams(search)
        params.set('state', 'sms_code_login')

        history.push({
          search: params.toString()
        })
        storage.authSavedUser = getSimplifiedCognitoUser(user)

        return
      }

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(user)
        setNeedsNewPassword(true)
      }
    } catch (error) {
      const params = new URLSearchParams(search)

      switch (error.code) {
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
          setStatus(
            error.message.includes('attempts exceeded')
              ? 'text.tooManyAttempts'
              : 'text.incorrectEmailOrPassword'
          )
          break

        case 'UserNotConfirmedException':
          await userApi.resendEmailCode(username.toLowerCase(), {
            bpId: organizationId
          })

          params.set('state', 'email_code_login')

          history.push({ search: params.toString() })
          updateUserData({ email: username, password })
          setNeedsEmailCodeConfirmation(true)
          break

        default:
          setStatus('text.unknownError')
          console.warn('* Unexpected error caught while signing in', error)
          break
      }
      setSubmitting(false)
    }
  }

  const handleRecoverPassword = async () => {
    try {
      setRecoverPassError()
      setIsSendingRecoverPassCode(true)
      const response = await userApi.sendVerificationCode(userData.email, {
        bpId: organizationId
      })
      setIsSendingRecoverPassCode(false)
      if (response.CodeDeliveryDetails) {
        handleAuthStateChange('recoverPassword')
      }
    } catch (err) {
      setIsSendingRecoverPassCode(false)
      _handleCodeSendingError(err, setRecoverPassError)
      console.error(
        ' * Error while sending recover password verification code',
        err
      )
    }
  }

  if (needsSMSCodeConfirmation) {
    return (
      <ConfirmSMSCode
        user={user}
        userData={userData}
        handleAuthStateChange={handleAuthStateChange}
      />
    )
  }

  if (needsEmailCodeConfirmation) {
    return (
      <ConfirmEmailCode
        userData={userData}
        setUser={setUser}
        state='login'
        retryLogin={() => setNeedsEmailCodeConfirmation(false)}
      />
    )
  }

  if (needsNewPassword) {
    return (
      <RequireNewPassword
        user={user}
        retryLogin={() => setNeedsNewPassword(false)}
      />
    )
  }

  return (
    <>
      <Space mb={4}>
        <Typography variant='base14Light'>
          <T label='text.enterPassword' />
        </Typography>
      </Space>

      <Form
        initialValues={initialValues}
        validate={validateSignIn}
        onSubmit={_signIn}
      >
        {({ isSubmitting, status }) => (
          <>
            <TextField
              name='username'
              label={<T label='label.email' />}
              placeholder={translate('label.email')}
              disabled
              required
            />
            <TextField
              type='password'
              passwordView='toggle'
              name='password'
              label={<T label='label.password' />}
              placeholder={translate('label.password')}
              ref={pwRef}
              required
            />
            {(status || recoverPassError) && (
              <Typography
                variant='base12Light'
                color='statusError'
                textAlign='center'
                mt={2}
                mb={4}
              >
                {recoverPassError || <T label={status} />}
              </Typography>
            )}

            <Flex flexDirection='column' alignItems='center' mt={2}>
              <Flex alignItems='center' mb={4}>
                <Button
                  variant='link'
                  onClick={handleRecoverPassword}
                  disabled={isSubmitting || isSendingRecoverPassCode}
                  mr={3}
                >
                  <T label='title.recoverPassword' />
                </Button>
                <Button
                  variant='link'
                  onClick={goToCheckEmail}
                  disabled={isSubmitting || isSendingRecoverPassCode}
                >
                  <T label='label.changeEmail' />
                </Button>
              </Flex>

              <Button
                variant='primary'
                type='submit'
                disabled={isSubmitting || isSendingRecoverPassCode}
                loading={isSubmitting}
              >
                <T label='label.login' />
              </Button>
            </Flex>
          </>
        )}
      </Form>
    </>
  )
}

const _handleCodeSendingError = (err, setRecoverPassError) => {
  const { code } = err ?? {}
  switch (code) {
    case 'UserNotFoundException':
      return setRecoverPassError(<T label='text.cantFindEmail' />)
    default:
      return setRecoverPassError(<T label='text.errorChangingPassword' />)
  }
}

Login.propTypes = {
  userData: PropTypes.object,
  handleAuthStateChange: PropTypes.func,
  updateUserData: PropTypes.func,
  goToCheckEmail: PropTypes.func
}
