import { useContext } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import styled from 'styled-components'

import { Box } from '@etvas/etvaskit'

export const PageFooter = () => {
  const { organization } = useContext(OrganizationContext)
  return (
    <PageFooterBox
      width={1}
      height='300px'
      bgImage={organization.footerImage}
    />
  )
}

const PageFooterBox = styled(Box)`
  background-size: 50%;
  background-image: url('${({ bgImage }) => bgImage || '/landing/footer.svg'}');
  background-repeat: repeat-x;
  background-position: bottom center;
`
