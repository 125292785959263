import { useCallback, useContext, useState } from 'react'

import { CouponContext } from '@services/CouponProvider'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { sendWindowMessage, showExternal } from '@shared/funcs'

import { BillingInfoModal } from '../components/BillingInfo'
import { Purchase } from '../components/Purchase'

export const usePurchaseModals = ({
  product,
  showEmbeddedUse = false,
  initPurchase = false,
  showModals = true
}) => {
  const history = useHistory()
  const [isBillingInfoShown, setBillingInfoShown] = useState(initPurchase)
  const { paymentCoupon } = useContext(CouponContext)
  const [action, setAction] = useState(null)

  const handleFinish = useCallback(() => {
    setAction(null)
    setBillingInfoShown(false)
  }, [setAction])

  const handlePurchase = useCallback(() => {
    setBillingInfoShown(false)
    setAction(product)
  }, [product])

  const handleUse = useCallback(
    () =>
      showEmbeddedUse
        ? sendWindowMessage('open-product-use', { productId: product.id })
        : showExternal(product, { withRefresh: false, navigate: history.push }),
    [product, showEmbeddedUse, history]
  )

  const handleBillingInfo = useCallback(() => setBillingInfoShown(true), [])

  return {
    PurchaseModal,
    modalProps: {
      action,
      product,
      handleUse,
      showModals,
      handleFinish,
      paymentCoupon,
      handlePurchase,
      handleBillingInfo,
      isBillingInfoShown
    }
  }
}

const PurchaseModal = ({
  action,
  product,
  handleUse,
  showModals,
  handleFinish,
  paymentCoupon,
  handlePurchase,
  isBillingInfoShown
}) => (
  <>
    {isBillingInfoShown ? (
      <BillingInfoModal
        product={product}
        onCancel={handleFinish}
        onProceed={handlePurchase}
        showModal={showModals}
      />
    ) : null}
    {action ? (
      <Purchase
        paymentCoupon={paymentCoupon}
        product={product}
        onCancel={handleFinish}
        onFinish={handleFinish}
        onUse={handleUse}
        showModal={showModals}
      />
    ) : null}
  </>
)

PurchaseModal.propTypes = {
  isBillingInfoShown: PropTypes.bool,
  product: PropTypes.object,
  handleFinish: PropTypes.func,
  handlePurchase: PropTypes.func,
  action: PropTypes.object,
  paymentCoupon: PropTypes.any,
  handleUse: PropTypes.func,
  showModals: PropTypes.bool
}
