import { isEmbedded, isLandingPage } from '@shared/funcs'

import { safeUrlDecodeBase64 } from './decode'

// REACT_APP_ALLOW_SSO=embed,landing,portal # allow separately for each section
// REACT_APP_ALLOW_SSO=*  # allow for all

const allowSSO = (process.env.REACT_APP_ALLOW_SSO || '*')
  .split(',')
  .map(value => value.trim().toLowerCase())

const isAllowed = variants => variants.some(value => allowSSO.includes(value))

const getSSOConfig = () => {
  const _current = isEmbedded()
    ? 'embed'
    : isLandingPage()
      ? 'landing'
      : 'portal'

  const embedAllowed = isAllowed(['embed', 'embedded', '*'])
  const landingAllowed = isAllowed([
    'landing',
    'landingpage',
    'landing_page',
    'lp',
    '*'
  ])
  const portalAllowed = isAllowed([
    'customer',
    'customer_portal',
    'customerportal',
    'portal',
    'main',
    '*'
  ])

  return {
    _current,
    embed: { enabled: embedAllowed, storage: 'memory' },
    landing: { enabled: landingAllowed, storage: 'cookie' },
    portal: { enabled: portalAllowed, storage: 'cookie' }
  }
}

export const ssoConfig = getSSOConfig()

export const getAuthStorage = () =>
  ssoConfig?.[ssoConfig._current]?.enabled
    ? ssoConfig[ssoConfig._current].storage
    : null

const _decode = value => {
  try {
    return safeUrlDecodeBase64(value)
  } catch (err) {
    console.warn('Could not decode Base64 value', err)
    return value
  }
}

export const unpack = query => {
  const { _sso, _ssc } = query
  if (!_sso && !_ssc) {
    return null
  }

  return {
    value: _decode(_sso || _ssc),
    type: _sso ? 'cognitoRefreshToken' : 'authCode'
  }
}
