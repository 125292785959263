import PropTypes from 'prop-types'

import { styled } from '@etvas/etvaskit'

const _color = 'rgba(var(--etvas-lighter-text-color), .7)'

const Bullets = ({ num, color, ...props }) => {
  const bullets = new Array(num)
    .fill(1, 0, num)
    .map((_, i) => ({ key: `blt-${i}` }))
  return (
    <BulletsWrapper {...props}>
      {bullets.map(b => (
        <Bullet key={b.key} color={color} />
      ))}
    </BulletsWrapper>
  )
}

const BulletsWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
`

const Bullet = styled.b`
  display: block;
  min-width: 8px;
  width: 8px;
  height: 8px;
  min-height: 8px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
  margin-left: 2px;
`

Bullets.propTypes = {
  num: PropTypes.number,
  color: PropTypes.string
}

Bullets.defaultProps = {
  num: 4,
  color: _color
}

Bullets.defaultColor = _color

export default Bullets
