import { useContext } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { OrganizationContext } from '@services/OrganizationProvider'
import { COLLECT_CONSENT } from '@services/graphql/mutations'
import { LIST_MY_CONSENTS } from '@services/graphql/queries'

export const useGetPortalConsent = ({ skip }) => {
  const { organizationId } = useContext(OrganizationContext)
  const {
    data,
    loading: isLoadingConsents,
    error: errorListConsents
  } = useQuery(LIST_MY_CONSENTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip
  })
  const listOfConsents = data?.listMyConsents ?? []
  const hasConsent = listOfConsents.some(
    consent => consent.organizationId === organizationId
  )

  const [
    _collectConsent,
    { loading: isCollectingConsent, error: errorCollectingConsent }
  ] = useMutation(COLLECT_CONSENT, {
    update(cache, { data: { collectConsent } }) {
      cache.modify({
        fields: {
          listMyConsents(existingConsents = []) {
            if (!collectConsent?.success) {
              return existingConsents
            }

            const newConsent = {
              organizationId,
              __typename: 'Consent'
            }
            return [...existingConsents, newConsent]
          }
        }
      })
    }
  })

  const collectConsent = async () => {
    try {
      const { data } = await _collectConsent()
      return { success: data?.collectConsent?.success }
    } catch (err) {
      console.error('* ERROR while collecting consent', err)
      return { success: false }
    }
  }

  return {
    hasConsent,
    isLoadingConsents,
    errorListConsents,
    collectConsent,
    isCollectingConsent,
    errorCollectingConsent
  }
}
