import { useContext } from 'react'

import { AuthContext } from '@services/AuthProvider'
import PropTypes from 'prop-types'

import { Box, Card, Flex, Grid, Typography } from '@etvas/etvaskit'

import { BillingInfoCard } from '@shared/components/BillingInfo'
import ImprintCard from '@shared/components/ImprintCard'
import { T } from '@shared/i18n'

import AccountInformation from '../../account/AccountInformation'
import PaymentCoupon from '../../account/PaymentCoupon'
import PaymentHistory from '../../account/PaymentHistory'
import PurchasedProducts from '../../account/PurchasedProducts'
import PaymentMethodEditor from '../../account/components/PaymentMethodEditor'
import TitleCard from '../../account/components/TitleCard'
import { usePostHeight } from '../hooks/usePostHeight'

const EmbeddedSettings = ({ name }) => {
  usePostHeight(name)
  const { currentUser } = useContext(AuthContext)

  return (
    <>
      {' '}
      <TitleCard
        title='title.accountInformation'
      >
        {currentUser && Object.keys(currentUser).length ? (
          <AccountInformation user={currentUser} />
        ) : null}
      </TitleCard>
      <Grid cols={2}>
        <Grid.Item>
          <Flex flexDirection='column' height='100%'>
            <TitleCard title='title.paymentMethod'>
              <PaymentMethodEditor />
            </TitleCard>
            <Box style={{ flex: '2' }} />
            <TitleCard title='title.couponTitle'>
              <PaymentCoupon />
            </TitleCard>
          </Flex>
        </Grid.Item>
        <Grid.Item>
          <Flex
            py={4}
            flexDirection='column'
            height='100%'
          >
            <Typography mb={3} variant='labelButton' color='lightGray'>
              <T label='title.billingInformation' />
            </Typography>
            <Card p={6} style={{ flex: '2' }}>
              <BillingInfoCard showVat={true} />
            </Card>
          </Flex>
        </Grid.Item>
      </Grid>
      <TitleCard title='title.myProducts'>
        <PurchasedProducts />
      </TitleCard>
      <TitleCard title='title.paymentHistory'>
        <PaymentHistory />
      </TitleCard>
      <ImprintCard />
    </>
  )
}

EmbeddedSettings.propTypes = {
  name: PropTypes.string
}

export default EmbeddedSettings
