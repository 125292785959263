import { useContext, useEffect } from 'react'

import { AuthContext } from '@services/AuthProvider'
import BrowserStackProvider from '@services/BrowserStackProvider'
import CouponProvider from '@services/CouponProvider'
import { useSyncUserLocale } from '@services/hooks'
import { useTracking } from '@services/hooks/useTracking'
import { Route, Switch } from 'react-router-dom'

import { CouponModal, NotFound } from '@shared/components'
import { AppLayout } from '@shared/components/AppLayout'
import { clearUnmatchedOfferId, userApi } from '@shared/funcs'
import { useOnRouteChange } from '@shared/hooks'

import {
  Account,
  BlogArticleDetails,
  Discover,
  ExternalApp,
  MyProducts,
  ProductDetails
} from '../screens'

const ProtectedApp = () => {
  const { track, clearDebounceHistory } = useTracking()
  useOnRouteChange(location => {
    track({ name: 'page.view', value: location?.pathname })
    clearDebounceHistory()
    clearUnmatchedOfferId()
  })

  useSyncUserLocale()

  const { isLoadingUserProfile } = useContext(AuthContext)

  const protectedRoutes = [
    { path: '/', component: Discover, exact: true },
    { path: '/my-products', component: MyProducts, exact: true },
    { path: '/account', component: Account, exact: true },
    {
      path: '/products/:slug/:productId',
      component: ProductDetails,
      exact: true
    },
    { path: '/blog/:articleId', component: BlogArticleDetails, exact: true },
    {
      path: '/products/:slug/:productId/use',
      component: ExternalApp,
      exact: true
    }
  ]

  useEffect(() => {
    const onFocus = async ev => {
      try {
        await userApi.getCurrentSession()
      } catch (error) {
        console.error(
          ' * No current session found on window focus while trying to access the protected app',
          error
        )
        userApi.signOut()
      }
    }

    window.addEventListener('focus', onFocus)
    return () => window.removeEventListener('focus', onFocus)
  }, [])

  return (
    <CouponProvider>
      {!isLoadingUserProfile && <CouponModal />}
      <AppLayout>
        <BrowserStackProvider>
          {isLoadingUserProfile ? null : (
            <Switch>
              {protectedRoutes.map(route => (
                <Route
                  key={route.path}
                  component={route.component}
                  path={route.path}
                  exact={route.exact}
                />
              ))}
              <NotFound path='*' />
            </Switch>
          )}
        </BrowserStackProvider>
      </AppLayout>
    </CouponProvider>
  )
}

export default ProtectedApp
