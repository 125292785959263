import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import {
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_PURCHASE_STATUS
} from './graphql/queries'

export const useLandingData = ({
  pid,
  authenticated,
  isFetchingCognitoUser
}) => {
  const { loading, data, error } = useQuery(GET_PRODUCT_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: { input: { id: pid } },
    context: { unauthenticated: true },
    skip: isFetchingCognitoUser || authenticated,
    onError: error =>
      console.error(
        '* Error on landing page while trying to get unauthenticated product details',
        error
      )
  })

  const {
    loading: loadingPurchaseStatus,
    data: dataPurchaseStatus,
    refetch: getProductPurchaseStatus,
    error: authProductError
  } = useQuery(GET_PRODUCT_PURCHASE_STATUS, {
    fetchPolicy: 'no-cache',
    variables: { input: { id: pid } },
    skip: isFetchingCognitoUser || !authenticated,
    onError: error =>
      console.error(
        '* Error on landing page while trying to get authenticated product details',
        error
      )
  })

  const product = useMemo(() => {
    const _data = authenticated ? dataPurchaseStatus : data
    if (_data?.getProductDetails && _data?.getProductTermsAndConditions)
      return {
        ..._data.getProductDetails,
        terms: _data.getProductTermsAndConditions.termsAndConditions
      }
  }, [data, dataPurchaseStatus, authenticated])

  const refreshProductStatus = () => {
    if (authenticated) {
      getProductPurchaseStatus()
    }
  }

  return {
    isLoading: loading || loadingPurchaseStatus || isFetchingCognitoUser,
    product,
    refreshProductStatus,
    error: error || authProductError
  }
}
