import { SessionStorage } from '@shared/store'

import { getCurrentLocation } from './currentLocation'

const PREFIX_OID = 'etv_oid_'
export const OFFER_QUERY_PARAM_NAME = 'oid'

const _read = key => {
  const saved = SessionStorage.getItem(key)
  return saved ? JSON.parse(saved) : null
}
const _write = (key, data) => SessionStorage.setItem(key, JSON.stringify(data))
const _clear = key => SessionStorage.removeItem(key)

export const persistOfferId = location => {
  const _location = location ?? getCurrentLocation()
  const oid = _location.query[OFFER_QUERY_PARAM_NAME]
  if (!oid) {
    return false
  }
  _write(PREFIX_OID, {
    oid,
    path: _location.pathname
  })
  return true
}

export const getOfferId = location => {
  const _location = location ?? getCurrentLocation()
  const data = _read(PREFIX_OID)
  if (!data?.oid) {
    return null
  }
  return data?.path === _location.pathname ? data.oid : null
}

export const clearCampaignId = _clear

export const clearUnmatchedOfferId = location => {
  const _location = location ?? getCurrentLocation()
  const dataOffer = _read(PREFIX_OID)

  if (!dataOffer?.oid || !dataOffer?.path) {
    _clear(PREFIX_OID)
    return true
  }
  if (dataOffer.path !== _location.pathname) {
    _clear(PREFIX_OID)
    return true
  }

  return true
}
