import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import { AuthContext } from '@services/AuthProvider'

import {
  GET_AVAILABLE_PRODUCTS,
  GET_AVAILABLE_PRODUCTS_SUMMARY
} from '@shared/services/graphql/queries'

export const useEmbeddedProducts = () => {
  const { authenticated } = useContext(AuthContext)

  const GET_PRODUCTS = authenticated
    ? GET_AVAILABLE_PRODUCTS
    : GET_AVAILABLE_PRODUCTS_SUMMARY

  const { data, loading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    context: { unauthenticated: !authenticated }
  })

  return {
    products: data?.listAvailableProducts ?? [],
    isLoadingProducts: loading
  }
}
