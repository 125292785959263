import { useContext, useMemo } from 'react'

import { AuthContext } from '@services/AuthProvider'
import moment from 'moment'

import { BlogSection, Section } from '@shared/components'
import { T } from '@shared/i18n'

import { AvailableProducts, NoProductsFound } from './components'
import { useProducts } from './services/useProducts'
import { useFilterProducts } from './useFilterProducts'

const filterBy = ['name', 'servicePartner.name', 'summary']

const Discover = () => {
  const { currentUser } = useContext(AuthContext)
  const { availableProducts, articles, isLoadingAvailable, isLoadingBlogs } =
    useProducts()

  const [FilterBox, { filteredProducts }] = useFilterProducts({
    products: availableProducts,
    filterBy
  })

  const greetingLabel = useMemo(() => {
    const currentHour = moment().hour()
    if (currentHour > 5 && currentHour < 12) {
      return 'title.goodMorning'
    }
    if (currentHour >= 12 && currentHour < 18) {
      return 'title.goodAfternoon'
    }
    return 'title.goodEvening'
  }, [])

  return (
    <>
      <Section
        title={<T label={greetingLabel} args={[currentUser.firstName]} />}
        addon={FilterBox}
        py={0}
        backgroundColor='background'
      />

      {filteredProducts?.length === 0 ? (
        <NoProductsFound mt={5} mb={2} />
      ) : (
        <AvailableProducts
          pb={4}
          isFetching={isLoadingAvailable}
          products={filteredProducts || availableProducts}
        />
      )}

      {articles?.length ? (
        <Section title={<T label='label.articles' />}>
          <BlogSection isFetching={isLoadingBlogs} articles={articles} />
        </Section>
      ) : null}
    </>
  )
}

export default Discover
