import { useContext } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'

import { PAYMENT_METHODS } from '@shared/constants/paymentMethods'

const portalIds = {
  advanzia: '81614200-6a22-472c-a776-0f1ff95bfc13',
  smile: 'o-6e2fcacf-cec5-4d8b-bf7c-764966d1309d',
  'smile-staging': 'o-042340ae-841e-4251-a1bd-fe8bd51ff0c4'
}

const portalsConfig = {
  default: {
    sidebar: {
      hide: []
    },
    allowedPaymentMethods: [PAYMENT_METHODS.card, PAYMENT_METHODS.sepa]
  },
  smile: {
    allowedPaymentMethods: [PAYMENT_METHODS.card]
  },
  'smile-staging': {
    allowedPaymentMethods: [PAYMENT_METHODS.card]
  },
  advanzia: {
    allowedPaymentMethods: [PAYMENT_METHODS.card]
  }
}

export const useFeatureFlags = () => {
  const { organization } = useContext(OrganizationContext)

  let currentConfig = { ...portalsConfig.default }

  Object.keys(portalIds).forEach(portal => {
    if (organization.id === portalIds[portal]) {
      currentConfig = { ...currentConfig, ...(portalsConfig[portal] ?? {}) }
    }
  })

  return currentConfig
}
