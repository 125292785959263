import { useQuery } from '@apollo/client'

import { GET_BLOG_ITEMS, GET_PRODUCT_DETAILS } from './graphql/queries'

const queryConfig = {
  fetchPolicy: 'cache-and-network',
  partialRefetch: true
}

export const useProductDetails = id => {
  const productDetailsQueryConfig = {
    ...queryConfig,
    variables: { input: { id } }
  }

  const { data: productDetailsResult, loading: productDetailsFetching } =
    useQuery(GET_PRODUCT_DETAILS, productDetailsQueryConfig)

  const { data: blogItemsResult, loading: blogItemsFetching } = useQuery(
    GET_BLOG_ITEMS,
    productDetailsQueryConfig
  )

  return {
    product: productDetailsResult?.getProductDetails,
    isLoadingProduct: productDetailsFetching,
    articles: blogItemsResult?.listBlogItems ?? [],
    isLoadingArticles: blogItemsFetching
  }
}
