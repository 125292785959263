import { useEffect, useLayoutEffect, useMemo, useState } from 'react'

import css from '@styled-system/css'
import PropTypes from 'prop-types'
import Swipe from 'react-easy-swipe'
import styled from 'styled-components'

import { Box, Card, Flex } from '@etvas/etvaskit'

import { Markdown } from '@shared/components'
import { useCarouselSlides } from '@shared/services/useCarouselSlides'

import styles from './MediaSlider.styles'

const CarouselSlider = ({
  ratio,
  isSingle,
  currentIndex,
  setCurrentIndex,
  carouselSlides
}) => {
  const [slideWidth, setSlideWidth] = useState(992 / 2)
  const [doubled, setDoubled] = useState(1)
  const [swipeStart, setSwipeStart] = useState(0)
  const [swipeOffset, setSwipeOffset] = useState(0)

  const slides = useMemo(
    () => carouselSlides.slice(0).sort((a, b) => a.position - b.position),
    [carouselSlides]
  )

  useLayoutEffect(() => {
    const adjustWidth = () => {
      const el = document.getElementById('slider-container')
      const containerWidth = el.offsetWidth
      const slideMargin = 8
      const mediaWidth = containerWidth / 2 + slideMargin
      if (containerWidth > 600 && !isSingle) {
        setDoubled(2)
        setSlideWidth(mediaWidth)
      } else {
        setDoubled(1)
        setSlideWidth(containerWidth)
      }
    }

    window.addEventListener('resize', adjustWidth)
    adjustWidth()
    return () => {
      window.removeEventListener('resize', adjustWidth)
    }
  }, [isSingle, ratio])

  const swipe = dir => {
    let nextIndex = currentIndex + dir
    if (dir === -1 && nextIndex < 0) {
      nextIndex = slides.length - doubled
    } else if (dir === 1 && nextIndex > slides.length - doubled) {
      nextIndex = 0
    }
    setCurrentIndex(nextIndex)
  }

  const _onSwipeStart = () => {
    setSwipeStart(Date.now())
    setSwipeOffset(0)
  }
  const _onSwipeEnd = () => {
    setSwipeStart(0)
    if (swipeOffset > 100 && currentIndex > 0) {
      swipe(-1)
    } else if (swipeOffset < -100 && currentIndex < slides.length - doubled) {
      swipe(1)
    }
    setSwipeOffset(0)
  }

  const _onSwipeMove = position => {
    setSwipeOffset(position.x)
  }

  const _currentPosition = (() =>
    -1 * currentIndex * slideWidth + swipeOffset)()

  const scrollStyles = {
    transform: `translate3d(${_currentPosition}px, 0, 0)`,
    width: `${slideWidth * slides.length}px`
  }

  if (!swipeStart) {
    scrollStyles.transition = 'all .25s cubic-bezier(.82 ,0, .22, .99)'
  }

  return (
    <StyledContainer id='slider-container'>
      <StyledSwipe
        onSwipeStart={_onSwipeStart}
        onSwipeMove={_onSwipeMove}
        allowMouseEvents={true}
        onSwipeEnd={_onSwipeEnd}
      >
        <Flex style={scrollStyles} height='100%'>
          {slides.map(({ position, imageUrl, text }) => (
            <Flex
              key={position}
              flexDirection={[
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'row'
              ]}
              width={slideWidth}
            >
              <CenterItems
                width={['100%', '60%']}
                height={['100%', '50%', '60%']}
                pl={8}
                pr={[8, 0]}
              >
                <Box>
                  <Markdown variant='textSmall' lineHeight={1.4} md={text} />
                </Box>
              </CenterItems>
              <Flex
                height={['50%', '50%', '50%', '100%']}
                width={['100%', '100%', '100%', '40%']}
              >
                <CenterItems width='100%'>
                  <StyledImage url={imageUrl} height='100%' width='100%' />
                </CenterItems>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </StyledSwipe>
    </StyledContainer>
  )
}

const Indicators = styled(Box)`
  position: absolute;
  z-index: 15;
  padding-left: 0;
  text-align: center;
  list-style: none;
  margin-top: -30px;
`

const Ind = styled.li`
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  ${({ theme, active }) =>
    css({
      backgroundColor: active ? theme.colors.accent : theme.colors.baseGrayLight
    })};
  margin: 0 8px 10px 8px;
  cursor: pointer;
  border-radius: 50%;
`
const CenterItems = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledSwipe = styled(Swipe)(css(styles.swipeContainer))
const StyledContainer = styled(Box)(css(styles.container))

const StyledImage = styled(Box).attrs(({ url, width, height }) => ({
  style: {
    width,
    height,
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  }
}))({ backgroundSize: 'contain' })

const slideShape = PropTypes.shape({
  id: PropTypes.string,
  position: PropTypes.number,
  image: PropTypes.string,
  text: PropTypes.string
})

CarouselSlider.propTypes = {
  isSingle: PropTypes.bool,
  ratio: PropTypes.number,
  currentIndex: PropTypes.number,
  setCurrentIndex: PropTypes.func,
  carouselSlides: PropTypes.arrayOf(slideShape)
}

const AuthCarousel = ({
  initialIndex = 0,
  isSingle,
  ratio,
  autoAdvance = false,
  advanceTimeSeconds = 5
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex)

  const { carouselSlides, isCarouselLoading } = useCarouselSlides()

  useEffect(() => {
    let timer
    if (!isCarouselLoading && autoAdvance && carouselSlides.length > 0) {
      timer = setTimeout(() => {
        if (currentIndex === carouselSlides.length - 1) {
          setCurrentIndex(initialIndex)
          return
        }
        setCurrentIndex(currentIndex + 1)
      }, advanceTimeSeconds * 1000)
    }
    return () => clearTimeout(timer)
  }, [
    advanceTimeSeconds,
    autoAdvance,
    currentIndex,
    initialIndex,
    carouselSlides.length,
    isCarouselLoading
  ])

  if (isCarouselLoading || !carouselSlides.length) {
    return null
  }

  return (
    <CarouselWrapper
      mt={[3, 3, 0]}
      // ml={['unset', 'unset', 'auto']}
      mr={[0, 0, 0, '40px', '40px']}
      width={['90vw', '410px', '410px', '540px']}
      height={['520px', '380px']}
    >
      <CarouselSlider
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        isSingle={isSingle}
        isCarouselLoading={isCarouselLoading}
        carouselSlides={carouselSlides}
        ratio={ratio}
      />

      <Indicators width={['100%', '100%', '100%', '60%']}>
        {carouselSlides.map(({ id }, i) => (
          <Ind
            key={id}
            size={8}
            active={i === currentIndex}
            onClick={() => setCurrentIndex(i)}
          />
        ))}
      </Indicators>
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled(Card)`
  border-radius: 2px;
  position: relative;
`

AuthCarousel.propTypes = {
  isSingle: PropTypes.bool,
  initialIndex: PropTypes.number,
  ratio: PropTypes.number,
  autoAdvance: PropTypes.bool,
  advanceTimeSeconds: PropTypes.number
}

export default AuthCarousel
