import { BlockSkeleton, Flex, TextSkeleton } from '@etvas/etvaskit'

export const Skeleton = () => (
  <div>
    <TextSkeleton lines={1} width='35%' />
    <Flex justifyContent='center' width='100%' my={8}>
      <BlockSkeleton width='100%' height='375px' />
    </Flex>
    <TextSkeleton lines={10} />
  </div>
)
