import currentLocation from '@shared/funcs/currentLocation'
import isEmbedded from '@shared/funcs/isEmbedded'
import { memoryStorage } from '@shared/services/sso/memoryStorage'

const storageConfig = isEmbedded()
  ? { storage: memoryStorage }
  : {
      cookieStorage: {
        domain: currentLocation.isLocal
          ? 'localhost'
          : `.${currentLocation?.domain}`,
        path: '/',
        expires: 365,
        samesite: 'none',
        secure: !currentLocation.isLocal
      }
    }

export const awsConfig = {
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    region: process.env.REACT_APP_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID
  },
  ...storageConfig,
  /* eslint-disable camelcase */
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_GRAPHQL_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_GRAPHQL_AUTHENTICATION_TYPE,
  API: {
    graphql_headers: async () => ({})
  },
  Analytics: {
    disabled: true
  }
}
