import { useContext } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import PropTypes from 'prop-types'

import { Box, Button, Flex, Form, TextField, Typography } from '@etvas/etvaskit'

import { userApi } from '@shared/funcs'
import { I18nContext, T } from '@shared/i18n'

export const RequireNewPassword = ({ user, retryLogin }) => {
  const { organizationId } = useContext(OrganizationContext)
  const { translate } = useContext(I18nContext)

  const _changePass = async ({ password }, { setSubmitting, setStatus }) => {
    try {
      await userApi.completeNewPassword(user, password, {
        bpId: organizationId
      })
      retryLogin()
    } catch (error) {
      switch (error.code) {
        case 'InvalidPasswordException':
          setStatus(<T label='text.passwordNotStrong' />)
          break

        default:
          setStatus(<T label='text.errorChangingPassword' />)
          break
      }
      setSubmitting(false)
    }
  }

  return (
    <>
      <Typography variant='textSmall'>
        <T label='text.passwordRequiredBeforeContinuing' />
      </Typography>
      <Form
        initialValues={{
          password: '',
          passwordConfirmation: ''
        }}
        validate={_handleValidation}
        onSubmit={_changePass}
      >
        {({ isSubmitting, status }) => (
          <Flex
            mt={[4, 6]}
            flexDirection='column'
            alignItems='center'
            justifyContent='flex-start'
          >
            {status && (
              <Typography
                variant='labelSmall'
                color='error'
                textAlign='center'
                mt={[4, 6]}
              >
                {status}
              </Typography>
            )}
            <TextField
              type='password'
              name='password'
              id='password'
              placeholder={translate('label.newPassword')}
              required
            />
            <TextField
              type='password'
              name='passwordConfirmation'
              id='passwordConfirmation'
              placeholder={translate('label.confirmNewPassword')}
              required
            />
            <Box mt={4} mx='auto'>
              <Button
                type='submit'
                variant='primary'
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {<T label='label.changePassword' />}
              </Button>
            </Box>
          </Flex>
        )}
      </Form>
    </>
  )
}

const _handleValidation = ({ password, passwordConfirmation }) => {
  let errors = {}

  if (!password) {
    errors.password = <T label='label.required' />
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = <T label='label.required' />
  }

  if (
    !errors.password &&
    !errors.passwordConfirmation &&
    passwordConfirmation !== password
  ) {
    errors.passwordConfirmation = <T label='text.passwordMismatch' />
  }

  return errors
}

RequireNewPassword.propTypes = {
  user: PropTypes.object,
  retryLogin: PropTypes.func
}
