import {
  LocalStorage, //, CookieStorage,
  //, MemoryStorage,
  SessionStorage
} from '@shared/store'

export const TOKEN_NAME_LANG = 'etv_locale'

const _config = {
  // methodName: { token: 'the_storage_token_name', storage: SessionStorage | LocalStorage | CookieStorage | MemoryStorage }
  language: { token: TOKEN_NAME_LANG, storage: SessionStorage },
  organizationId: { token: 'etv_oid', storage: SessionStorage },
  skipTracking: { token: 'etv_skip_tracking', storage: SessionStorage },
  didChangeLanguage: { token: 'etv_locale_change', storage: SessionStorage },
  authSavedUser: { token: 'etv_land_auth_user', storage: SessionStorage },
  hideCookieBanner: { token: 'etv_cookie_banner', storage: LocalStorage },
  deviceWasLoggedIn: { token: 'etv_device_logged', storage: LocalStorage }
}

const _handler = {
  get: (target, prop) => {
    const _h = target[prop]
    if (!_h) {
      // console.error('* Unknown storage property to get', prop)
      return undefined
    }
    const value = _h.storage.getItem(_h.token)
    return value ? JSON.parse(value) : value
  },
  set: (target, prop, value) => {
    const _h = target[prop]
    if (!_h) {
      console.error('Unknown storage property to set', prop)
      return false
    }
    if (value === undefined || value === null) {
      _h.storage.removeItem(_h.token)
      return true
    }
    _h.storage.setItem(_h.token, JSON.stringify(value))
    return true
  }
}

const storage = new Proxy(_config, _handler)

export default storage
