import { useContext, useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Flex, Form, TextField, Typography } from '@etvas/etvaskit'

import { storage, userApi } from '@shared/funcs'
import loginWithTrace from '@shared/funcs/loginWithTrace'
import { I18nContext, T } from '@shared/i18n'

export const ConfirmSMSCode = ({ userData, user, handleAuthStateChange }) => {
  const [updatedCognitoUser, setUpdatedCognitoUser] = useState()
  const phoneNumber = user?.challengeParam?.CODE_DELIVERY_DESTINATION

  const { translate } = useContext(I18nContext)
  const verficationCodeRef = useRef()
  const [isResendingCode, setIsResendingCode] = useState(false)
  const initialValues = {
    code: ''
  }

  const _confirmSMSCode = async ({ code }, { setSubmitting, setStatus }) => {
    try {
      setStatus()
      await userApi.confirmSignIn(updatedCognitoUser || user, code, 'SMS_MFA')

      storage.authSavedUser = null
    } catch (error) {
      switch (error.code) {
        case 'CodeMismatchException':
          setStatus(<T label='label.smsCodeNotValid' />)
          setSubmitting(false)
          break

        case 'NotAuthorizedException':
          // e.g. {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds."}
          // e.g. {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Invalid session for the user, session is expired."}
          handleAuthStateChange('checkEmail')
          break

        default:
          setStatus(<T label='text.verifySmsCodeError' />)
          console.warn(
            '* Unexpected error caught while confirming SMS code',
            error
          )
          setSubmitting(false)
      }
    }
  }

  const resendCode = async () => {
    setIsResendingCode(true)
    try {
      if (!userData.email || !userData.pw) {
        handleAuthStateChange('checkEmail')
      }

      const user = await loginWithTrace(userData.email, userData.pw)

      setUpdatedCognitoUser(user)
    } catch (error) {
      console.error(' * Error while resending the MFA code', error)
    } finally {
      setIsResendingCode(false)
    }
  }

  useEffect(() => {
    if (verficationCodeRef.current) {
      verficationCodeRef.current.focus()
    }
  }, [])

  return (
    <>
      <Box mb={4}>
        <Typography variant='base14Light'>
          <T label='text.verifySigninCode' />
        </Typography>
        <Typography variant='base14Light'>
          <T label='text.enterSigninCodeSentTo' args={[phoneNumber]} />
        </Typography>
      </Box>

      <Form
        initialValues={initialValues}
        validate={handleCodeValidation}
        onSubmit={_confirmSMSCode}
      >
        {({ isSubmitting, status }) => (
          <Flex
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
          >
            <TextField
              name='code'
              id='code'
              label={<T label='label.verificationCode' />}
              autoComplete='off'
              inputMode='numeric'
              placeholder={translate('label.6digitCode')}
              ref={verficationCodeRef}
              required
            />

            {status && (
              <Typography
                variant='base12Light'
                color='statusError'
                textAlign='center'
                mt={2}
                mb={4}
              >
                {status}
              </Typography>
            )}

            <Button
              variant='link'
              onClick={resendCode}
              mt={2}
              mb={5}
              disabled={isSubmitting || isResendingCode}
            >
              <T label='label.resendCode' />
            </Button>

            <Button
              variant='primary'
              mx='auto'
              type='submit'
              disabled={isSubmitting || isResendingCode}
              loading={isSubmitting || isResendingCode}
            >
              <T label='label.validateCode' />
            </Button>
          </Flex>
        )}
      </Form>
    </>
  )
}

const handleCodeValidation = ({ code }) => {
  let errors = {}

  if (!code) {
    errors.code = <T label='label.required' />
  } else if (!/^\d+$/.test(code) || code.length !== 6) {
    errors.code = <T label='label.verificationCodeDigits' />
  }
  return errors
}

ConfirmSMSCode.propTypes = {
  userData: PropTypes.object,
  user: PropTypes.object,
  handleAuthStateChange: PropTypes.func
}
