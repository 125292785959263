import { breakpoints } from '@assets/styles/_utils'
import PropTypes from 'prop-types'

import { Card, Modal, Typography } from '@etvas/etvaskit'

import { usePostHeight } from 'app/screens/embedded/hooks/usePostHeight'

import { isEmbedded } from '@shared/funcs'
import { useMatchWidth } from '@shared/hooks'

const ModalDialog = ({
  visible,
  animated,
  title,
  onBackDropClick,
  onEscape,
  onClose,
  onDismiss,
  tinted,
  children,
  width,
  showCloseButton,
  ...props
}) => {
  usePostHeight()

  const isMobile = !useMatchWidth(breakpoints.sm)
  if (!visible) {
    return null
  }
  const noOp = () => {}
  let handleBackDropClick = isMobile ? noOp : onBackDropClick
  let handleEscape = onEscape
  let handleClose = onClose

  if (onDismiss) {
    handleBackDropClick =
      handleBackDropClick === undefined
        ? onDismiss
        : handleBackDropClick || noOp
    handleEscape = handleEscape === undefined ? onDismiss : handleEscape || noOp
    handleClose = handleClose === undefined ? onDismiss : handleClose || noOp
  }

  const handleCloseButton = showCloseButton ? handleClose : null

  return (
    <Modal
      animated={animated}
      onBackDropClick={handleBackDropClick}
      onEscape={handleEscape}
      backDrop='modalBackdropColor'
      blurredBg={isEmbedded()}>
      <Modal.Content
        onClose={handleCloseButton}
        width={width}
        id='etvas-modal-content'>
        <Card bg='backgroundGray' variant='popup' {...props}>
          {title ? (
            <Typography variant='titleLarge' fontWeight='bold' mb={2}>
              {title}
            </Typography>
          ) : null}
          {children}
        </Card>
      </Modal.Content>
    </Modal>
  )
}

ModalDialog.propTypes = {
  visible: PropTypes.bool,
  animated: PropTypes.bool,
  title: PropTypes.node,
  onBackDropClick: PropTypes.func,
  onEscape: PropTypes.func,
  onClose: PropTypes.func,
  onDismiss: PropTypes.func,
  tinted: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  children: PropTypes.node
}

ModalDialog.defaultProps = {
  tinted: true,
  visible: true,
  showCloseButton: true
}

export default ModalDialog
