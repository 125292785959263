import { buildDeepLinkURL } from './buildDeepLinkURL'
import { getCurrentLocation } from './currentLocation'
import slugify from './slugify'

export const handlePurchaseRedirectURL = (redirectURL, product) => {
  const { full } = getCurrentLocation()

  const productURL = `${full}/products/${slugify(product.name)}/${product.id}`

  const url = buildDeepLinkURL(redirectURL, {
    productId: product.id,
    openURL: `${productURL}?purchaseIntent=1`
  })

  window.open(url, '_self')
}
