import { useQuery } from '@apollo/client'

import { GET_PURCHASED_PRODUCTS } from './graphql/queries'

const queryConfig = {
  fetchPolicy: 'cache-and-network',
  nextFetchPolicy: 'cache-first',
  partialRefetch: true
}

export const usePurchasedProducts = () => {
  const { data, loading } = useQuery(GET_PURCHASED_PRODUCTS, queryConfig)
  return { products: data?.listMyProducts ?? [], isLoadingProducts: loading }
}
