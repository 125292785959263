import PropTypes from 'prop-types'

import {
  BlockSkeleton,
  Box,
  Flex,
  TextSkeleton,
  Typography
} from '@etvas/etvaskit'

const AccountAvatar = ({ user, color, isLoadingUserProfile, ...props }) => {
  const fullName = `${user?.firstName} ${user?.lastName}`

  return (
    <Flex flexDirection='row' alignItems='center' {...props}>
      {isLoadingUserProfile ? (
        <>
          <BlockSkeleton width='32px' height='32px' mr={4} />
          <Flex flexGrow={1}>
            <TextSkeleton height='50%' width='100%' lines={1} />
          </Flex>
        </>
      ) : (
        <>
          <Box width='2rem' height='2rem' mr={2}>
            <img
              alt='account placeholder'
              src='/account-placeholder.png'
              height='100%'
            />
          </Box>
          <Typography color={color} variant='textLarge' fontWeight='bold'>
            {fullName}
          </Typography>
        </>
      )}
    </Flex>
  )
}

AccountAvatar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  color: PropTypes.string,
  isLoadingUserProfile: PropTypes.bool
}

export default AccountAvatar
