import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { extractErrorsFromException } from '@shared/funcs'

import {
  SUBSCRIBE_TO_WAITING_LIST,
  UNSUBSCRIBE_FROM_WAITING_LIST
} from './graphql/mutations'

export const useWaitingList = (name, id) => {
  const [
    subscribeToWaitingList,
    { loading: subscribeLoading, error: subscribeError }
  ] = useMutation(SUBSCRIBE_TO_WAITING_LIST)
  const [
    unsubscribeFromWaitingList,
    { loading: unsubscribeLoading, error: unsubscribeError }
  ] = useMutation(UNSUBSCRIBE_FROM_WAITING_LIST)

  const _subscribeToWaitingList = useCallback(async () => {
    try {
      const response = await subscribeToWaitingList({
        variables: { input: { name, id } }
      })
      return response.subscribeToWaitingList
    } catch (err) {
      console.error(
        ` * Error while trying to subscribe to the waiting list for ${name}`,
        err
      )
      return extractErrorsFromException(err)
    }
  }, [id, name, subscribeToWaitingList])

  const _unsubscribeFromWaitingList = useCallback(async () => {
    try {
      const response = await unsubscribeFromWaitingList({
        variables: { input: { name, id } }
      })
      return response.unsubscribeFromWaitingList
    } catch (err) {
      console.error(
        ` * Error while trying to unsubscribe from the waiting list for ${name}`,
        err
      )
      return extractErrorsFromException(err)
    }
  }, [id, name, unsubscribeFromWaitingList])

  return {
    subscribeToWaitingList: _subscribeToWaitingList,
    unsubscribeFromWaitingList: _unsubscribeFromWaitingList,
    loading: subscribeLoading || unsubscribeLoading,
    error: subscribeError || unsubscribeError
  }
}
