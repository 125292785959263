import { useHistory, useParams } from 'react-router-dom'

import { InterCom } from '@etvas/etvaskit'

export const useIntercom = () => {
  const intercom = new InterCom()

  const { productId, slug } = useParams()
  const history = useHistory()

  intercom.onRequest('openDetailsPage', queryParams => {
    const searchParams = new URLSearchParams()

    for (const key in queryParams) {
      searchParams.append(key, queryParams[key])
    }

    history.push(`/products/${slug}/${productId}?${searchParams.toString()}`)
  })
}
