import moment from 'moment'

import { I18nService, buildFormatPrice } from '@etvas/i18n'

import { TOKEN_NAME_LANG } from '@shared/funcs/storage'
import { SessionStorage } from '@shared/store/index'

import languages from './languages'

export { T, I18nContext } from '@etvas/i18n'

const DEFAULT_LANGUAGE = 'en'

export const i18nService = new I18nService(languages, {
  defaultLanguage: DEFAULT_LANGUAGE,
  sessionKey: TOKEN_NAME_LANG,
  storage: SessionStorage,
  dictionaryUrl: '/static/i18n/{lang}.json',
  fallbackToKey: true
})

export const setLanguage = lang => {
  i18nService.setSessionLanguage(lang)
  moment.locale(lang)
}

export const getLanguage = () => i18nService.language

export const formatPrice = buildFormatPrice(i18nService)

export const loadI18n = async () => {
  moment.locale(DEFAULT_LANGUAGE)

  await Promise.all(
    languages.map(async language => {
      i18nService.loadDictionary(language.id, language.dictionary)
    })
  )
}
