import { useContext } from 'react'

import { AuthContext } from '@services/AuthProvider'
import { Route, Switch } from 'react-router-dom'

import { AppLayout } from '@shared/components/AppLayout'

import {
  LoginTermsAndConditions,
  PrivacyPolicy,
  ProductTermsAndConditions,
  CashbackTermsAndConditions,
  CashbackPrivacyPolicy,
  CashbackDataProcessing
} from '../screens'

export const tosRoutes = [
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/termsandconditions', component: LoginTermsAndConditions },
  { path: '/terms-and-conditions', component: LoginTermsAndConditions },
  {
    path: '/products/:slug/:productId/terms-and-conditions',
    component: ProductTermsAndConditions
  },
  {
    path: '/terms-and-conditions/:productId',
    component: ProductTermsAndConditions
  },
  {
    path: '/cashback/terms-and-conditions',
    component: CashbackTermsAndConditions
  },
  {
    path: '/cashback/privacy-policy',
    component: CashbackPrivacyPolicy
  },
  {
    path: '/cashback/data-processing',
    component: CashbackDataProcessing
  }
]

const TermsSwitch = () => {
  const { authenticated } = useContext(AuthContext)

  const routes = tosRoutes.map(route => (
    <Route
      key={route.path}
      component={route.component}
      path={route.path}
      exact
    />
  ))
  return (
    <Switch>{authenticated ? <AppLayout>{routes}</AppLayout> : routes}</Switch>
  )
}

export default TermsSwitch
