import currentLocation from './currentLocation'

export const redirectIfNeeded = (strip, forceHttps) => {
  if (
    currentLocation.subdomain !== strip ||
    currentLocation.segments.length <= 1
  ) {
    return false
  }
  const newHost = currentLocation.segments.slice(1).join('.')
  const { pathname, search, hash } = currentLocation
  const protocol = forceHttps ? 'https:' : currentLocation.protocol
  window.location.replace(`${protocol}//${newHost}${pathname}${search}${hash}`)
  return true
}
