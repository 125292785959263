import { awsConfig } from '@config/aws.config'
import {
  CognitoUser,
  CognitoUserPool,
  CookieStorage
} from 'amazon-cognito-identity-js'

import { memoryStorage } from '@shared/services/sso/memoryStorage'

export const getCognitoUserFromObject = obj => {
  const Pool = new CognitoUserPool({
    ClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    UserPoolId: process.env.REACT_APP_USER_POOL_ID
  })

  const Storage = !awsConfig.cookieStorage
    ? memoryStorage
    : new CookieStorage(awsConfig.cookieStorage)

  const user = new CognitoUser({
    Username: obj?.Username,
    Pool,
    Storage
  })

  user.Session = obj?.Session
  user.challengeParam = obj?.challengeParam
  user.challengeName = obj?.challengeName

  return user
}
