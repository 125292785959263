import moment from 'moment'

import { Box, Button } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { GridMainComponent, TruncateGridInfo } from '@shared/components'
import { formatPrice } from '@shared/funcs'

export const getPaymentHistoryGrid = () => ({
  name: 'payment-history',
  columns: [
    {
      name: 'name',
      header: <T label='label.productName' />,
      render: item => (
        <Box pl={4} pr={2} width='100%'>
          <GridMainComponent icon='receipt'>
            {item.productName}
          </GridMainComponent>
        </Box>
      ),
      sort: 'name',
      width: '2fr'
    },
    {
      name: 'price',
      header: <T label='label.price' />,
      attribute: item => formatPrice(item.price, { currency: item.currency }),
      width: '1fr'
    },
    {
      name: 'date',
      header: <T label='label.paymentDate' />,
      attribute: item => moment(item.paidAt).format('D MMM YYYY'),
      width: '1fr'
    },
    {
      name: 'status',
      header: <T label='label.status' />,
      render: item => (
        <TruncateGridInfo witdh='100%'>
          <T label={i18nStatusLabels[item.displayStatus]} />
        </TruncateGridInfo>
      ),
      width: '1fr'
    },
    {
      name: 'chargeType',
      header: <T label='title.paymentMethod' />,
      render: item => (
        <TruncateGridInfo witdh='100%'>
          {i18nPMLabels[item.chargeType] ? (
            <T label={i18nPMLabels[item.chargeType]} />
          ) : (
            '-'
          )}
        </TruncateGridInfo>
      ),
      width: '1fr'
    },
    {
      name: 'invoice',
      // align:'center',
      header: <T label='label.invoiceLink' />,
      render: item => (
        <Button as='a' variant='link' download href={item.pdfURL}>
          <T label='label.download' />
        </Button>
      ),
      width: '1.3fr'
    }
  ]
})

const i18nStatusLabels = {
  Unknown: 'label.unknown',
  Processing: 'label.processing',
  Paid: 'label.paid',
  Failed: 'label.failed',
  Voided: 'label.voided'
}

const i18nPMLabels = {
  sepa: 'label.directDebit',
  card: 'label.creditCard'
}
