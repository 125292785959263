import { useCallback, useContext, useMemo } from 'react'

import { AuthContext } from '@services/AuthProvider'
import { useTracking } from '@services/hooks/useTracking'
import PropTypes from 'prop-types'

import { Box, Button } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import {
  getQueryParam,
  handlePurchaseRedirectURL,
  isEmbedded,
  isLandingPage,
  sendWindowMessage
} from '@shared/funcs'
import { productShape, purchaseStatus } from '@shared/models'
import { usePurchaseModals } from '@shared/services/usePurchaseModals'

import { PriceOption } from './PriceOption'

export const PurchaseButton = ({
  product,
  showEmbeddedUse,
  showEmbeddedPurchase,
  isHero,
  initPurchase,
  ...props
}) => {
  const { authenticated } = useContext(AuthContext)
  const { PurchaseModal, modalProps } = usePurchaseModals({
    product,
    showEmbeddedUse,
    initPurchase
  })

  const { track } = useTracking()

  const isPurchased = product.purchaseStatus === purchaseStatus.Purchased
  const isSuspended = purchaseStatus.canBeReactivated(product.purchaseStatus)
  const opensInNewTab = isPurchased && !!product.appInNewTab

  const _handleButtonClick = useCallback(() => {
    const prefix = isLandingPage() ? 'landing.' : ''

    track({
      name: isPurchased
        ? `${prefix}product.use`
        : isSuspended
          ? `${prefix}reactivate.start`
          : `${prefix}purchase.start`,
      value: product?.id
    })

    const redirectPurchaseURL = getQueryParam('redirectPurchaseURL')

    if (redirectPurchaseURL) {
      return handlePurchaseRedirectURL(redirectPurchaseURL, product)
    }

    const sendPurchaseMessage = () =>
      sendWindowMessage('on-product-purchase', {
        productId: product.id,
        connected: authenticated
      })

    if (opensInNewTab) {
      return true
    }

    return isPurchased
      ? modalProps.handleUse()
      : showEmbeddedPurchase
        ? sendPurchaseMessage()
        : modalProps.handleBillingInfo()
  }, [
    track,
    isPurchased,
    isSuspended,
    product,
    opensInNewTab,
    modalProps,
    showEmbeddedPurchase,
    authenticated
  ])

  const buttonContent = useMemo(() => {
    const isFree = product.price === 0

    if (isSuspended) {
      return { label: 'label.reactivate', withPrice: isHero }
    }

    if (isHero) {
      if (isPurchased) {
        return { label: 'label.useProduct' }
      }

      return isFree
        ? { label: 'label.getForFree' }
        : { label: 'label.purchaseFor', withPrice: true }
    }

    if (isPurchased) {
      return { label: 'label.use' }
    }

    return isFree ? { label: 'label.get' } : { label: 'label.purchase' }
  }, [isHero, isPurchased, isSuspended, product])

  const buttonBehavior = opensInNewTab
    ? {
        as: 'a',
        href: isEmbedded()
          ? product.appURL
          : `/products/product/${product.id}/use`,
        target: '_blank',
        type: ''
      }
    : {
        type: 'button'
      }

  return (
    <Box {...props}>
      <Button
        variant={isPurchased ? 'positive' : 'primary'}
        onClick={_handleButtonClick}
        width={1}
        {...buttonBehavior}>
        <>
          <T label={buttonContent.label} />
          {buttonContent.withPrice && (
            <>
              {' '}
              <PriceOption product={product} />
            </>
          )}
        </>
      </Button>

      <PurchaseModal {...modalProps} />
    </Box>
  )
}

PurchaseButton.propTypes = {
  product: productShape.isRequired,
  showEmbeddedUse: PropTypes.bool,
  showEmbeddedPurchase: PropTypes.bool,
  isHero: PropTypes.bool,
  initPurchase: PropTypes.bool
}

PurchaseButton.defaultProps = {
  showEmbeddedUse: false,
  showEmbeddedPurchase: false,
  isHero: false,
  initPurchase: false
}
