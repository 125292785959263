import { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Flex,
  Form,
  Grid,
  TextField,
  Typography
} from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

const PaymentCouponEditor = ({ onAttach, isBusy, ...props }) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const _handleSubmit = async ({ code }) => {
    setErrorMessage(null)
    const couponCode = code.trim().toUpperCase()
    const { success, error } = await onAttach(couponCode)
    if (!success) {
      error.fieldErrors?.failedRule === 'used'
        ? setErrorMessage('text.couponAlreadyUsed')
        : setErrorMessage('text.couponInvalid')
    }
  }

  return (
    <T>
      {({ translate }) => (
        <Form
          initialValues={{ code: '' }}
          validate={() => ({})}
          onSubmit={_handleSubmit}
        >
          {({ values, dirty }) => (
            <Grid cols={1}>
              <Grid.Item>
                <Box {...props}>
                  <TextField
                    name='code'
                    id='code'
                    label={translate('label.couponCode')}
                    placeholder={translate('label.coupon')}
                    disabled={isBusy}
                    required
                  />

                  <Flex justifyContent='flex-end' alignItems='center' pt={4}>
                    {errorMessage && (
                      <Typography variant='textSmall' color='error' mr={2}>
                        {translate(errorMessage)}
                      </Typography>
                    )}
                    <Button
                      variant='link'
                      width={{ _: '100%', md: 'auto' }}
                      ml={1}
                      type='submit'
                      loading={isBusy}
                      disabled={isBusy || !dirty || !values.code.trim().length}
                    >
                      <T label='label.saveChanges' />
                    </Button>
                  </Flex>
                </Box>
              </Grid.Item>
            </Grid>
          )}
        </Form>
      )}
    </T>
  )
}

PaymentCouponEditor.propTypes = {
  onAttach: PropTypes.func,
  isBusy: PropTypes.bool
}

export default PaymentCouponEditor
