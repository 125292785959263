import gql from 'graphql-tag'

export const GET_PRODUCT_APP_INFO = gql`
  query getProductDetails($input: IDInput!) {
    getProductDetails(input: $input) {
      id
      appURL
      name
      suspendedAt
      servicePartner {
        id
      }
      appInNewTab
      purchaseId
      purchaseStatus
      __typename
    }
  }
`
