import { useEffect } from 'react'

import { useTermsPage } from '@services/hooks/useTermsPage'

import { ActivityIndicator, Box, Flex, Typography } from '@etvas/etvaskit'

import { getQueryParam } from '@shared/funcs'
import { T, setLanguage } from '@shared/i18n'

const TermsAndConditions = ({ ...props }) => {
  useEffect(() => {
    const lang = getQueryParam('lang')
    setLanguage(lang ?? 'en')
  }, [])

  const { isLoading: isLoadingTAndC, page: tAndC } =
    useTermsPage('termsAndConditions')

  if (isLoadingTAndC) {
    return (
      <ActivityIndicator
        variant='runningbar'
        colors={{ background: 'transparent', primary: 'brand' }}
      />
    )
  }
  const { html } = tAndC

  return (
    <Flex {...props} justifyContent='center' flexDirection='column'>
      <Typography variant='title32Light' mb={[3, 6]}>
        <T label='title.termsAndConditions' />
      </Typography>
      <Box px={2}>
        <Typography>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Typography>
      </Box>
    </Flex>
  )
}

export default TermsAndConditions
