import { useContext } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Form,
  Space,
  TextField,
  Typography
} from '@etvas/etvaskit'

import { I18nContext, T } from '@shared/i18n'
import { isPasswordWeak } from '@shared/validators'

export const ChangeForgottenPasswordForm = ({
  email,
  changeForgottenPassword
}) => {
  const { translate } = useContext(I18nContext)
  const initialValues = {
    email,
    newPassword: '',
    verificationCode: ''
  }

  return (
    <>
      <Space mb={4}>
        <Typography variant='base14Light'>
          <T label='text.insertVerificationCode' />
        </Typography>
      </Space>

      <Form
        initialValues={initialValues}
        validate={_handleValidation}
        onSubmit={changeForgottenPassword}
      >
        {({ isSubmitting, status }) => (
          <>
            <Box mb={4}>
              <TextField
                name='email'
                label={<T label='label.email' />}
                placeholder={translate('label.email')}
                disabled
                required
              />
              <TextField
                name='verificationCode'
                label={<T label='label.verificationCode' />}
                placeholder={translate('label.6digitCode')}
                autoComplete='off'
                required
              />
              <TextField
                type='password'
                passwordView='toggle'
                name='newPassword'
                label={<T label='label.newPassword' />}
                placeholder={translate('label.newPassword')}
                required
              />
            </Box>
            {status && (
              <Typography
                variant='base12Light'
                color='statusError'
                textAlign='center'
                mt={2}
                mb={4}
              >
                {status}
              </Typography>
            )}
            <Button
              variant='primary'
              type='submit'
              disabled={isSubmitting}
              loading={isSubmitting}
              mx='auto'
            >
              <T label='label.resetPassword' />
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

const _handleValidation = ({ verificationCode, newPassword }) => {
  let errors = {}

  if (!verificationCode) {
    errors.verificationCode = <T label='label.required' />
  } else if (!/^\d+$/.test(verificationCode) || verificationCode.length !== 6) {
    errors.verificationCode = <T label='label.verificationCodeDigits' />
  }

  if (!newPassword) {
    errors.newPassword = <T label='label.required' />
  } else if (isPasswordWeak(newPassword)) {
    errors.newPassword = <T label='text.passwordNotStrong' />
  }

  return errors
}

ChangeForgottenPasswordForm.propTypes = {
  email: PropTypes.string,
  changeForgottenPassword: PropTypes.func
}
