import { useContext } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { BlockSkeleton, BrandingContext, Image } from '@etvas/etvaskit'

export const LogoImage = ({ size }) => {
  const { cssVars } = useContext(BrandingContext)

  const { organization } = useContext(OrganizationContext)

  if (!organization) {
    return <BlockSkeleton width='100%' height='100%' />
  }

  const url = size === 'small' ? cssVars.logoSmall : cssVars.logo
  return <StyledImage src={url} alt={organization.name} />
}

LogoImage.propTypes = {
  size: PropTypes.oneOf(['small', 'regular'])
}

LogoImage.defaultProps = {
  size: 'regular'
}

export const Logo = () => (
  <StyledLink to='/'>
    <LogoImage />
  </StyledLink>
)

const StyledImage = styled(Image)({
  display: 'block',
  margin: 0,
  maxWidth: '100%',
  maxHeight: '100%',
  width: 'auto',
  height: 'auto'
})

const StyledLink = styled(Link)(
  css({
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center'
  })
)
