import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

export const QueryPreserverLink = ({ children, to, ...props }) => {
  const { search } = useLocation()

  return (
    <Link to={`${to}${search}`} {...props}>
      {children}
    </Link>
  )
}

QueryPreserverLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
}
