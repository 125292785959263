import { useParams } from 'react-router'

import { Box } from '@etvas/etvaskit'

import { BlogSection, NavBack, NotFound, Section } from '@shared/components'
import { getQueryParam } from '@shared/funcs'
import { T } from '@shared/i18n'

import ProductInfo from './components/ProductInfo'
import { useProductDetails } from './services/useProductDetails'

const ProductDetails = () => {
  const { productId } = useParams()
  const shouldInitPurchase = getQueryParam('purchaseIntent') === '1'

  const { isLoadingProduct, isLoadingArticles, product, articles } =
    useProductDetails(productId)

  if (!isLoadingProduct && !product) {
    return <NotFound />
  }

  return (
    <Box pb={6}>
      <NavBack to='/'>{product?.name || ''}</NavBack>
      <Section>
        <ProductInfo
          product={product}
          isFetching={isLoadingProduct}
          hideRating={true}
          initPurchase={shouldInitPurchase}
        />
      </Section>
      {articles?.length ? (
        <Section title={<T label='label.articles' />}>
          <BlogSection articles={articles} isFetching={isLoadingArticles} />
        </Section>
      ) : null}
    </Box>
  )
}

export default ProductDetails
