import { Countries } from '@etvas/etvaskit'

const rawCountries = Countries.asArray(country => ({
  value: country.code,
  label: country.native
    ? `${country.short || country.full} (${country.native})`
    : country.full,
  isEU: country.isEU
}))

const orderedCountries = rawCountries.sort((a, b) =>
  a.isEU && b.isEU ? a.label.localeCompare(b.label) : a.isEU ? -1 : 1
)

export const countryCodes = orderedCountries

export const currentCountryCode = Countries.fromTimezone(
  new Intl.DateTimeFormat().resolvedOptions().timeZone
)

export const getCountryLabel = countryCode =>
  orderedCountries.find(c => c.value === countryCode)?.label

export const countryPrefixesCodes = Countries.asArray(country => ({
  code: country.code,
  prefix: country.prefix
}))
