const _decode = [
  { search: /\./g, replace: '+' },
  { search: /_/g, replace: '/' },
  { search: /-/g, replace: '=' }
]

export const safeUrlDecodeBase64 = b64 =>
  window.atob(
    _decode.reduce(
      (result, item) => result.replace(item.search, item.replace),
      b64
    )
  )
