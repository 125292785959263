import { addIcons, etvasTheme } from '@etvas/etvaskit'

import customIcons from './icons'
import AUTHENTICATION_TEMPLATE_STYLE from './styles/components/authentication-template'
import CREDIT_CARD_STYLE from './styles/components/credit-card'
import { GRID, GRID_DOT } from './styles/components/grid'

addIcons(customIcons)

const breakpoints = ['30rem', '48rem', '64rem']
const [sm, md, lg] = breakpoints
breakpoints.sm = sm
breakpoints.md = md
breakpoints.lg = lg

const customColors = {
  modalBackdropColor: 'rgba(32,37,52, 0.92)'
}
const customShadows = {
  actionButtonsShadow:
    '0px 0px 1px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.2)'
}

const theme = Object.seal({
  CreditCard: CREDIT_CARD_STYLE,
  AuthenticationTemplate: AUTHENTICATION_TEMPLATE_STYLE,
  breakpoints,
  Grid: GRID,
  GridDot: GRID_DOT,
  colors: {
    ...etvasTheme.colors,
    ...customColors
  },
  shadows: {
    ...etvasTheme.shadows,
    ...customShadows
  }
})

export default theme
