import { useContext } from 'react'

import { PropTypes } from 'prop-types'

import { Typography } from '@etvas/etvaskit'

import { I18nContext, getLanguage } from '@shared/i18n'

export const CustomerLinks = ({ label, ...props }) => {
  const { translate } = useContext(I18nContext)

  const language = getLanguage()

  const links = [
    `<a href='/terms-and-conditions?lang=${language}'
    rel='noopener noreferrer'
    target='_blank'>${translate('label.termsAndConditions')}</a>`,
    `<a href='/privacy-policy?lang=${language}'
    rel='noopener noreferrer'
    target='_blank'>${translate('label.privacyPolicy')}</a>`
  ]

  return (
    <Typography
      variant='base14Light'
      dangerouslySetInnerHTML={{
        __html: translate(label, links)
      }}
      {...props}
    />
  )
}

CustomerLinks.propTypes = {
  label: PropTypes.string
}
