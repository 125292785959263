import gql from 'graphql-tag'

export const AVAILABLE_PRODUCT_FRAGMENT = gql`
  fragment availableProduct on Product {
    id
    name
    summary
    description
    price
    currency
    infoURL
    appInNewTab
    appURL
    imageURL
    mediaURLs
    subscriptionMonths
    subscriptionType
    servicePartner {
      id
      name
      logo
      logoSmall
      hideProductBranding
    }
    trialPeriod
    trialUnit
    trialEndsAt
    taxName
    taxRate
  }
`

export const PRODUCT_PURCHASE_STATUS_FRAGMENT = gql`
  fragment productPurchaseStatus on Product {
    purchaseId
    purchaseStatus
    allowRepurchase
  }
`

export const BLOG_ITEM_WITHOUT_CONTENT_FRAGMENT = gql`
  fragment blogItemWithoutContent on BlogItem {
    id
    title
    excerpt
    featuredImageURL
  }
`

export const PURCHASED_PRODUCT_FRAGMENT = gql`
  fragment PurchasedProductsDetails on PurchasedProduct {
    id
    purchaseId
    isDemo
    purchaseStatus
    name
    price
    currency
    validUntil
    subscriptionType
    subscriptionMonths
    cancelsAt
    trialEndsAt
  }
`
