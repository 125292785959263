import { useContext } from 'react'

import { useParams } from 'react-router'

import { ActivityIndicator, Box } from '@etvas/etvaskit'

import { Markdown, NavBack, NotFound } from '@shared/components'
import { T } from '@shared/i18n'

import { AuthContext } from '../../services/AuthProvider'
import { useProductTOS } from './services/useProductTOS'

const ProductTermsAndConditions = props => {
  const { authenticated } = useContext(AuthContext)
  const { productId } = useParams()

  const { isLoadingProductTOS, productTOS } = useProductTOS(productId)

  if (isLoadingProductTOS) {
    return (
      <ActivityIndicator
        variant='runningbar'
        colors={{ background: 'transparent', primary: 'brand' }}
      />
    )
  }

  if (!productTOS) {
    return <NotFound />
  }

  const { termsAndConditions } = productTOS
  return (
    <Box {...props}>
      {authenticated && (
        <NavBack>
          <T label='title.termsAndConditions' />
        </NavBack>
      )}

      <Box px={2}>
        <Markdown as='article' variant='textSmall' md={termsAndConditions} />
      </Box>
    </Box>
  )
}

export default ProductTermsAndConditions
