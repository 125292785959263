import { ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client'
import { ApolloClient, defaultDataIdFromObject } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { awsConfig } from '@config/aws.config'
import { Amplify } from 'aws-amplify'
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import { getOfferId, isLandingPage, storage, userApi } from '@shared/funcs'
import { getLanguage } from '@shared/i18n'

import { mergePaginatedItems } from '../utils/mergePaginatedItems'

const getRealm = () => (isLandingPage() ? 'landing' : 'portal')

Amplify.configure(awsConfig)

const appSyncAuthConfig = {
  url: awsConfig.aws_appsync_graphqlEndpoint,
  region: awsConfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE[awsConfig.aws_appsync_authenticationType],
    jwtToken: async () => {
      try {
        return (await userApi.getCurrentSession())?.getIdToken().getJwtToken()
      } catch (error) {
        console.warn('Error caught while trying to get jwt token', error)
      }
    }
  }
}

const appSyncApiKeyConfig = {
  uri: awsConfig.aws_appsync_graphqlEndpoint
  // region: awsConfig.aws_appsync_region
}

const apolloAuthContext = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'x-organization-id': storage.organizationId,
    'x-locale': getLanguage(),
    'x-realm': getRealm(),
    ...(getOfferId() ? { 'x-offer-id': getOfferId() } : {})
  }
}))

const apolloApiKeyContext = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'x-organization-id': storage.organizationId,
    'x-locale': getLanguage(),
    'x-realm': getRealm(),
    'x-api-key': process.env.REACT_APP_GRAPHQL_API_KEY
  }
}))

const authLink = ApolloLink.concat(
  apolloAuthContext.concat(createAuthLink(appSyncAuthConfig)),
  createSubscriptionHandshakeLink(appSyncAuthConfig)
)

const apiKeyLink = apolloApiKeyContext.concat(
  createHttpLink(appSyncApiKeyConfig)
)

export const apolloClient = new ApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext().unauthenticated === true,
    apiKeyLink,
    authLink
  ),
  // link: ApolloLink.from([
  //   apolloAuthContext.concat(createAuthLink(appSyncAuthConfig)),
  //   createSubscriptionHandshakeLink(appSyncAuthConfig)
  // ]),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'LocalBusiness':
          return `LocalBusiness:${responseObject.location.latitude}-${responseObject.location.longitude}`
        case 'Location':
          return `Location:${responseObject.latitude}-${responseObject.longitude}`
        default:
          return defaultDataIdFromObject(responseObject)
      }
    },
    typePolicies: {
      BlogTextElement: {
        keyFields: false
      },
      BillingAddress: {
        keyFields: false
      },
      Consent: {
        keyFields: false
      },
      Query: {
        fields: {
          listInvoices: {
            keyArgs: false,
            merge(existing, incoming, options) {
              return mergePaginatedItems(existing, incoming, options)
            }
          }
        }
      }
    }
  })
})
