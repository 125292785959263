import { useContext } from 'react'

import PropTypes from 'prop-types'

import { I18nContext } from '@etvas/i18n'

export const RecurringSubscriptionPeriod = ({ subscriptionMonths }) => {
  const { translate } = useContext(I18nContext)
  if (subscriptionMonths === 12) {
    return <span>{translate('label.yearly')}</span>
  }

  return (
    <span>
      {translate('label.monthly', { subscriptionMonths }, 'subscriptionMonths')}
    </span>
  )
}

RecurringSubscriptionPeriod.propTypes = {
  subscriptionMonths: PropTypes.number
}
