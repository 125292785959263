import { useLayoutEffect } from 'react'

import { apolloClient } from '@services/graphql/clients'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'

import { Typography } from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { purchaseStatus } from '@shared/models'
import { usePollingActivation } from '@shared/services/usePollingActivation'

const PollWaitActivation = ({
  product,
  purchaseId,
  onStopPolling,
  ...props
}) => {
  const stopCondition = [
    purchaseStatus.Failed,
    purchaseStatus.Suspended,
    purchaseStatus.Purchased
  ]

  const { activation, stopPolling } = usePollingActivation(purchaseId)
  const stopConditionReached = stopCondition.includes(activation?.status)

  useLayoutEffect(() => {
    if (!stopConditionReached) {
      return
    }

    stopPolling()

    try {
      apolloClient.writeFragment({
        id: `Product:${product.id}`,
        fragment: gql`
          fragment UpdatePurchaseStatus on Product {
            __typename
            purchaseId
            purchaseStatus
            trialEndsAt
          }
        `,
        data: {
          purchaseId,
          trialEndsAt: activation.trialEndsAt,
          purchaseStatus: activation.status,
          __typename: 'Product'
        }
      })
    } catch (err) {
      console.warn('* Error while updating fragment cache', err)
    }

    onStopPolling(activation.status)
  }, [
    stopConditionReached,
    stopPolling,
    activation,
    onStopPolling,
    purchaseId,
    product
  ])

  return stopConditionReached ? null : (
    <Typography variant='textSmall' {...props}>
      <T label='text.waitingForPayConfirmation' />
    </Typography>
  )
}

PollWaitActivation.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string
  }),
  purchaseId: PropTypes.string,
  onStopPolling: PropTypes.func
}

export default PollWaitActivation
