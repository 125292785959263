import { _lg, _md } from '../_utils'
import * as primaryColors from '../primary-colors'
import {
  authTemplateAuthLinkMarginTop,
  authTemplateAuthLinkPadding,
  authTemplateBgImageHeight,
  authTemplateLogoWrapperSize,
  authTemplateTermsAndConditionsFontSize,
  headerHeight,
  pageSpacing,
  sidebarWidth
} from '../sizes'
import { authTemplateBg, authTemplateLink } from '../theme-colors'

const AuthenticationTemplate = {
  wrapper: {
    zIndex: '9',
    left: -1 * sidebarWidth,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: authTemplateBg,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '> div': {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ..._lg({
        justifyContent: 'flex-start'
      })
    },
    ..._lg({
      position: 'absolute'
    })
  },
  scroll: {
    width: '100%',
    padding: '40px 0',
    '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* Safari and Chrome */
    }
  },
  card: {
    backgroundColor: authTemplateBg,
    padding: '32px 26px 30px 26px',
    borderRadius: 25,
    marginLeft: 12,
    marginRight: 12,
    ..._md({
      width: 411,
      padding: 40,
      marginLeft: 100
    })
  },
  authenticatorWrapper: {
    width: '100%',
    marginTop: -(headerHeight.xs + pageSpacing.xs),
    ..._md({
      marginLeft: -sidebarWidth.lg,
      width: `calc(100% + ${sidebarWidth.lg}px)`,
      marginTop: 0
    })
  },
  container: {
    position: 'relative',
    display: 'inline-block',
    width: `calc(100% + ${2 * pageSpacing.xs}px)`,
    marginLeft: -pageSpacing.xs
  },
  fgContent: {
    position: 'absolute',
    top: '15%',
    width: '100%',
    paddingLeft: pageSpacing.xs,
    paddingRight: pageSpacing.xs,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2
  },
  bgImage: {
    position: 'relative',
    width: '100%',
    height: authTemplateBgImageHeight,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  bgVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100%',
    minHeight: '100%',
    zIndex: -1
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: authTemplateLogoWrapperSize,
    height: authTemplateLogoWrapperSize,
    borderRadius: '50%',
    backgroundColor: authTemplateBg
  },
  authLink: {
    alignSelf: 'center',
    padding: authTemplateAuthLinkPadding,
    marginTop: authTemplateAuthLinkMarginTop,
    fontWeight: 'normal',
    color: authTemplateLink,
    fontSize: `${authTemplateTermsAndConditionsFontSize}px`,
    lineHeight: '20px',
    '> a': {
      textDecoration: 'none',
      color: primaryColors.default.accent
    }
  },
  link: {
    '> a': {
      textDecoration: 'none',
      color: primaryColors.default.accent
    }
  },
  languageSwitcher: {
    padding: '12px',
    display: 'flex',
    justifyContent: 'flex-end',

    ..._md({
      position: 'absolute',
      height: 'auto',
      top: 32,
      right: 32
    })
  }
}

export default AuthenticationTemplate
