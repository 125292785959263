import gql from 'graphql-tag'

import { PURCHASED_PRODUCT_FRAGMENT } from '@shared/services/graphql/fragments'

export const GET_LIST_PURCHASED_PRODUCTS = gql`
  query listPurchasedProducts {
    listPurchasedProducts {
      ...PurchasedProductsDetails
    }
  }
  ${PURCHASED_PRODUCT_FRAGMENT}
`

export const GET_PAYMENT_HISTORY = gql`
  query getPaymentHistory($input: ListInvoicesInput!) {
    listInvoices(input: $input) {
      items {
        id
        productName
        price
        currency
        paidAt
        pdfURL
        displayStatus
        chargeType
      }
      nextPageToken
    }
  }
`
