import { useQuery } from '@apollo/client'

import { GET_PRODUCT_TERMS_AND_CONDITIONS } from './graphql/queries'

export const useProductTOS = id => {
  const { data, loading, error } = useQuery(GET_PRODUCT_TERMS_AND_CONDITIONS, {
    variables: { input: { id } },
    context: { unauthenticated: true }
  })

  if (error) {
    console.error(
      `Unable to get the terms and conditions details for the product with id ${id}`,
      error
    )
  }

  return {
    productTOS: data?.getProductTermsAndConditions,
    isLoadingProductTOS: loading
  }
}
