import { useCallback, useEffect } from 'react'

import { apolloClient } from '@services/graphql/clients'

/**
 * Evict a field from the apollo cache on unmount
 * @function
 * @param {string} fieldName - The field name to be evicted from the cache
 */
const useEvictOnUnmount = fieldName => {
  const clearFieldFromCache = useCallback(() => {
    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName })
    apolloClient.cache.gc()
  }, [fieldName])

  useEffect(() => () => clearFieldFromCache(), [clearFieldFromCache])

  return clearFieldFromCache
}

export default useEvictOnUnmount
