import Cookies from 'js-cookie'

import { isCookieStorageAvailable } from '@shared/store/cookie'

import { getCurrentLocation } from './currentLocation'

const _removeCookie = ({ name }, options) => {
  if (options && Array.isArray(options)) {
    options.forEach(option => _removeCookie({ name }, option))
    return
  }

  Cookies.remove(name, options)
}

export const getCognitoCookiesToDelete = cookies => {
  const COGNITO_WEB_CLIENT_ID = process.env.REACT_APP_WEB_CLIENT_ID
  const COGNITO_KEY_PREFIX = 'CognitoIdentityServiceProvider'

  const authCookies = cookies.filter(
    ({ name }) =>
      name.startsWith(`${COGNITO_KEY_PREFIX}.`) &&
      name.length > COGNITO_KEY_PREFIX.length + 7
  )
  // No Auth cookies
  if (!authCookies?.length) {
    return []
  }

  // Do we have a last auth user cookies?
  const lastAuthUser = authCookies.filter(({ name }) =>
    name.endsWith('.LastAuthUser')
  )

  // No last auth user, so we remove all the cognito cookies
  if (!lastAuthUser.length) {
    return authCookies
  }

  // There can be only one
  const currentWebClientKeyPrefix = lastAuthUser.find(
    ({ name }) =>
      name === `${COGNITO_KEY_PREFIX}.${COGNITO_WEB_CLIENT_ID}.LastAuthUser`
  )

  // No last auth user for our web client id, so we delete all cognito cookies
  if (!currentWebClientKeyPrefix) {
    return authCookies
  }

  const preservePrefix = [
    COGNITO_KEY_PREFIX,
    COGNITO_WEB_CLIENT_ID,
    currentWebClientKeyPrefix.value
  ].join('.')

  return authCookies.filter(
    ({ name }) =>
      name !== currentWebClientKeyPrefix.name &&
      !name.startsWith(preservePrefix)
  )
}

export const cleanupCookies = () => {
  if (!isCookieStorageAvailable()) {
    return 0
  }

  const cookies = Cookies.get()
  const cookieArray = Object.keys(cookies).map(name => ({
    name,
    value: cookies[name]
  }))

  // remove auth cookies
  const cognitoCookiesToDelete = getCognitoCookiesToDelete(cookieArray)
  const currentLocation = getCurrentLocation()
  const cognitoCookieAttributes = {
    secure: !currentLocation.isLocal,
    domain: currentLocation.isLocal
      ? 'localhost'
      : `.${currentLocation.domain}`,
    path: '/'
  }

  cognitoCookiesToDelete.forEach(cookie => {
    try {
      _removeCookie(cookie, cognitoCookieAttributes)
    } catch (error) {
      console.error('Error while deleting cookie', {
        cookie,
        attributes: cognitoCookieAttributes,
        error
      })
    }
  })
}
