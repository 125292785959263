import gql from 'graphql-tag'

import {
  AVAILABLE_PRODUCT_FRAGMENT,
  PRODUCT_PURCHASE_STATUS_FRAGMENT
} from '@shared/services/graphql/fragments'

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetailsAndTerms($input: IDInput!) {
    getProductTermsAndConditions(input: $input) {
      termsAndConditions
    }
    getProductDetails(input: $input) {
      ...availableProduct
    }
  }
  ${AVAILABLE_PRODUCT_FRAGMENT}
`

export const GET_PRODUCT_PURCHASE_STATUS = gql`
  query getProductPurchaseStatusAndTerms($input: IDInput!) {
    getProductTermsAndConditions(input: $input) {
      termsAndConditions
    }
    getProductDetails(input: $input) {
      ...availableProduct
      ...productPurchaseStatus
    }
  }
  ${AVAILABLE_PRODUCT_FRAGMENT}
  ${PRODUCT_PURCHASE_STATUS_FRAGMENT}
`
