import { useGate } from 'statsig-react'

export const useFeatureGate = (name, defaultValue = false) => {
  const gate = useGate(
    `${
      process.env.REACT_APP_NODE_ENV === 'production' ? 'prod' : 'dev'
    }_${name}`
  )

  return gate.isLoading ? defaultValue : gate.value
}
