import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { AuthContext } from '@services/AuthProvider'
import { CouponContext } from '@services/CouponProvider'
import { useTracking } from '@services/hooks/useTracking'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'

import { Box, Flex, Typography, styled } from '@etvas/etvaskit'

import {
  AuthFlow,
  ImprintCard,
  ModalDialog,
  NotFound
} from '@shared/components'
import {
  BillingInfoModal,
  BillingInfoSkel
} from '@shared/components/BillingInfo'
import { Purchase } from '@shared/components/Purchase'
import {
  getQueryParam,
  handlePurchaseRedirectURL,
  isImage,
  showExternal
} from '@shared/funcs'
import { T, setLanguage } from '@shared/i18n'

import {
  Discount,
  Footer,
  NavBar,
  NavScroll,
  PageFooter,
  ProductDescription,
  ProductGallery,
  ProductOverview,
  ProductVideo
} from '../components'
import { useAllowedRedirect } from '../hooks/useAllowedRedirect'
import { useLandingData } from '../hooks/useLandingData'

const authLabels = {
  checkEmail: 'title.logInOrCreate',
  login: 'title.logIn',
  register: 'title.createAnAccount',
  recoverPassword: 'title.recoverPassword'
}

const modalWidth = ['90%', '400px']
export const ProductLanding = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { pid, lang } = useParams()
  const { isRedirectAllowed } = useAllowedRedirect()
  const { authenticated, profileAuth, isFetchingCognitoUser, hasConsent } =
    useContext(AuthContext)
  const [currentAuthTitle, setCurrentAuthTitle] = useState('checkEmail')

  const {
    couponCode,
    paymentCoupon,
    requestAttachPaymentCoupon,
    isCouponAvailable
  } = useContext(CouponContext)

  const [showModal, setShowModal] = useState(false)
  const [billingFinished, setBillingFinished] = useState(false)
  const [isTracked, setTracked] = useState(false)
  const [navScrollHeight, setNavScrollHeight] = useState(0)

  const heroRef = useRef()

  const { track } = useTracking()

  useEffect(() => {
    if (!paymentCoupon && isCouponAvailable) {
      requestAttachPaymentCoupon(couponCode)
    }
  }, [requestAttachPaymentCoupon, couponCode, isCouponAvailable, paymentCoupon])

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  const { isLoading, product, refreshProductStatus, error } = useLandingData({
    pid,
    isFetchingCognitoUser,
    authenticated: authenticated || profileAuth
  })

  useEffect(() => {
    const params = new URLSearchParams(search)
    const state = params.get('state')

    if (state) {
      setShowModal(true)
    }
  }, [search])

  useEffect(() => {
    if (!isLoading && !isTracked) {
      setTracked(true)
      track([
        {
          name: 'landing.page.view',
          value: window.location?.pathname
        },
        {
          name: 'landing.product.view',
          value: pid
        }
      ])
    }
  }, [isLoading, pid, track, isTracked])

  useLayoutEffect(() => {
    if (isLoading) {
      return
    }

    const windowResizeHandler = () => {
      setNavScrollHeight(heroRef?.current?.offsetHeight)
    }

    window.addEventListener('resize', windowResizeHandler)
    windowResizeHandler()
    return () => window.removeEventListener('resize', windowResizeHandler)
  }, [isLoading])

  const handleToggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  const handleUse = () =>
    showExternal(product, { withRefresh: false, navigate: history.push })

  const handlePurchase = isSecondary => () => {
    const event = {
      name: 'landing.purchase.start',
      value: product.id
    }

    track(event, isSecondary)

    const redirectPurchaseURL = getQueryParam('redirectPurchaseURL')

    if (redirectPurchaseURL) {
      return handlePurchaseRedirectURL(redirectPurchaseURL, product)
    }

    handleToggleModal()
  }

  const handlePurchaseFinished = () => {
    refreshProductStatus()
    setShowModal(false)
  }

  const productVideo = useMemo(
    () =>
      product && product.mediaURLs
        ? product.mediaURLs.find(item => !isImage(item))
        : undefined,
    [product]
  )

  const location = useLocation()
  if (authenticated && product && !hasConsent) {
    return (
      <Redirect
        to={{ pathname: '/consent', state: { authRedirectLocation: location } }}
      />
    )
  }

  if (!product && !isLoading) {
    return (
      <Flex
        width='100vw'
        height='100vh'
        alignItems='center'
        justifyContent='center'
      >
        <NotFound />
      </Flex>
    )
  }

  if (error) {
    return (
      <Box px={2} py={10}>
        <Typography
          variant='title24Light'
          color='statusError'
          textAlign='center'
        >
          <T label='error.unknown' />
        </Typography>
      </Box>
    )
  }

  const redirectURL = getQueryParam('redirectPurchaseURL')
  if (redirectURL && !isRedirectAllowed(redirectURL)) {
    return (
      <Box px={2} py={10}>
        <Typography
          variant='title24Light'
          color='statusError'
          textAlign='center'
        >
          <T label='error.redirectURLNotAllowed' />
        </Typography>
      </Box>
    )
  }

  return (
    <Box bg='divider'>
      <NavBar scrollOffset={navScrollHeight}>
        <NavScroll
          handlePurchase={handlePurchase(true)}
          authenticated={authenticated}
          product={product}
          loading={isLoading}
        />
      </NavBar>
      <Footer
        handlePurchase={handlePurchase(true)}
        authenticated={authenticated}
        product={product}
        loading={isLoading}
        scrollOffset={navScrollHeight}
      />
      <ProductOverview
        ref={heroRef}
        handlePurchase={handlePurchase(false)}
        authenticated={authenticated}
        product={product}
        loading={isLoading}
      />
      <StyledContainer>
        {productVideo ? (
          <ProductVideo
            mt={8}
            video={productVideo}
          />
        ) : null}
        {couponCode ? <Discount mt={8} informal /> : null}
        {product ? (
          <ProductGallery product={product} />
        ) : null}
        {product ? (
          <ProductDescription
            mb={8}
            product={product}
          />
        ) : null}
        <ImprintCard />
      </StyledContainer>
      <PageFooter />

      {showModal ? (
        authenticated && product ? (
          billingFinished ? (
            <Purchase
              paymentCoupon={paymentCoupon}
              product={product}
              onCancel={handlePurchaseFinished}
              onUse={handleUse}
              backDrop='rgba(0,0,0,0.73)'
            />
          ) : (
            <BillingInfoModal
              product={product}
              onCancel={handleToggleModal}
              backDrop='rgba(0,0,0,0.73)'
              onProceed={() => setBillingFinished(true)}
            />
          )
        ) : isLoading ? (
          <ModalDialog p={6} width={modalWidth}>
            <BillingInfoSkel />
          </ModalDialog>
        ) : (
          <ModalDialog
            onDismiss={handleToggleModal}
            width={modalWidth}
            title={<T label={authLabels[currentAuthTitle]} />}
            p={10}
          >
            <AuthFlow
              onAuthStateChange={authState => setCurrentAuthTitle(authState)}
            />
          </ModalDialog>
        )
      ) : null}
    </Box>
  )
}

const StyledContainer = styled(Box)`
  max-width: 1200px;
  margin: 0 16px;
  @media (min-width: 1199px) {
    margin: 0 auto;
  }
`
