import { useContext, useMemo, useState } from 'react'

import { AuthContext } from '@services/AuthProvider'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'

import { Button, Flex, Grid, Typography } from '@etvas/etvaskit'

import { CardElementWrapper } from '@shared/components'
import { T } from '@shared/i18n'

import { style } from '../paymentMethodUtils'
import PaymentMethodEmpty from './PaymentMethodEmpty'
import PaymentMethodView from './PaymentMethodView'

const CardEditor = ({
  paymentMethodsResult,
  paymentMethod,
  showAddForm,
  setShowAddForm
}) => {
  const { requestAddSetupIntent, isAddingPaymentMethod, forceUpdateCache } =
    paymentMethodsResult

  const [stripeReady, setStripeReady] = useState(false)
  const [stripeErrors, setStripeErrors] = useState({
    number: true,
    expiry: true,
    cvc: true
  })

  const stripe = useStripe()
  const elements = useElements()

  const { currentUser } = useContext(AuthContext)
  const [isTokenizerBusy, setTokenizerBusy] = useState(false)
  const [intentError, setIntentError] = useState(null)

  const _resetStripeForm = resetInput => {
    setTokenizerBusy(false)
    if (resetInput) {
      setStripeErrors({
        number: true,
        expiry: true,
        cvc: true
      })
      if (elements) {
        const _resetInputs = [
          CardNumberElement,
          CardExpiryElement,
          CardCvcElement
        ]
        _resetInputs.forEach(el => {
          const instance = elements.getElement(el)
          if (instance) {
            instance.clear()
          }
        })
      }
    }
  }

  const _toggleAddForm = () => {
    _resetStripeForm()
    setIntentError(null)
    if (!showAddForm) {
      setStripeReady(false)
    }
    setShowAddForm(!showAddForm)
  }

  const _onCardElementReady = e => {
    if (e._componentName === 'cardCvc') {
      setStripeReady(true)
      setStripeErrors({
        number: true,
        expiry: true,
        cvc: true
      })
    }
  }

  const _onCardElementChange = (name, event) => {
    const errors = { ...stripeErrors }
    errors[name] = !event.complete
    setStripeErrors(errors)
  }

  const _onAddPaymentIntent = async () => {
    if (!stripe || !elements) {
      return
    }
    setIntentError('waitingForPayGateway')
    setTokenizerBusy(true)
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      // eslint-disable-next-line camelcase
      billing_details: {
        email: currentUser.email,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        phone: currentUser.phoneNumber
      }
    })
    if (result.error) {
      console.error('* Error caught on stripe', result)
      setIntentError('stripeError')
      return false
    }
    const {
      success,
      paymentMethod: newPaymentMethod,
      setupIntent
    } = await requestAddSetupIntent(result.paymentMethod.id)
    if (!success) {
      setIntentError('stripeError')
      return
    }
    if (setupIntent.status === 'succeeded') {
      setIntentError(null)
      setShowAddForm(false)
      return
    } else if (
      setupIntent.status !== 'requires_action' &&
      setupIntent.status !== 'requires_confirmation'
    ) {
      console.error('* Error processing setup intent status', setupIntent)
      setIntentError('stripeError')
      return
    }

    setIntentError('cardRequiredAuth')

    // requires_action || requires_confirmation
    const confirmResponse = await stripe.confirmCardSetup(
      setupIntent.clientSecret,
      // eslint-disable-next-line camelcase
      { payment_method: newPaymentMethod.id }
    )
    if (confirmResponse.error) {
      _resetStripeForm(true)
      setIntentError('cardAuthFailed')
      return
    }

    forceUpdateCache(newPaymentMethod)
    setIntentError(null)
    setTokenizerBusy(false)
    setShowAddForm(false)
  }

  const _canSubmitStripe = useMemo(
    () =>
      !!stripe &&
      !!elements &&
      stripeReady &&
      Object.keys(stripeErrors).every(key => !stripeErrors[key]),
    [elements, stripe, stripeErrors, stripeReady]
  )

  if (!showAddForm) {
    if (paymentMethod) {
      return (
        <PaymentMethodView
          paymentMethod={paymentMethod}
          handleClick={_toggleAddForm}
        />
      )
    }
    return <PaymentMethodEmpty handleClick={_toggleAddForm} />
  }

  return (
    <Grid vspace='0' cols={2}>
      <Grid.Item span={2}>
        <Typography variant='inputLabel'>
          <T label='label.cardNumber' />
        </Typography>
        <CardElementWrapper mb={5} active>
          <CardNumberElement
            onReady={_onCardElementReady}
            onChange={event => _onCardElementChange('number', event)}
            options={{
              style,
              showIcon: true
            }}
          />
        </CardElementWrapper>
      </Grid.Item>
      <Grid.Item>
        <Typography variant='inputLabel'>
          <T label='label.cardExpire' />
        </Typography>
        <CardElementWrapper mb={5} active>
          <CardExpiryElement
            options={{ style }}
            onChange={event => _onCardElementChange('expiry', event)}
            onReady={_onCardElementReady}
          />
        </CardElementWrapper>
      </Grid.Item>
      <Grid.Item>
        <Typography variant='inputLabel'>
          <T label='label.cardCVC' />
        </Typography>
        <CardElementWrapper mb={5} active>
          <CardCvcElement
            options={{ style }}
            onChange={event => _onCardElementChange('cvc', event)}
            onReady={_onCardElementReady}
          />
        </CardElementWrapper>
      </Grid.Item>
      <Grid.Item span={2}>
        <Flex justifyContent='flex-end' py={4}>
          {intentError ? (
            <Typography
              variant='labelButton'
              textAlign='center'
              fontWeight='lighter'
            >
              <T label={`text.${intentError}`} />
            </Typography>
          ) : null}
          <Button
            variant='link'
            width={{ _: '100%', md: 'auto' }}
            mr={4}
            onClick={_toggleAddForm}
            disabled={isTokenizerBusy || isAddingPaymentMethod}
            type='button'
          >
            <T label='label.cancel' />
          </Button>
          <Button
            variant='link'
            width={{ _: '100%', md: 'auto' }}
            ml={1}
            onClick={_onAddPaymentIntent}
            disabled={
              isTokenizerBusy || !_canSubmitStripe || isAddingPaymentMethod
            }
            type='button'
          >
            <T label='label.saveChanges' />
          </Button>
        </Flex>
      </Grid.Item>
    </Grid>
  )
}

CardEditor.propTypes = {
  paymentMethodsResult: PropTypes.object,
  paymentMethod: PropTypes.object,
  showAddForm: PropTypes.bool,
  setShowAddForm: PropTypes.func
}

export default CardEditor
