import { BlockSkeleton, Box, Button, Flex } from '@etvas/etvaskit'

const PaymentMethodSkeleton = props => (
  <Box {...props}>
    <BlockSkeleton height='1rem' />
    <BlockSkeleton height='3rem' mt={1} />
    <Flex justifyContent='flex-end' mt={4}>
      <Button
        variant='primary'
        width={{ _: '100%', md: 'auto' }}
        ml={1}
        disabled
        type='button'
      >
        ...
      </Button>
    </Flex>
  </Box>
)

export default PaymentMethodSkeleton
