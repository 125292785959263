import {
  mdiApps,
  mdiArrowRight,
  mdiBrightnessPercent,
  mdiCheckCircleOutline,
  mdiFullscreenExit,
  mdiMapMarker,
  mdiPlayCircle
} from '@mdi/js'

const customIcons = {
  mdiCheckCircleOutline,
  mdiArrowRight,
  mdiBrightnessPercent,
  mdiPlayCircle,
  mdiFullscreenExit,
  mdiApps,
  mdiMapMarker
}

export default customIcons
