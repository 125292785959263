export const headerHeight = { xs: 80, lg: 100 }
export const headerWidth = { xs: '100%', lg: 320 }
export const burgerHeight = 1

export const logoWidth = { xs: 140, lg: 220 }

export const sidebarWidth = { xs: '50%', lg: 320 }
export const sidebarSpacing = '1rem 2.5rem 1rem 2.5rem'

export const pageSpacing = {
  xs: 16,
  lg: 16
}

export const pageWidth = {
  xs: '100%',
  lg: 1024
}

export const sectionTitleHeight = {
  xs: 'auto',
  lg: headerHeight.lg
}

export const sectionTitleFontSize = {
  xs: 18,
  lg: 24
}

export const sectionSubtitleWidth = 150
export const sectionSubtitleFontSize = 16
export const inputVSpacing = '1rem'
export const inputHeight = 40
export const inputWidth = 360
export const inputFontSize = 12
export const inputPadding = '0 1rem'
export const selectInputPadding = '0 1rem'
export const inputRadius = 8
export const inputTiny = 80
export const inputSmall = 98
export const inputSpacing = 4

export const errorMessage = '.75rem'

export const checkSize = 24
export const checkRadius = 2
export const checkLabel = '.75rem'

export const separatorSpacing = '2rem'

export const productCardRadius = '.5rem'
export const productCardTextSmall = '.75rem'
export const productCardTextTiny = '.75rem'

export const productCardRatings = {
  xs: '.75rem',
  lg: '1.4rem'
}

export const productCardPadding = {
  xs: '.75rem',
  lg: '1.5rem'
}
export const productCardTitle = {
  xs: '1rem',
  lg: '1.4rem'
}

export const productCardHSpacing = {
  lg: '1rem'
}

export const productPurchaseButton = 40
export const productTrial = '.75rem'
export const productPurchasePadding = '2rem'
export const productTagTop = '1rem'
export const productTagPadding = '.375rem .375rem .375rem .5rem'
export const productTagFontSize = '.75rem'

export const productPagePriceBarSpacing = {
  xs: '2.5rem',
  lg: '.5rem'
}

export const productPageTitle = '1.5rem'
export const productPageRatingTitle = '2.2rem'
export const productPageCoverHeight = 320
export const productPageCoverRadius = '1rem'
export const productPageDescriptionFont = {
  xs: '.9rem'
}
export const productPageDescriptionMaxHeight = 150
export const productPageDescriptionMarginBottom = 20
export const productPageLogoMaxHeight = 100
export const productPageLogoMaxWidth = 100
export const productPageCardSpacing = {
  xs: '1.2rem',
  lg: '3.75rem'
}

export const comingSoonProductImageBorderRadius = '12px'
export const comingSoonProductImageHeight = {
  xs: 140,
  sm: 160,
  md: 180
}
export const comingSoonProductMobileMarginTop = 32
export const comingSoonProductTabletPadding = 8
export const comingSoonProductDesktopPadding = 16
export const comingSoonSectionContainerMarginBottom = 24
export const comingSoonSectionContainerMobileMarginTop = 16
export const comingSoonSectionContainerDesktopMarginTop = 20
export const comingSoonSectionMobilePadding = 12
export const comingSoonSectionTabletPadding = 16
export const comingSoonSectionDesktopPadding = 16
export const comingSoonTitleMobileMarginTop = 12
export const comingSoonTitleDektopMarginTop = 20
export const comingSoonDescriptionMinHeight = 38
export const comingSoonTitleMinHeight = 60

export const blogArticleDescriptionMinHeight = 76
export const blogSectionTitleMarginTop = 50
export const blogSectionMarginBottom = 24
export const blogSectionContainerMobileMarginTop = 24
export const blogArticleDesktopPadding = comingSoonProductDesktopPadding
export const blogArticleMobileMarginTop = comingSoonProductMobileMarginTop
export const blogArticleImageBorderRadius = comingSoonProductImageBorderRadius
export const blogArticleImageHeight = comingSoonProductImageHeight

export const blogArticleDetailsImageHeight = {
  xs: 200,
  sm: 240,
  md: 320
}

export const termsAndConditionsMaxWidth = 880

export const modalTagPadding = '.5rem 1rem'
export const modalTagFont = '.75rem'
export const modalCardSpacing = '1rem'
export const modalCardPadding = {
  xs: '1.5rem',
  md: '3rem'
}

export const modalCardTitle = '1.2rem'
export const modalCardText = '0.75rem'
export const modalCardRadius = '1.5rem'
export const modalCardOverlaySpacing = '.375rem'
export const modalCardTight = '30rem'

export const sliderHeight = {
  xs: 160,
  lg: 320
}

export const sliderNavWidth = 24
export const sliderRadius = '.5rem'
export const sliderHSpacing = {
  xs: '2rem',
  lg: '1rem'
}

export const reviewRadius = 10
export const reviewPadding = '1rem'
export const reviewPanelSpacing = '2rem'
export const reviewPanelTemporaryMarginBottom = 60
export const reviewAuthorSize = '1rem'
export const reviewDateSize = '.75rem'
export const reviewTextSize = '.75rem'

export const avatarSpacing = '2.5rem'
export const avatarIconRadius = 4
export const avatarSize = 48
export const avatarIconSize = 32

export const navBackIconSize = 24
export const formLabelWidth = 140
export const formHSpacing = 24
export const formWrapperMarginTop = 32
export const formSubtitleMarginTop = 8
export const formErrorSubmitMarginBottom = 16
export const formErrorTextPaddingTop = 4
export const formErrorTextMarginRight = 24
export const formErrorTextHeight = 18

export const authTemplateLogoWrapperSize = 100
export const authTemplateLogoImageSize = 60
export const authTemplateCardMaxWidth = 450
export const authTemplateCardMarginTop = 32
export const authTemplateCardPadding = 32
export const authTemplateCardBorderRadius = 16
export const authTemplateBgImageHeight = 350
export const authTemplateAuthLinkPadding = 4
export const authTemplateAuthLinkMarginTop = 8
export const authTemplateTermsAndConditionsFontSize = 12
