import { useCallback } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { isEmbedded, isLandingPage } from '@shared/funcs'

export const useRedirectToStoredPath = () => {
  const history = useHistory()
  const location = useLocation()

  const redirect = useCallback(() => {
    if (isLandingPage() || isEmbedded()) {
      return
    }

    const redirectLocation = location?.state?.authRedirectLocation
    if (redirectLocation) {
      history.replace(redirectLocation)
    } else {
      history.replace({
        pathname: '/',
        search: location.search
      })
    }
  }, [history, location])

  return redirect
}
