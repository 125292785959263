import { useContext, useEffect, useState } from 'react'

import { OrganizationContext } from '@services/OrganizationProvider'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, Flex, Typography } from '@etvas/etvaskit'

import { PriceOption } from '@shared/components'
import { CookieBannerTeleport } from '@shared/constants/CookieBannerTeleport'

import { isProductPurchased } from '../hooks/purchasedService'
import PurchaseButton from './PurchaseButton'

const Footer = ({
  authenticated,
  handlePurchase,
  loading,
  product,
  scrollOffset
}) => {
  const { organization } = useContext(OrganizationContext)
  const [scrolledDistance, setScrolledDistance] = useState(false)

  useEffect(() => {
    const listener = () => {
      setScrolledDistance(window.scrollY)
    }
    window.addEventListener('scroll', listener)
    listener()
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  const isPurchased = isProductPurchased(product)
  const isWhite = organization?.landingHeaderColor?.toLowerCase() !== '#ffffff'

  return (
    <CookieBannerTeleport.Source>
      {scrolledDistance > scrollOffset && (
        <StyledContainer
          display={['block', 'block', 'none']}
          hasBorder={isWhite}
        >
          <Flex
            bg={isWhite ? 'white' : 'brandDark'}
            p={6}
            flexDirection='column'
            justifyContent='center'
            width={1}
          >
            <Typography
              variant='title20Light'
              lineHeight='24px'
              truncate
              width={1}
              {...(!isWhite ? { color: 'white' } : {})}
            >
              {product?.name}
            </Typography>

            {!isPurchased && (
              <Typography
                variant='base16Light'
                {...(!isWhite ? { color: 'white' } : {})}
              >
                {product ? <PriceOption product={product} /> : null}
              </Typography>
            )}

            <PurchaseButton
              authenticated={authenticated}
              onPurchase={handlePurchase}
              product={product}
              loading={loading}
              variant='primary'
              width={1}
              mt={4}
            />
          </Flex>
        </StyledContainer>
      )}
    </CookieBannerTeleport.Source>
  )
}

const StyledContainer = styled(Box)`
  z-index: 1;
  transition: 'all 0.25s ease-in-out';
  background: 'white';
  ${({ hasBorder }) => (hasBorder ? 'border-top: 1px solid #e3e3e3;' : '')}
`

Footer.propTypes = {
  authenticated: PropTypes.bool,
  handlePurchase: PropTypes.func,
  loading: PropTypes.bool,
  scrollOffset: PropTypes.number,
  product: PropTypes.shape({
    name: PropTypes.string,
    servicePartner: PropTypes.object
  })
}

export default Footer
