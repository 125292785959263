import { useState } from 'react'

import { Field } from 'formik'
import PropTypes from 'prop-types'

import {
  Button,
  Flex,
  Form,
  Grid,
  TextField,
  Typography
} from '@etvas/etvaskit'

import { T } from '@etvas/i18n'

import { LanguageField } from '@shared/components'
import { isEmbedded } from '@shared/funcs'
import { i18nService } from '@shared/i18n'

import ProfileDeleteModal from './components/ProfileDeleteModal'
import { useProfile } from './services'

const AccountInformation = ({ user }) => {
  const [isDeleteConfirmationShown, setDeleteConfirmationShown] =
    useState(false)

  const { requestUpdateProfile, isUpdatingProfile } = useProfile()

  const { firstName, lastName, email, phoneNumber, locale } = user

  const initialValues = {
    firstName,
    lastName,
    locale,
    email,
    phoneNumber
  }
  const _handleValidation = ({ firstName, lastName }) => {
    const errors = {}
    if (!firstName || !firstName.length) {
      errors.firstName = <T label='label.required' />
    }
    if (!lastName || !lastName.length) {
      errors.lastName = <T label='label.required' />
    }

    return errors
  }

  const _handleSubmit = async (formValues, { setStatus }) => {
    setStatus(null)
    const result = await requestUpdateProfile(formValues)
    if (result.hasErrors) {
      setStatus(result.errorMessage)
    }
  }

  const _toggleDeleteConfirmation = () => {
    setDeleteConfirmationShown(!isDeleteConfirmationShown)
  }

  const liveChangeLocale = value => {
    i18nService.setLanguage(value)
  }

  return (
    <T>
      {({ translate }) => (
        <>
          <Form
            initialValues={initialValues || {}}
            validate={_handleValidation}
            onSubmit={_handleSubmit}
            enableReinitialize={true}
          >
            {({ status, dirty }) => (
              <>
                <Grid vspace='0' cols={2}>
                  <Grid.Item>
                    <TextField
                      name='firstName'
                      id='firstName'
                      label={translate('label.firstName')}
                      placeholder={translate('label.firstName')}
                      required
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <TextField
                      name='lastName'
                      id='lastName'
                      label={translate('label.lastName')}
                      placeholder={translate('label.lastName')}
                      required
                    />
                  </Grid.Item>
                  {phoneNumber && (
                    <Grid.Item>
                      <TextField
                        name='phoneNumber'
                        id='phoneNumber'
                        label={translate('label.phoneNumber')}
                        placeholder={translate('label.phoneNumber')}
                        required
                        disabled
                      />
                    </Grid.Item>
                  )}
                  <Grid.Item span={phoneNumber ? 1 : 2}>
                    <TextField
                      name='email'
                      id='email'
                      label={translate('label.email')}
                      placeholder={translate('label.email')}
                      required
                      disabled
                    />
                  </Grid.Item>

                  <Grid.Item span={2}>
                    <Field
                      as={LanguageField}
                      name='locale'
                      id='locale'
                      liveChange={liveChangeLocale}
                      label={translate('label.language')}
                      placeholder={translate('label.language')}
                      required
                    />
                  </Grid.Item>

                  <Grid.Item>
                    {status && (
                      <Typography ml={4} variant='textSmall' color='error'>
                        {translate(status)}
                      </Typography>
                    )}
                  </Grid.Item>
                </Grid>
                <Flex justifyContent='space-between' py={4}>
                  {!isEmbedded() && (
                    <Button
                      type='button'
                      variant='link'
                      icon='trash'
                      onClick={_toggleDeleteConfirmation}
                    >
                      <T label='label.profileDelete' />
                    </Button>
                  )}
                  <Button
                    variant='link'
                    width={{ _: '100%', md: 'auto' }}
                    ml='auto'
                    type='submit'
                    disabled={!dirty || isUpdatingProfile}
                  >
                    <T label='label.saveChanges' />
                  </Button>
                </Flex>
              </>
            )}
          </Form>
          {isDeleteConfirmationShown && (
            <ProfileDeleteModal closeModal={_toggleDeleteConfirmation} />
          )}
        </>
      )}
    </T>
  )
}

AccountInformation.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    locale: PropTypes.string,
    phoneNumber: PropTypes.string
  })
}

export default AccountInformation
