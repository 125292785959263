import { createContext, useEffect, useLayoutEffect, useState } from 'react'

import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import { Box } from '@etvas/etvaskit'

import { NotFound } from '@shared/components'
import { currentLocation } from '@shared/funcs'
import { setLanguage } from '@shared/i18n'

import { ProtectedRoute } from '../../navigation/components'
import {
  EmbeddedDiscover,
  EmbeddedImprint,
  EmbeddedMyProducts,
  EmbeddedProductCard,
  EmbeddedProductDetails,
  EmbeddedProductUse,
  EmbeddedPurchaseFlow,
  EmbeddedSettings,
  EmbeddedSettingsTile
} from './components'

export const ViewContext = createContext()

const EmbeddedRouter = () => {
  const { lang } = useParams()
  const { path } = useRouteMatch()
  const [currentView, setCurrentView] = useState('')
  const [options, setOptions] = useState({})

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  useEffect(() => {
    const opts = currentLocation.query
    const boolOptions = ['hideRating']
    const normalizeBool = value =>
      value === 'true' || value === '1' || value === 1 ? true : false
    const normalized = Object.keys(opts).reduce(
      (normalized, key) => ({
        ...normalized,
        [key]: boolOptions.includes(key) ? normalizeBool(opts[key]) : opts[key]
      }),
      {}
    )
    setOptions(normalized)
  }, [])

  useLayoutEffect(() => {
    const setBackgroundColor = (defaultColor = 'ffffff') => {
      const { bg } = currentLocation.query
      const html = document.getElementsByTagName('html')
      if (!html) {
        return
      }
      const [el] = html
      if (bg === 'transparent') {
        el.style.backgroundColor = bg
        return
      }
      if (!bg || !/^[0-9a-fA-F]{6}$/.test(bg)) {
        el.style.backgroundColor = `#${defaultColor}`
        return
      }
      el.style.backgroundColor = `#${bg}`
    }

    setBackgroundColor()
  }, [])

  return (
    <ViewContext.Provider value={{ currentView, setCurrentView }}>
      <Box>
        <Switch>
          <Route path={`${path}/product/:productId`} exact>
            <EmbeddedProductCard name='product-card' options={options} />
          </Route>
          <Route path={`${path}/product/:productId/details`} exact>
            <EmbeddedProductDetails name='product-details' options={options} />
          </Route>

          <Route path={`${path}/discover`}>
            <EmbeddedDiscover name='discover' options={options} />
          </Route>

          <Route exact path={`${path}/settings/imprint`}>
            <EmbeddedImprint name='settings-imprint' />
          </Route>

          <Route exact path={`${path}/imprint`}>
            <EmbeddedImprint name='imprint' />
          </Route>

          <ProtectedRoute
            component={EmbeddedPurchaseFlow}
            path={`${path}/purchase/:productId`}
            name='product-purchase'
            options={options}
            exact
          />
          <ProtectedRoute
            component={EmbeddedMyProducts}
            path={`${path}/my-products`}
            name='my-products'
            options={options}
            exact
          />
          <ProtectedRoute
            component={EmbeddedProductUse}
            path={`${path}/product/:productId/use`}
            name='product-use'
            options={options}
            exact
          />
          <ProtectedRoute
            component={EmbeddedSettings}
            path={`${path}/settings`}
            name='settings'
            options={options}
            exact
          />
          <ProtectedRoute
            component={EmbeddedSettingsTile}
            path={`${path}/settings/account`}
            name='settings-account'
            options={options}
            tile='account'
            exact
          />
          <ProtectedRoute
            component={EmbeddedSettingsTile}
            path={`${path}/settings/billing`}
            name='settings-billing'
            options={options}
            tile='billing'
            exact
          />
          <ProtectedRoute
            component={EmbeddedSettingsTile}
            path={`${path}/settings/payment`}
            name='settings-payment'
            options={options}
            tile='payment'
            exact
          />
          <ProtectedRoute
            component={EmbeddedSettingsTile}
            path={`${path}/settings/coupon`}
            name='settings-coupon'
            options={options}
            tile='coupon'
            exact
          />
          <ProtectedRoute
            component={EmbeddedSettingsTile}
            path={`${path}/settings/products`}
            name='settings-products'
            options={options}
            tile='products'
            exact
          />
          <ProtectedRoute
            component={EmbeddedSettingsTile}
            path={`${path}/settings/payments`}
            name='settings-payments'
            options={options}
            tile='payments'
            exact
          />

          <NotFound />
        </Switch>
      </Box>
    </ViewContext.Provider>
  )
}

export default EmbeddedRouter
