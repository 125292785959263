import PropTypes from 'prop-types'

import { AvailableProducts } from '../../discover/components'
import { usePostHeight } from '../hooks/usePostHeight'
import { useEmbeddedProducts } from '../services'

const EmbeddedDiscover = ({ name }) => {
  usePostHeight(name)

  const { products, isLoadingProducts } = useEmbeddedProducts()

  return (
    <AvailableProducts
      isFetching={isLoadingProducts}
      products={products}
      showSeeMore='link'
      showAction='embed'
    />
  )
}
EmbeddedDiscover.propTypes = {
  name: PropTypes.string
}

export default EmbeddedDiscover
