import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box, themed } from '@etvas/etvaskit'

const Modal = ({ children, ...props }) => (
  <Overlay>
    <Card {...props}>{children}</Card>
  </Overlay>
)

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 110;
  background-color: ${themed('colors.whiteShadow')};
  display: flex;
  align-items: center;
`

const Card = styled(Box)`
  background: ${themed('colors.white')};
  border: 1px solid ${themed('colors.lightGray')};
  border-radius: 6px;
  box-shadow: ${themed('colors.shadow')};
  position: relative;
`

Modal.propTypes = {
  children: PropTypes.node
}

export default Modal
